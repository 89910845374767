import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteGuard } from '../routeGuard';


@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'save-popup.component.html'
})
export class ProjectDetailsDialog {
   dataSavedorNot =false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<ProjectDetailsDialog>,private guard:RouteGuard) {
  }
  closePopup(){
    this.dialogRef.close(true);
  }
  continueEditing(){
    this.dialogRef.close(false);
  }
}
