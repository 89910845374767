<!-- <nav #navbar class="navbar navbar-expand-lg navbar-absolute"> -->
<nav #navbar class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="navbar-wrapper" style="cursor: pointer" [routerLink]="['/dashboard']">
      <img src="../../../assets/img/conagra-logo.png" class="conagra-logo" />
      <div class="ml-4 userLogin">
        Strategic Investment Funding Tool
      </div>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple text-white" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
     
        <li class="nav-item">
          <div class="dropdown">
            <button href="#" class="btn dropdown-toggle unStyledButton userLogin" data-toggle="dropdown" aria-expanded="true">
              <i class="material-icons">person</i>
              {{UserName}}
              <b class="caret"></b>
            </button>
            <ul class="dropdown-menu">
              <li><a href="#"><span class="material-icons" style="padding-right: 10% ;">person</span> Profile</a></li>
              <li><a target="_blank" href="{{backEndUrl}}/admin/"><span class="material-icons" style="padding-right: 10% ;">admin_panel_settings</span>Admin</a></li>
              <li><a [routerLink]="['/myapprovals']"><span class="material-icons" style="padding-right: 10% ;">person</span>My Approvals</a></li>
              <li class="divider"></li>
              <li><a (click)="cacheclear()" style="cursor: pointer"><span class="material-icons" style="padding-right: 10% ;">autorenew</span>Clear App Cache</a></li>
              <li class="divider"></li>
              <li ><a (click)="logout()" href="{{LogoutURL}}"><span class="material-icons" style="padding-right: 10% ;">logout</span>Logout</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
