<div class="main-content">
    <button mat-raised-button class="btn btn-outline-success p-2" [routerLink]="['/dashboard']"> <mat-icon>arrow_back_ios</mat-icon>Back to Dashboard</button><span>&nbsp;</span>
    <!-- Adding back to approvals button based on condition -->
    <button mat-raised-button *ngIf = "showBackButton" class="btn btn-outline-success p-2" [routerLink]="['/myapprovals']"> <mat-icon>arrow_back_ios</mat-icon>Back to My Approvals</button>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
             
                <div class="wizard-container">
                    <div class="card card-wizard">
                        <div class="card-header text-center">
                            <!-- view CR list -->
                        <button mat-mini-fab style="float: right;background-color: #4caf50;" (click)="viewCRs()" matTooltip="View CRs" matTooltipPosition="right"><mat-icon>request_quote</mat-icon></button>
                            <h2 class="card-title">Project Details</h2>
                        </div>
                        <div class="nav nav-pill heading-style">PROJECT</div>
                        <div *ngIf="!allContentLoaded" class="bar_overlay"></div>
                        <mat-progress-bar mode="indeterminate" *ngIf="!allContentLoaded"></mat-progress-bar>
                        <br/><br/>
                        <div class="row justify-content-center content-padding" *ngIf="ActivationDetails?.length && allContentLoaded">
                            <div class="col-sm-3">
                                <span class="title-font">Project Title</span>
                                <div>{{projectTitle}}</div>
                            </div>
                            <div class="col-sm-2">
                                <span class="title-font">Budget Group</span>
                                <div>{{budgetGroup}}</div>
                            </div>
                            <div class="col-sm-2">
                                <span class="title-font">Project Owner</span>
                                <div>{{projectOwner}}</div>
                            </div>
                            <div class="col-sm-5">
                                <span class="title-font">Project Description</span>
                                <div>{{projectDesc}}</div>
                            </div>
                        </div>
                        <br/>
                        <div>
                        <div class="content-padding">
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let activation of responseArray;first as isFirst; let i = index;" (opened)="getAttachmentList(activation.activationWbs);setOpened(i);" [expanded]="path==activation.activationWbs" 
                                (closed)="setClosed(i)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="panel-title">
                                            ACTIVATION : {{activation.activationWbs}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <br/>
                                    <div  class="row justify-content-center">
                                        <div class="col-sm-4">
                                            <span class="title-font">Activation</span>
                                            <div>{{activation.projectActivationDesc}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">Transfer Type</span>
                                        <div>{{activation.transferId.transferType}}</div>
                                        </div>
                                        <div class="col-sm-3">
                                            <span class="title-font">Activation Amount</span>
                                            <div>{{formatAmount(activation.activationAmount)}}</div>
                                        </div>
                                        <div class="col-sm-3">
                                            <span class="title-font">Fiscal Year</span>
                                            <div>{{activation.fiscalYear}}</div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div  class="row justify-content-start">
                                        <div class="col-sm-2">
                                            <span rclass="title-font">Event Initiative</span>
                                            <div>{{activation.eventInitiative}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">Clarizen Number</span>
                                            <div>{{activation.clarizenNumber}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">Start Date</span>
                                            <div>{{formatDate(activation.startDate)}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">End date</span>
                                            <div>{{formatDate(activation.endDate)}}</div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div  class="row justify-content-start">
                                        <div class="col-sm-2">
                                            <span class="title-font">Number of SKU's</span>
                                            <div>{{activation.numberOfSKUs}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">Activation Basis</span>
                                            <div style="text-transform:uppercase;">{{activation.activationType}}</div>
                                        </div>
                                        <div class="col-sm-2">
                                            <span class="title-font">Activation Status</span>
                                            <div style="text-transform:capitalize;">{{activation.activationStatus}}</div>
                                        </div>
                                        <div class="col-sm-4">
                                            <span class="title-font">Vendor</span>
                                            <div>{{activation.vendorId.vendorName}}</div>
                                        </div>
                               
                                    </div>
                                    <br/>
                                    <br/>
                              
                                    <div class="row">
                                        <div class="col-sm-12" *ngIf="attachmentList.length>0">
                                            <div class="table-responsive bg-white px-2">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th class="text-left text-alignmentAtt table-header">Attached Files</th>
                                                    <th class="text-left column-alignment table-header">Uploaded</th>
                                                    <th class="text-left table-header">Download</th>
                                                  
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor = "let Attachment of attachmentList">
                                                    <td class="text-left text-alignmentAtt p-1"><a href="{{'#/attachments/'+Attachment.filename}}" target="_self">{{Attachment.trimmedFileName}}</a></td>
                                                    <td class="text-left column-alignment p-1">{{formatDate(Attachment.createdOn)}}</td>
                                                    <td class="text-left p-1"><mat-icon (click)="viewAttachment(Attachment)">file_download</mat-icon></td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <br/>
                                              <!-- table for populating allocation -->
                                    <div class="row">
                                        <div class="col-sm-12" >
                                            <div class="table-responsive bg-white px-2">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th class="text-left table-header text-alignmentSerial" >#</th>
                                                    <th class="text-left table-header text-alignmentHeader1">Product</th>
                                                    <th class="text-left table-header text-alignmentHeader2">Customer</th>
                                                    <th class="text-left table-header">Allocation Weight</th>
                                                    <th class="text-left table-header">Allocation Amount</th>
                                                  </tr>
                                                </thead>
                                                <tbody *ngFor = "let allocation of pagedList[i];let j = index;">
                                                  <tr>
                                                    <td class="text-left text-alignmentSerial p-1">{{allocation.pageNumber}}</td>
                                                    <td class="text-left text-alignmentHeader1 capElement p-1">{{allocation.productName}}</td>
                                                    <td class="text-left text-alignmentHeader2 capElement p-1">{{allocation.customer}}</td>
                                                    <td class="text-left p-1">{{allocation.allocationWeight}}</td>
                                                    <td class="text-left p-1">{{allocation.calculatedAllocationAmount}}</td>
                                                </tr>
                                                </tbody> 
                                              </table>
                                             
                                                <mat-paginator class="example-container justify-content-end" [length]="activation.allocationArray.length" 
                                                [pageSize]="pageSize" style-paginator [showTotalPages]="3"
                                                (page)="pageEvent = OnPageChange(i,activation.allocationArray.length,$event)" showFirstLastButtons></mat-paginator>
                                          </div>
                                          </div>
                                        </div>
                                </mat-expansion-panel>
                              
                            </mat-accordion>
                         
                        </div>
                    </div>
                    </div>
                </div>
             
            </div>
        </div>
    </div> 
</div>

