import {  Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { HelperService } from '../shared/services/helper.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInsightsMonitoringService } from '../shared/services/appinsights-logging.service';
import { DataCacheService } from '../shared/services/dataCache.service';
import { ActivationService } from '../services/activation.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewActivationConfirmationDialog } from './modal/dialog.component';
import { validateActivationType,validateAmount,validateCustomer,validateDecimalPlaces,validatefiscalYear,validateNumberString,validateProduct,validateTransferType, validateVendor } from './activationForm.validator';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { ContentObserver } from '@angular/cdk/observers';

export enum ActivationStatus {
  approved = 'approved',
  draft = 'draft',
  under_review= 'under_review'
}

@Component({
  selector: 'app-activation-details',
  templateUrl: './activation-details.component.html',
  styleUrls: ['./activation-details.component.css']
})

export class ActivationDetailsComponent implements OnInit, OnDestroy{
  activationForm!: any;
  activations: any = []
  UniqueFiscalYears: any[] = [];
  FiscalYears: any[] = [];
  filteredFY: any[] = [];
  Vendors: any[] = [];
  customers: any[] = [];
  products: any[] = [];
  typesOfAllocations = ['product', 'customer'];
  typeOfAllocation:any = 'product';
  serviceTypeOfPackaging:any = 'PACKAGING'
  ChangeActivations: any[] = [];  
  ChangedAllocs: any[] = [];
  minDate: any;
  maxDate: any;
  id:any;
  BudgetGroups: any;
  spendType:any;
  activationDescription:any;
  activationWbsArray:any[]=[];
  trackActivationWbsArray:any[]=[];
  activationFormArray:any[]=[];
  firstElem:any;
  isReadOnly:boolean = true;
  isPackagingServiceType: boolean;
  filterActivations!: any;
  filterTransferTypes!: any;
  selectedFiscalyear:any;
  filterVendors:any;
  isCustomerOrProduct:boolean = true;
  filterCustomers!: any;
  filterProducts!: any;
  ProjectId:any;
  ProjectOwnerZid:any
  selectedVendor:any;
  selectedCustomer:any
  selectedProduct:any;
  trackDefaultActivation:any;
  selectedFiles:any;
  attachmentList = [];
  transferTypes :any[] = [];
  selectedTransferType : any;
  start: any;
  end: any;
  filterFiscalYear : any;
  defaultTransferType : any;vendorname :any;
  AllocationForms: FormGroup;
  ActForms: FormGroup;
  Project: FormGroup;
  showAllocationTable: boolean;
  totalAllocationAmounts:Array<number>=[];
  enableUpdate : any;
  currentlyOpenedItemIndex = 0;  
  trackActivationArray: any[];
  attachmentListArray: any=[];
  showSubmit: boolean=false;
  trackAllocationCountArray: any=[];
  trackAttaCount:any=[];
  showSave: boolean=false;
  showSpinner: boolean = true;
  trackAllocationArray: any=[];
  projectDetails: any;
  saveAndClose : boolean = false;
  submitClicked = false;
  editedCR = false;
  editedDraft = false;
  showClose = false;
  submitArray: any[]=[];
  noAttaActArray: any[]=[];
  underReviewArray: any[]= [];
  uploadStatus = false;
  saveStatus = false;
  docCount:any=0;
  path:any;
  disableAddButton  = false;
  
   constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, 
    private projectService: ProjectService, private helper:HelperService,private CurrencyPipe: CurrencyPipe, 
    private _snackBar:MatSnackBar,private router: Router, 
    private cacheService: DataCacheService,private datePipe : DatePipe,
    private dialog: MatDialog,
    private activationService:ActivationService) {
    }
  ngOnDestroy(): void {
    this.projectService.projectDetails.next([]);
  }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("projectId");
    await this.getTransferTypes();
    await this.getFiscalYears();
    await this.getCustomers();
    await this.LockConditionCheck();
    this.ProjectOwnerZid = this.cacheService.get('zid');
    this.Project = this._formBuilder.group({
      activationsForms: this._formBuilder.array([])
    });
    this.AllocationForms = this._formBuilder.group({
      allocations: this._formBuilder.array([])
    });
    this.ActForms = this._formBuilder.group({
      activationsForm: this._formBuilder.array([])
    }); 
    if(this.id != 0){
      this.path = this.id;
      let pIdList = this.id.split(".");
      this.id=pIdList[0]+'.'+pIdList[1];//getting projectId from wbs
      //service for populating activations and allocations details when id != 0
      this.projectService.getActivationAllocations(this.id).subscribe(
          {
            next: async (response) =>{
              //checking reponse length to detect whether any activations are there
              if(response?.length){
                let actData : any = []; 
                actData = response;
                let bId = actData[0].projectId.budgetGroupId.budgetGroupId;
                await this.getProducts(bId);
                await this.getActivations(bId);
                await this.getVendorByBId(bId);
                this.id = this.ProjectId = actData[0].projectId.projectId;
                let activationData = actData.filter((activation,i,arr)=>{
                  return arr.findIndex(act => act.activationWbs.activationWbs === activation.activationWbs.activationWbs)===i
                }); 
                this.formActivationObject(activationData,actData);
              }else{
                //service for getting project details when there is no changed activations for project
                this.projectService.getProjectBaseObject(this.id).subscribe(
                  {
                    next: async (data1) =>{
                   //checking whether response is present/not(until new project is created it hold empty list);
                      this.projectDetails = data1;
                      this.getProjectDetails();
                    }, error: (error: any) => {
                      this._snackBar.open("Failed to load project details. "+ error, "X", {panelClass: ['error-snackbar']  });
                    }
                });
              }
            }, error: (error: any) => {
              this._snackBar.open("Failed to load project activation details. "+ error, "X", {panelClass: ['error-snackbar']  });
            }
          }
      )
    }else{
      this.showSpinner = false;
    }
    //service for getting project details for a newly created project
this.projectService.projectDetailsObservable.subscribe(async(response:any)=>{
  if(response.length != 0){
    //checking whethere reponse is present/not(until new project is created it hold empty list);
      this.showSpinner = true;
      this.projectDetails = response[0];
      this.getProjectDetails();
      }
    });
  }
  formActivationObject(activationData:any,data:any){
    let responseArray = [];
    for(let i=0;i<activationData.length;i++){
      let activationObject1 = activationData[i].activationWbs;
      let activationObject = this.getActivationObject(activationObject1);
      let allocationResponseArray = [];
      for(let j=0;j<data.length;j++){
        if(activationObject.activationWbs == data[j].activationWbs.activationWbs){
          allocationResponseArray.push(data[j]);
        }
      }
      activationObject.allocationArray = allocationResponseArray;
      responseArray.push(activationObject);
    }
    this.MapActivationResponse(responseArray);
  }
  //creating activation objects from observable response
  getActivationObject(activationObject:any):any{
    activationObject.vendorName = activationObject.vendorId.vendorName;
    activationObject.vendorId = activationObject.vendorId.vendorId;
    activationObject.transferId = activationObject.transferId.transferId;
    activationObject.transferType = activationObject.transferId.transferType;
    activationObject.activationDesc= activationObject.spendType.activationDesc;
    activationObject.marketingType = activationObject.spendType.marketingType;
    activationObject.service = activationObject.spendType.service;
    activationObject.spendType = activationObject.spendType.spendType;
    activationObject.projectId = activationObject.projectId.projectId;//breaking down projectId object to single variable
    return activationObject;
  }
  get allocations() {
    return this.AllocationForms.controls["allocations"] as FormArray;
  }
  get activationsForm() {
    return this.ActForms.controls["activationsForm"] as FormArray;
  }
  get activationsForms(): FormGroup {
    return this._formBuilder.group({
      activationWbs:new FormControl(""),
      projectId: new FormControl(""),
      projectActivationDesc: new FormControl(''),
      activationDesc: new FormControl("",[Validators.required,validateActivationType(this.activations)]),
      transferId: new FormControl(""),
      activationAmount: new FormControl("", [Validators.required,validateAmount()]),
      fiscalYear: new FormControl("", [Validators.required,validatefiscalYear(this.FiscalYears)]),
      eventInitiative: new FormControl(""),
      clarizenNumber: new FormControl("0"),
      startDate: new FormControl("",[Validators.required]),
      endDate: new FormControl("", [Validators.required]),
      numberOfSKUs: new FormControl("0"),
      mmNumber:new FormControl("0"),
      vendorId: new FormControl(""),
      activationType: new FormControl("product"),
      defaultActivation:new FormControl(""),
      product: new FormControl(""),
      customer: new FormControl(""),
      createdBy: new FormControl(this.ProjectOwnerZid),
      modifiedBy: new FormControl(this.ProjectOwnerZid),
      isSpendTypeBD:false,
      spendType:new FormControl(""),
      activationStatus:new FormControl("draft"),
      singleCustomerId:new FormControl(""),
      singleCustomer:new FormControl(""),
      singleZplnLvl:new FormControl(""),
      singleProduct:new FormControl(""),
      vendorName:new FormControl("",[Validators.required,validateVendor(this.Vendors)]),
      transferType: new FormControl("",[validateTransferType(this.transferTypes)]),
      newActivation:true,
      deleteFlag:false,
      totalAllocationAmount:0,
      totalAllocationWeight:0,        
      attachmentListArray:[],
      newAttaListArray:[],
      minDate:new Date(),
      maxDate:new Date(),
      disabledAddAlloc:false, 
      closedFiscalYear:false, 
      totalPiecesOfArt:new FormControl("0",[validateNumberString()]),   
      allocationsForms: this._formBuilder.array([])
    });
  
  }
  get allocationsForms(): FormGroup {
    return this._formBuilder.group({
      activationWbs: new FormControl(""),
      allocationDesc: new FormControl(""),
      allocationWbs: new FormControl(""),
      createdBy: new FormControl(""),
      createdOn: new FormControl(""),
      modifiedBy: new FormControl(""),
      modifiedOn: new FormControl(""),
      allocationWeight: new FormControl("",[validateDecimalPlaces()]),
      projectId: new FormControl(""),
      customerId: new FormControl(""),      
      customer:new FormControl(""),
      zplnLvl: new FormControl(""),      
      productName:new FormControl(""),
      allocationType: new FormControl(""),
      calculatedAllocationAmount: new FormControl(""),
      newAllocation:false,      
      deleteFlag:false
    });
  }
  async getProjectDetails(){
    this.id = this.ProjectId = this.projectDetails.projectId;
    await this.getProducts(this.projectDetails.budgetGroupId);
    await this.getActivations(this.projectDetails.budgetGroupId);
    await this.getVendorByBId(this.projectDetails.budgetGroupId);
    let ActivationForm = this.Project.get('activationsForms');
    if(!((ActivationForm as FormArray).controls.length>0)){
      this.NewActivation(true);
      this.showSave=true;
     } 
  }

  changeAllocationType(selected: any,activation:any) {
    this.typeOfAllocation = selected.value;
    this.isCustomerOrProduct = true;    
    this.setCustomerOrProductValidator(activation);
    this.setCustomerOrProductAllocValidator(activation.get('allocationsForms'),this.typeOfAllocation);
    this.setAddalloc(activation);
  }

  setCustomerOrProductValidator(activation:any){    
      if(activation.get('activationType').value=='product'){
        activation.get('singleCustomer')?.enable();
        activation.get('singleCustomer').setValidators([Validators.required,validateCustomer(this.customers)]);
        activation.get('singleProduct').disable();        
      }else{
        activation.get('singleProduct').enable();
        activation.get('singleProduct').setValidators([Validators.required,validateProduct(this.products)]);
        activation.get('singleCustomer').disable();        
      }     
  }
  setCustomerOrProductAllocValidator(alloc:any,typeOfAllocation:any){
    if(alloc.length>0){
      for(let i=0;i<alloc.length;i++){
        alloc.get(i.toString()).get('allocationType').setValue(typeOfAllocation);
        if(typeOfAllocation=='product'){          
          alloc.get(i.toString()).get('productName')?.enable();
          alloc.get(i.toString()).get('productName')?.setValidators([Validators.required,validateProduct(this.products)]);
          alloc.get(i.toString()).get('customer')?.disable();
        }else{
          alloc.get(i.toString()).get('customer')?.enable();
          alloc.get(i.toString()).get('customer')?.setValidators([Validators.required,validateCustomer(this.customers)]);
          alloc.get(i.toString()).get('productName')?.disable();
        }
      }
    }
  }

  getVendorByBId(bid:any){
    return new Promise(resolve => {
      this.projectService.getVendorByBudgetGroup(bid).subscribe({
        next: (data) =>{
          this.Vendors = data;
          this.Vendors.sort((a,b)=> a.vendorName.toLowerCase().localeCompare(b.vendorName.toLowerCase()));
          resolve(true);
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of vendors. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
       });
      });
  }
  getFilteredVendors(activation){   
   let vendor=activation.get('vendorName').value;
    this.filterVendors =  (vendor ? this._FilterVendors(vendor) : this.Vendors.slice());  
    if(activation.get('vendorName').valid) {//validation for user input
      this.selectVendor(activation.get('vendorName').value,activation);
    }
    this.validateForm();
  }

  getActivations(budgetGroupId) {
    return new Promise(resolve => {
      this.projectService.getActivations(budgetGroupId).subscribe({
        next: (response) =>{
          this.activations = response;
          this.activations.sort((a,b)=> a.activationDesc.toLowerCase().localeCompare(b.activationDesc.toLowerCase()));
         resolve(true);
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of activation spend types. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      });
    });
  }

  getfilteractivation(activation: any) {
    let act=activation.get('activationDesc').value;
    this.filterActivations = (act ? this._FilterActivations(act) : this.activations.slice());
    if(activation.get('activationDesc').valid){//validation for user input
      this.selectActivationType(activation.get('activationDesc').value,activation);
    }
    this.validateForm();
  }

  private _FilterTransferTypes(value:any) {
    const filterValue = value.toLowerCase();
    return this.transferTypes.filter((type : any) => type.transferType.toLowerCase().includes(filterValue));
  }

  private _FilterActivations(value: any) {
    const filterValue = value.toLowerCase();
    return this.activations.filter((activation: any) => activation.activationDesc.toLowerCase().includes(filterValue));
  }

  private _FilterVendors(value: any) {
    const filterValue = value.toLowerCase();
    return this.Vendors.filter((vendor: any) => vendor.vendorName.toLowerCase().includes(filterValue));
  }
  private _FilterCustomers(value: any) {
    const filterValue = value.toLowerCase();
    return this.customers.filter((customer: any) => customer.customerName.toLowerCase().includes(filterValue));
  }

  private _FilterProducts(value: any) {
    const filterValue = value.toLowerCase();
    return this.products.filter((product: any) => product.productDescription.toLowerCase().includes(filterValue));
  }

  getProducts(budgetGroupId) {
    return new Promise(resolve => {
      this.projectService.getProducts(budgetGroupId).subscribe({
         next: (products) =>{
          this.products = products;
          this.products.sort((a,b)=> a.productDescription.toLowerCase().localeCompare(b.productDescription.toLowerCase()));
          resolve(true); 
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of products. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
       });
      });
  }
  getFilteredProductsAllocation(activation:any,index:any){//validation for user input
    let product= activation.get('allocationsForms').controls[index].get('productName')?.value;
    this.filterProducts = (product ? this._FilterProducts(product) : this.products.slice());
    if(activation.get('allocationsForms').controls[index].get('productName').valid){
      this.setProduct(activation.get('allocationsForms').controls[index].get('productName')?.value,
      activation.get('allocationsForms').controls[index],activation.get('allocationsForms').controls,activation);
    }
    this.validateForm();
  }
  getFilteredProducts(activation:any){
    let product = activation.get('singleProduct')?.value;
    this.filterProducts = (product ? this._FilterProducts(product) : this.products.slice());
    if(activation.get('singleProduct').valid){//validation for user input
      this.setProductAllocationbasis(activation.get('singleProduct')?.value,activation);
    }
  }

  getCustomers() {
    return new Promise(resolve => {
      this.projectService.getCustomers().subscribe({
        next: (customers) =>{
          this.customers = customers; 
          this.customers.sort((a,b)=> a.customerName.toLowerCase().localeCompare(b.customerName.toLowerCase()));
          resolve(true);   
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of customers. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }); 
    });
  }
  getFilteredCustomers(activation:any){
    let customer = activation.get('singleCustomer')?.value; 
    this.filterCustomers = (customer ? this._FilterCustomers(customer) : this.customers.slice()); 
    if(activation.get('singleCustomer').valid){//validation for user input
      this.setCustomerAllocationbasis(activation.get('singleCustomer')?.value,activation);
    }
    this.validateForm();
  }
  getFilteredCustomersAllocation(activation:any,index:any){
    let customer = activation.get('allocationsForms').controls[index].get('customer')?.value;
    this.filterCustomers = (customer ? this._FilterCustomers(customer) : this.customers.slice()); 
    if(activation.get('allocationsForms').controls[index].get('customer').valid) {//validation for user input
      this.setCustomer(activation.get('allocationsForms').controls[index].get('customer')?.value,
      activation.get('allocationsForms').controls[index],activation.get('allocationsForms').controls,activation);
    }  
  }
  getFiscalYears() {
    return new Promise(resolve => {
      this.projectService.getFiscalYears().subscribe({
        next: (fiscalyears) =>{
          this.FiscalYears = fiscalyears; 
          resolve(true); 
        }, error: (error: any) => {
          this._snackBar.open("Failed to load active fiscal years. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      });
    });
  }

  getFilteredFiscalyear(activation:any){
    let year = activation.get('fiscalYear')?.value;
    this.filterFiscalYear = (year ? this._FilterFiscalYear(year) : this.FiscalYears.slice());
    if(activation.get('fiscalYear').valid){//validation for user input
      this.SetMinMaxDates(activation.get('fiscalYear')?.value,activation);
    }
    this.validateForm();
  }

  private _FilterFiscalYear(value : any){
    const filterValue = value;
    return this.FiscalYears.filter((year: any) => year.fiscalYear.toString().includes(filterValue.toString()));
  }

  selectActivationType(event:any,activation:any) {
    let selectedActObj = this.activations.filter(function (el: any) {
        return el.activationDesc.toUpperCase() == event.toUpperCase();
      });
      activation.get('activationDesc').setValue(selectedActObj[0].activationDesc);
      this.activationDescription = activation.get('activationDesc').value;
    
    let activationTypeList=[];
    let activationFormControls=(this.Project.get('activationsForms')as FormArray).controls;
    for(let i=0;i<activationFormControls.length;i++) {
      if(activation.get('activationWbs').value!=activationFormControls[i].get('activationWbs').value){ 
        activationTypeList.push(activationFormControls[i].get('spendType').value);
      }
    }
    if(!activationTypeList.includes(selectedActObj[0].spendType)){ 
       let activationWbs=this.ProjectId+'.'+selectedActObj[0].spendType;
       activation.get('activationWbs').setValue(activationWbs);            
        this.spendType = selectedActObj[0].spendType;
        activation.get('spendType').setValue(this.spendType); 
        if (this.spendType == 'BD1' || this.spendType == 'BD2' || this.spendType == 'BD3') {
          activation.get('isSpendTypeBD')?.setValue(true);
        }
        else {
          activation.get('isSpendTypeBD')?.setValue(false);
        }
        this.isPackagingServiceType = selectedActObj[0].service == this.serviceTypeOfPackaging;
        this.enablePackagingTypeEdits(activation);
        let allocations=(activation.get('allocationsForms')as FormArray).controls;
        if(allocations?.length>0){
          for(let ele in allocations) { 
            allocations[ele].get('activationWbs').setValue(activation.get('activationWbs').value);
            this.setAllocationWbs(allocations[ele], activation.get('activationStatus').value, activation);
        }
        }
    }else{
      this._snackBar.open("Selected Activation type already exist.","Close");
      activation.controls['activationDesc'].setErrors({'incorrect':true})
    }
  }

  enablePackagingTypeEdits(activation:any) {
    if (this.isPackagingServiceType) {
      activation.get('clarizenNumber').enable();
      activation.get('numberOfSKUs').enable();
      activation.get('mmNumber').enable();
      activation.get('clarizenNumber').setValidators([Validators.required,validateNumberString()])
      activation.get('numberOfSKUs').setValidators([Validators.required,validateNumberString()])
      activation.get('mmNumber').setValidators([validateNumberString()])
    } else {
      activation.get('clarizenNumber').disable();
      activation.get('numberOfSKUs').disable();
      activation.get('mmNumber').disable();
      activation.get('clarizenNumber').setValue("0");
      activation.get('numberOfSKUs').setValue("0");
      activation.get('mmNumber').setValue("0");
    }
  }
  async MapActivationResponse(response: any) {
    this.trackActivationArray=[];
    let ActivationForm = this.Project.get('activationsForms');
    response.sort((a: any, b: any) => (a.createdOn > b.createdOn ? 1 : -1));
    await this.submitActivationConditionCheck(response);
    await this.saveActivationConditionCheck(response);
    this.FiscalYears.splice(2,1);
    for (let i = 0; i < response.length; i++) {
      if(response[i].activationStatus == 'approved'){
        this.showClose = true;
      }
      let transferId=response[i].transferId;
      let vendorId=response[i].vendorId;
      response[i].startDate = this.formatDate(response[i].startDate);
      response[i].endDate = this.formatDate(response[i].endDate);
      this.currentlyOpenedItemIndex = response.findIndex(act => act.activationWbs === this.path);
      this.NewActivation(false);
      ActivationForm?.get(i.toString())!.patchValue(response[i]);
      this.SetMinMaxDates(response[i].fiscalYear,ActivationForm?.get(i.toString()));
      ActivationForm?.get(i.toString())!.get('defaultActivation')?.setValue(response[0].activationWbs)
      this.trackActivationArray.push(response[i]);
      this.activationWbsArray.push(response[i].activationWbs);
      this.firstElem = response[0].activationWbs;
      this.spendType=response[i].spendType;
      this.setPackagingEdits(ActivationForm?.get(i.toString()));
      ActivationForm?.get(i.toString())!.get('newActivation')?.setValue(false);
      ActivationForm?.get(i.toString())!.get('transferType').setValue(this.transferTypes.find(s=>s.transferId==transferId)?.transferType);
      ActivationForm?.get(i.toString())!.get('vendorName').setValue(this.Vendors.find(v => v.vendorId == vendorId)?.vendorName );      
      this.getActivationAllocations(response[i].allocationArray, i);
      this.getAttachmentList(response[i].activationWbs,i);
      this.setCustomerOrProductValidator(ActivationForm?.get(i.toString()));
      let yearIndex = 0;
      yearIndex = this.FiscalYears.findIndex(v => v.fiscalYear == response[i].fiscalYear);
      if(yearIndex < 0){
        ActivationForm?.get(i.toString())!.get('closedFiscalYear')?.setValue(true);
      }else{
        ActivationForm?.get(i.toString())!.get('closedFiscalYear')?.setValue(false);
      }
      } 
     if(!((ActivationForm as FormArray).controls.length>0)){
      this.NewActivation(true);
      this.showSave=true;
     }
    this.showSpinner = false;
    this.disableAddButton = false;
  }
  //method for closing the timezone difference
  formatDate(date:any){
    let edate = new Date(date);
    edate = new Date(edate.getTime() + edate.getTimezoneOffset() * 60000);
    return edate;
  }

  async setPackagingEdits(activation:any){
    let selectedActObj = this.activations.filter(function (el: any) {
      return el.spendType == activation.get('spendType').value;
    });
    if (activation.get('spendType').value == 'BD1' || activation.get('spendType').value == 'BD2' || activation.get('spendType').value == 'BD3') {
      activation?.get('isSpendTypeBD')?.setValue(true);
    }
    else {
      activation?.get('isSpendTypeBD')?.setValue(false);
    }
    this.isPackagingServiceType = selectedActObj[0].service == this.serviceTypeOfPackaging;
    this.enablePackagingTypeEdits(activation);
  }
  submitActivationConditionCheck(response :any){
    return new Promise(resolve => {
        for (let i = 0; i < response.length; i++) {
        if(response[i].activationStatus=='draft'  || response[i].activationStatus=='approved'){
          this.showSubmit=true;
          resolve(true);
          break;
        }else{
          resolve(true);
        }
        } 
      });  
          
  }
  saveActivationConditionCheck(response:any){
    return new Promise(resolve => {
      for (let i = 0; i < response.length; i++) {
        if(response[i].activationStatus=='draft'){
          this.showSave=true;
          resolve(true); 
          break;
         }else{
          resolve(true); 
        }
       }
    });
   
  }
  //method for checking whether form is valid and atleast one allocation is added
 validateForm(){
  for(let key of (this.Project.get('activationsForms') as FormArray).controls){
    if(key.status === 'INVALID'){
      this.disableAddButton = true;//disable button if activation form is invalid
      break;
    } else if(key.status === 'VALID'){
      if((key.get('allocationsForms')as FormArray).controls.length){
        this.disableAddButton = (key.get('allocationsForms')as FormArray).controls.some(a => a.status === 'INVALID');
        if(this.disableAddButton) break;
      }else{
        this.disableAddButton = true;
      }
    }

  }
  
 }
  NewActivation(fromUI:boolean) {
    return new Promise(resolve => {
      this.disableAddButton = true;
    if(fromUI){
      this.showSave=true;
      let newActObj:any = this.activationsForms;
      newActObj?.patchValue({'showSaveActivationBtn':true,'enableActivationEdit':true});
      newActObj.get('projectId').setValue(this.id);
      newActObj.get('transferType').setValue('Not Transfer');
      newActObj.get('transferId').setValue(this.transferTypes.find(s=>s.transferId=='Not Transfer')?.transferId);
      newActObj.get('disabledAddAlloc').setValue(true);
      (this.Project.get('activationsForms') as FormArray).push(newActObj);
      this.isCustomerOrProduct = true;
      this.typeOfAllocation = 'product';
      newActObj.get('activationType').setValue(this.typeOfAllocation);
      this.setCustomerOrProductValidator(newActObj);
      this.setOpened((this.Project.get('activationsForms') as FormArray).length-1);
      window.scrollTo(0,document.body.scrollHeight);
      this.showSpinner = false;
      resolve(true); 
    }
    else{
      (this.Project.get('activationsForms') as FormArray).push(this.activationsForms);
      this.showSpinner = false;
      resolve(true); 
    }
         
  });
  }
  getActivationAllocations(allocationArray: any, activationindex: any){
    let activationIndex = activationindex;
    let actObj = this.Project.get('activationsForms')?.get(activationIndex.toString());
    this.showAllocationTable=true;
    this.MapAllocationResponse(allocationArray,actObj);
  }

  MapAllocationResponse(response, actObj){
      let AllocForm = actObj.get('allocationsForms') ; 
      response.sort((a: any, b: any) => (a.createdOn > b.createdOn ? 1 : -1)); //sorting the array with creation date
      let totalAllocationAmount=0;
      let totalAllocationWeight = 0;            
      for (let j = 0; j < response.length; j++) {
        this.trackAllocationArray.push(response[j]);
        if (response[j].allocationWeight !== undefined) {
          totalAllocationWeight = totalAllocationWeight + parseFloat(response[j].allocationWeight);
        }
        if (response[j].calculatedAllocationAmount !== undefined) {
          totalAllocationAmount = totalAllocationAmount+ parseFloat(response[j].calculatedAllocationAmount);
        } 
        response[j].projectId=response[j].projectId.projectId;  
        response[j].zplnLvl = response[j].zplnLvl.zplnLvl;
        response[j].activationWbs=response[j].activationWbs.activationWbs;
        response[j].productName=(this.products.find(v => v.zplnLvl == response[j].zplnLvl)?.productDescription);
        response[j].customerId = response[j].customerId.customerId;
        response[j].customer=(this.customers.find(v => v.customerId == response[j].customerId)?.customerName); 
        response[j].calculatedAllocationAmount = this.CurrencyPipe.transform(response[j].calculatedAllocationAmount,' ');
        if (AllocForm.get(j.toString()) == null || AllocForm?.get(j.toString()) == '') {
          AllocForm.push(this.allocationsForms);
        }
        AllocForm.get(j.toString())?.patchValue(response[j]);
        AllocForm.get(j.toString())?.get('newAllocation').setValue(false);
        if(AllocForm.get(j.toString())?.get('allocationType')?.value=='product'){ 
          AllocForm.get(j.toString()).get('productName')?.enable();
          AllocForm.get(j.toString()).get('productName')?.setValidators([Validators.required,validateProduct(this.products)]);
          AllocForm.get(j.toString()).get('customer')?.disable();
        }else{  
          AllocForm.get(j.toString()).get('customer')?.enable();        
          AllocForm.get(j.toString()).get('customer')?.setValidators([Validators.required,validateCustomer(this.customers)]);
          AllocForm.get(j.toString()).get('productName')?.disable();
        } 
        actObj.get('activationType')?.setValue(AllocForm.get('0').get('allocationType')?.value);
        if(j==0){
            if(actObj.get('activationType').value=='product'){
            actObj.get('singleCustomerId').setValue(AllocForm.get(j.toString()).get('customerId')?.value);
            actObj.get('singleCustomer').setValue(AllocForm.get(j.toString()).get('customer')?.value);
            actObj.get('singleZplnLvl').setValue(null);
          }
          if(actObj.get('activationType').value=='customer'){
            actObj.get('singleZplnLvl').setValue(AllocForm.get(j.toString()).get('zplnLvl')?.value);
            actObj.get('singleProduct').setValue(AllocForm.get(j.toString()).get('productName')?.value);
            actObj.get('singleCustomerId').setValue(null);
          }
        }
      }  
      actObj.get('totalAllocationAmount').setValue(Math.round(totalAllocationAmount));  
      actObj.get('totalAllocationWeight').setValue(totalAllocationWeight);
    
  }

  transform(element: any,activation:any) {
    if(activation.get('activationAmount').valid){
      let value = element.target.value;
      value = value.replace(/,/g, '');
      let formattedAmount = this.CurrencyPipe.transform(value,' ');
      element.target.value = formattedAmount;
    }
    this.validateForm();
  }

  async SetMinMaxDates(fy: any,activation:any) {   
    let selectedFY = this.FiscalYears.filter(function (el) {
      return el.fiscalYear == fy;
    });   
    activation.get('minDate').setValue( new Date(this.formatDate(selectedFY[0]?.minDate)));
    activation.get('maxDate').setValue( new Date(this.formatDate(selectedFY[0]?.maxDate)));
  }
  formatTotalAmount(value:any){
    return value.replace(/,/g,'');
  } 
  //calling api for getting lockstatus before saving and submitting
  LockConditionCheck(){
    return new Promise(resolve=> {
    this.projectService.getSiftAppSettings().subscribe((data:any)=>{
      for(let k of data){
       if(k.variableName == 'isSiftLocked' && k.variableValue.toLowerCase() == 'false'){
          this.enableUpdate = true;
          resolve(true);
        }else if(k.variableName == 'isSiftLocked' && k.variableValue.toLowerCase() == 'true'){
          this.enableUpdate = false;
          resolve(true);
        }
      }
    });
  });
  }
  //checking lockcondition before saving
  async saveConditionCheck(){
    let enablePopUp = true;
    this.showSpinner = true;
    let path = this.route.snapshot.routeConfig.path;
    if(path != 'project-details-CR/:projectId'){
      await this.LockConditionCheck();
    }else{
      this.enableUpdate = true;
      enablePopUp = false;
    }
    
    if(this.enableUpdate == true){
      this.saveActivation();
    }else{
      this.showSpinner = false;
     this.dialog.open(ViewActivationConfirmationDialog, {
        data: {enablePopUp}
      });
    }
  }
  //checking lockstatus before submitting
  async submitCondition(){
    let path = this.route.snapshot.routeConfig.path;
    if(path != 'project-details-CR/:projectId'){
      await this.LockConditionCheck();
    }else{
      this.enableUpdate = true;
    }
    this.submitClicked = true;
    if(this.enableUpdate == true){
      this.submitConditionCheck();
    }else{
      let enablePopUp = true;
      this.dialog.open(ViewActivationConfirmationDialog, {//popup message if locked
        data: {enablePopUp}
      });
    }
  }
  async saveActivation(){
    return new Promise(async resolve=> {
      let count=0;
    let hasInvalidActivationArray=[];
    let hasInvalidActivation="";  
    let attachments = "";
    let activationObjArray=[];
    let savedWbs=[];
    let newWbs=[];
    let CRs:any[]=[];
    let activationFormControls=(this.Project.get('activationsForms')as FormArray).controls;    
    for(let i=0;i<activationFormControls.length;i++) {
      if(activationFormControls[i]?.get('activationStatus').value=='draft' || activationFormControls[i]?.get('activationStatus').value=='approved'){
        if(activationFormControls[i]?.get('deleteFlag').value==false){   
          if(this.validateActivationForm(activationFormControls[i],i)==true){
            if (this.checkEditedActivation(activationFormControls[i],i)==true){
              if((activationFormControls[i].get('allocationsForms')as FormArray).controls.length>0){
                    let allocations=(activationFormControls[i].get('allocationsForms')as FormArray).controls
                    let nodeleteCount=0;
                    for(let ele in allocations){
                      if(allocations[ele]['controls']['deleteFlag'].value==false){
                        nodeleteCount++;
                      }
                    }
                      if(nodeleteCount>0){
                        activationObjArray.push(activationFormControls[i]);
                        if(activationFormControls[i].get('newActivation')?.value){
                          newWbs.push(activationFormControls[i].get('activationDesc')?.value)
                        }else if(activationFormControls[i].get('activationStatus')?.value=='draft'){
                          savedWbs.push(activationFormControls[i].get('activationWbs')?.value);
                        }else{
                          CRs.push(activationFormControls[i].get('activationWbs')?.value);
                        }
                        hasInvalidActivation="valid";
                        hasInvalidActivationArray.push(hasInvalidActivation);
                      }else{
                        this.showSpinner = false;      
                        this.setOpened(i);
                        this._snackBar.open('Add atleast one Allocation to the Activation',"Close");
                        hasInvalidActivation="true";
                        break;
                      }
                  
                  }else{   
                    this.showSpinner = false;               
                    this.setOpened(i);
                    this._snackBar.open('Add atleast one Allocation to the Activation',"Close");
                    hasInvalidActivation="true";
                    break;
                  }
                }
                else{
                  hasInvalidActivation="notEdited"
                  hasInvalidActivationArray.push(hasInvalidActivation);
                }
                if((activationFormControls[i]?.get('newAttaListArray')?.value?.length>0)){
 
                  attachments = "attachmentAdded";
 
                }
              }else{ 
                hasInvalidActivation="true";             
                break;
              }  
        }else{
          activationObjArray.push(activationFormControls[i]);
          savedWbs.push(activationFormControls[i].get('activationWbs')?.value);
          hasInvalidActivation="valid";
          hasInvalidActivationArray.push(hasInvalidActivation);
        }       
      }
      count++; 
    }    
    if(activationObjArray && activationObjArray.length>0 && hasInvalidActivationArray.includes("valid") && activationFormControls.length==count){      
      if(CRs.length>0){
          let count = 0;
          //if CRs are present
          for(let key of CRs){
              const dialogRef=this.dialog.open(ViewActivationConfirmationDialog, {
                data: {Savedactivations:savedWbs,NewActivations:newWbs,changeReqList:key},
                width: '30%',
                });
                dialogRef.afterClosed().subscribe( async result => {
                if (result === true) {
                    count++;
                    if(count === CRs.length){
                      await this.saveApiMethod(activationObjArray);
                      //check for attachments after save upload will be called.
                      if(attachments){
                        await this.uploadAttachments();
                      }
                      this.reloadToDoashboard();
                      resolve(true);
                    }
                }
              });
            }
          }
          //if draft projects are edited
          else{
          await this.saveApiMethod(activationObjArray);
          //after successful save, if attachements are present.
          if(this.saveStatus){
            if(attachments){
              await this.uploadAttachments();
            }
          }
          //If the save and submit button is clicked after a successful logic app save, the user will be redirected to the dashboard.
          if(!this.submitClicked){
            this.reloadToDoashboard();
          }
          resolve(true);
          }
    }else if(!hasInvalidActivationArray.includes("valid") && attachments != "attachmentAdded" && !this.submitClicked && !hasInvalidActivationArray.includes("true")){//if no changes are made and all activations are valid.
       if(this.editedDraft){
         this.showSpinner = false;
        this.reloadToDoashboard();
      }
      resolve(true);
    } else if( !hasInvalidActivationArray.includes("valid") && !this.submitClicked && attachments == "attachmentAdded"){//if only attachememts are added
      await this.uploadAttachments();
      this.reloadToDoashboard();
      resolve(true);
    }
    });
  }
  saveApiMethod(data:any) {
    this.showSpinner = true;
    return new Promise(resolve=> {
    this.activationService.saveProjectActivation(data).subscribe({
      next: (resp: any) => {
      if(!this.submitClicked)   {
        this.showSpinner = false;
        //Adding responses from the save logic app by testing if the response has a valid activationWBS  
        if(resp[0].activationWbs.length != 0 || resp[0].activationWbs != 'NA' ){
          this.saveStatus = true;
          this._snackBar.open(`Your new project activation ${resp[0].activationWbs} is currently in the process of saving. Please refresh your browser window after a few moments to see it in the dashboard. ` , 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });  
          resolve(true);
        }
        else{
          this.saveStatus = false;
          this._snackBar.open(`Unable to save activation ${resp[0].activationWbs}, Please try again ` , 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });  
          resolve(true);
        }
          
       }
       else{
        if(resp[0].activationWbs.length !=0 || resp[0].activationWbs !='NA' ){
          this.saveStatus = true;
          this.showSpinner = false;
          this._snackBar.open(`Your new project activation ${resp[0].activationWbs} is currently in the process of saving.` , 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });
          resolve(true);
        }
        else{
          this.saveStatus = false;
          this.showSpinner = false
          this._snackBar.open(`Unable to save activation ${resp[0].activationWbs}, Please try again ` , 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });
          resolve(true);
        }  
       }
       resolve(true);
      },
      error: (error: any) => {
        resolve(true);
        this.showSpinner = false;
        this._snackBar.open("Failed to save activations. "+ error, "X", {panelClass: ['error-snackbar']  });
      }
    });
  });

  }
  checkEditedActivation(activation:any,index){
    if(this.trackActivationArray?.length>index){
      if(activation.get('activationStatus').value == 'draft'){
        this.editedDraft = true;
      }
      if(activation.get('activationStatus').value == 'approved'){
        this.editedCR= true;
      }
        if((activation.get('activationDesc').value != this.trackActivationArray[index].activationDesc) ||
        (activation.get('activationAmount').value != this.trackActivationArray[index].activationAmount) ||
        (activation.get('fiscalYear').value != this.trackActivationArray[index].fiscalYear) ||
        (activation.get('vendorId').value != this.trackActivationArray[index].vendorId) ||
        (activation.get('startDate').value != this.trackActivationArray[index].startDate) ||
        (activation.get('endDate').value != this.trackActivationArray[index].endDate) ||
        (activation.get('numberOfSKUs').value != this.trackActivationArray[index].numberOfSKUs) ||
        (activation.get('clarizenNumber').value != this.trackActivationArray[index].clarizenNumber) ||
        (activation.get('mmNumber').value != this.trackActivationArray[index].mmNumber) ||        
        this.checkNonRequiredFieldEdit(activation,index) ||
        this.checkEditedAllocation(activation,index)
        ){    
          return true;
        }else {          
          return false;
        }

      }else{       
        return true;
      }
  }
  
  checkEditedAllocation(activation:any,index: any){
    let allocForm= (activation.get('allocationsForms') as FormArray).controls;
    for(let i=0;i<allocForm.length;i++){     
        for(let j=0;j<this.trackAllocationArray.length;j++){   
          if(allocForm[i].get('allocationWbs').value==this.trackAllocationArray[j].allocationWbs &&
           allocForm[i].get('newAllocation').value!=true){
            if(allocForm[i].get('deleteFlag').value !=true){
              if(allocForm[i].get('allocationWeight').value!=this.trackAllocationArray[j].allocationWeight ||
                allocForm[i].get('calculatedAllocationAmount').value !=this.trackAllocationArray[j].calculatedAllocationAmount ||
                allocForm[i].get('customerId').value !=this.trackAllocationArray[j].customerId || 
                allocForm[i].get('zplnLvl').value !=this.trackAllocationArray[j].zplnLvl){
                  return true;                
                }
            }else{
              return true;
            }
          }else if(allocForm[i].get('newAllocation').value==true){
            return true;
          }
        }          
    }
  }
  checkNonRequiredFieldEdit(activation:any,index: any){
    if((activation.get('deleteFlag').value==true) ||
    (activation.get('transferId').value != this.trackActivationArray[index].transferId ) || (activation.get('totalPiecesOfArt').value != this.trackActivationArray[index].totalPiecesOfArt)){      
      return true;
    }else{
      return false;
    }
  }

  validateActivationForm(activation:any,index: any){ 
    if(activation.invalid){
      for(let key in activation.controls){
        if(activation.controls[key].invalid){
          if(activation.controls[key] instanceof FormControl){
            //check for valid fiscal year when activation is approved
            if(activation.get('activationStatus').value != 'approved'){
              this.showSpinner = false;
              this._snackBar.open("Enter valid option.", "Close"); 
            }else if(activation.get('activationStatus').value == 'approved' && key == 'fiscalYear'){
              this.showSpinner = false;
              this._snackBar.open("Change request cannot be submitted as this project accrued in a closed fiscal year.","Close"); 
            }else{
              this.showSpinner = false;
              this._snackBar.open("Enter valid option.", "Close"); 
            }
            activation.controls[key].markAsTouched();
            activation.controls[key].setErrors({'noMatch':true});
          }else{
            let allocControl=(activation.controls[key] as FormArray).controls;
            for(let i=0;i<allocControl.length;i++){              
                if(allocControl[i].invalid){
                  for(let control in allocControl[i]['controls']){
                    if(allocControl[i]['controls'][control].invalid){
                      allocControl[i]['controls'][control].markAsTouched();
                      if(control !== 'allocationWeight'){
                        allocControl[i]['controls'][control].setErrors({'noMatch':true});
                      }
                    }
                  }                 
                }              
            }
            this.showSpinner = false;
            this._snackBar.open("Enter valid option.", "Close"); 
          }
          this.setOpened(index);
          break;    
         return false;          
        }
      }
    }else if(new Date(activation.get('endDate').value)<new Date(activation.get('startDate').value)){
      this.showSpinner = false;
      activation.controls['endDate'].markAsTouched();         
      activation.controls['endDate'].setErrors({'noMatch':true});         
      this.setOpened(index);
      this._snackBar.open("End date should be greater than Start date.", "Close");
      return false;
    }
    else{
      return true;
    }     
  }
  
  reloadPage(pjctid: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['project-details', pjctid]);
  }
  reloadToDoashboard() {
    this.projectService.disableButton.next(false);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard']);

  }

  selectVendor(event:any,activation){
    let selectedItem;
    selectedItem = this.Vendors.filter((vendor)=>{
        return vendor.vendorName.toUpperCase() == event.toUpperCase();
      })  
      activation.get('vendorName').setValue(selectedItem[0].vendorName)
      activation.get('vendorId').setValue(selectedItem[0].vendorId);
  }
  setCustomerAllocationbasis(event:any,activation:any){
    let selectedCustomer;
      selectedCustomer = this.customers.filter((customer)=>{
        return customer.customerName.toUpperCase() == event.toUpperCase();
      });  
    activation.get('singleCustomer').setValue(selectedCustomer[0].customerName);
    this.selectedCustomer = selectedCustomer[0];
    activation?.get('singleCustomerId')?.setValue(this.selectedCustomer.customerId);   
      this.setAddalloc(activation);        
      for(let i=0;i<activation.get('allocationsForms').length;i++){       
          activation.get('allocationsForms').get(i.toString()).get('customerId').setValue(this.selectedCustomer.customerId);
        this.setAllocationWbs(activation.get('allocationsForms').get(i.toString()), activation.get('activationStatus').value, activation);
      }   
  }
  setCustomer(event:any,allocation:any,allocations,activation){
    let selectedCustomer ;
      selectedCustomer = this.customers.filter((customer)=>{
        return customer.customerName.toUpperCase() == event.toUpperCase();
      });
      allocation.get('customer').setValue(selectedCustomer[0].customerName);
    let selectedCustomerList = [];
    for(let all of allocations){
      if(all.value.allocationWbs!=allocation.get('allocationWbs').value){
        selectedCustomerList.push(all.value.customerId);
      }
    }
    if(!selectedCustomerList.includes(selectedCustomer[0].customerId)){
      this.selectedCustomer = selectedCustomer[0];      
      allocation.get('customerId').setValue(this.selectedCustomer.customerId);
      this.setAllocationWbs(allocation, activation.get('activationStatus').value, activation);
    }else{
      this._snackBar.open("Selected Customer already exist.","Close");
        allocation.controls['customer'].setErrors({'incorrect':true});
    }
  }
  setAllocationWbs(allocation, activationStatus, activation) {
    if (allocation.get('newAllocation').value == true || !((activation.get('activationStatus').value != 'draft' && activation.get('activationStatus').value != 'approved') || activation.get('deleteFlag').value == true ||
      activation.get('newActivation').value != true || (activation.get('activationStatus').value != 'approved' && !this.enableUpdate))) {
      let allocationWbs: any;
      if (activationStatus == 'approved') {
        if (allocation.get('deleteFlag').value == false && allocation.get('newAllocation').value == true) {
          allocationWbs = allocation.get('activationWbs').value + '_' + allocation.get('zplnLvl').value + '_' +
            allocation.get('customerId').value;
        }
      } else {
        if (allocation.get('deleteFlag').value == false && allocation.get('newAllocation').value == true) {
          allocationWbs = allocation.get('activationWbs').value + '.' + allocation.get('zplnLvl').value + '.' +
            allocation.get('customerId').value;
        }
      }
      if (allocationWbs != undefined) {
        allocation.get('allocationWbs').setValue(allocationWbs);
      }
    }
  }
 
  setAddalloc(activation){
    if(activation?.get('activationAmount').value){
      if(activation?.get('activationType').value=='product'){        
        if(activation?.get('singleCustomer').valid && activation?.get('singleCustomer').value !=""){
          activation?.get('disabledAddAlloc').setValue(false);
        }else{
          activation?.get('disabledAddAlloc').setValue(true);
        }
      }else if(activation?.get('activationType').value=='customer'){
        if(activation?.get('singleProduct').valid && activation?.get('singleProduct').value !=""){
          activation?.get('disabledAddAlloc').setValue(false);
        }else{
          activation?.get('disabledAddAlloc').setValue(true);
        }
      }
    }else{
      activation?.get('disabledAddAlloc').setValue(true);
      }
  }
  setProductAllocationbasis(event:any,activation){
    let selectedProduct;
    selectedProduct = this.products.filter((product)=>{
        return product.productDescription.toUpperCase() == event.toUpperCase();
    });
    activation.get('singleProduct').setValue(selectedProduct[0].productDescription);
    this.selectedProduct = selectedProduct[0];
    activation?.get('singleZplnLvl')?.setValue(this.selectedProduct.zplnLvl);    
      this.setAddalloc(activation);       
      for(let i=0;i<activation.get('allocationsForms').length;i++){        
          activation.get('allocationsForms').get(i.toString()).get('zplnLvl').setValue(this.selectedProduct.zplnLvl);
        this.setAllocationWbs(activation.get('allocationsForms').get(i.toString()), activation.get('activationStatus').value, activation);
      }    
  }
  setProduct(event:any,allocation,allocations,activation){
    
      let selectedProduct = this.products.filter((product)=>{
        return product.productDescription.toUpperCase() == event.toUpperCase();
      });
      allocation.get('productName').setValue(selectedProduct[0].productDescription);
    let selectedProductList = [];
    for(let all of allocations){
      if(all.value.allocationWbs!=allocation.get('allocationWbs').value){
        selectedProductList.push(all.value.zplnLvl);
      }
    }   
    if(!selectedProductList.includes(selectedProduct[0].zplnLvl)){
      this.selectedProduct = selectedProduct[0];      
      allocation.get('zplnLvl').setValue(this.selectedProduct.zplnLvl);
      this.setAllocationWbs(allocation, activation.get('activationStatus').value, activation);
    }else{
      this._snackBar.open("Selected Product already exist.","Close");
      allocation.controls['productName'].setErrors({'incorrect':true});
    }   
  }  

  cancelActivation(activation:any,index:any){
    let actFormcontrol=<FormArray>this.Project.controls['activationsForms'] ;    
    if(activation.get('newActivation').value==true){    
      actFormcontrol.removeAt(index);
      this._snackBar.open("Deleted new activation . ", 'Close', {panelClass:['green-snackbar'] });
    }else{
        const dialogRef=this.dialog.open(ViewActivationConfirmationDialog, {
          data: {deleteMessage:"Do you want to delete the activation "+activation.get('activationWbs').value+"?",
          deleteAlert:"On clicking DELETE, the activation will be staged for deletion . The delete process will be completed once you save the activations."}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {        
              let activationwbs=activation.get('activationWbs').value;
              activation.get('deleteFlag').setValue(true);
              this._snackBar.open("Activation  " + activationwbs +" is staged for deletion." ,'Close', {panelClass:['green-snackbar'] });
            }          
        });  
    }
    this.disableAddButton = false;
  }  

  selectFiles(event: any,activation :any) {
    this.selectedFiles =[];
    let file = event.target.files.item(0);
    if(file.size>(50*1024*1024)){
      this._snackBar.open('File size greater than 50MB. Please select a smaller attachment.', 'Close', {panelClass:['red-snackbar']});
    }else if(file.name.length>180){//adding validation for length of file name
      this._snackBar.open('The file name is over 180 characters, please reupload the file with a shorter title in order to submit an activation', 'Close', {panelClass:['red-snackbar']});
    }
    else{
      this.selectedFiles.push(file);    
      if(!activation.get('newAttaListArray').value){
      activation.get('newAttaListArray').setValue(this.selectedFiles);
      }else{       
        activation.get('newAttaListArray').value.push(file);
      }
    }   
  }

  async uploadAttachments(){
    this.showSpinner = true;
    let uploadAttaArray=[];
    let activationFormArray=(this.Project.get('activationsForms')as FormArray).controls;
    return new Promise(async resolve => {
    await new Promise<void>(async resolve => { 
    for(let j=0;j<activationFormArray.length;j++) {
      if(activationFormArray[j]?.get('newAttaListArray')?.value && activationFormArray[j]?.get('newAttaListArray')?.value.length>0 && activationFormArray[j]?.get('deleteFlag')?.value !=true){
          for (let i: any = 0; i < activationFormArray[j]?.get('newAttaListArray')?.value.length; i++) {
              await new Promise<void>(resolve => {
              this.readBase64(activationFormArray[j]?.get('newAttaListArray')?.value[i])
                .then((data) => {
                  let fileObj={file:'',filename:'',activationWbs:''};
                  let base64 = data.split(",");
                  let sDocumentName = activationFormArray[j]?.get('newAttaListArray')?.value[i].name;
                  fileObj.file=base64[1];
                  fileObj.filename=sDocumentName;
                  if(activationFormArray[j]?.get('activationWbs')?.value){            
                    fileObj.activationWbs=activationFormArray[j]?.get('activationWbs')?.value;
                  }else{
                    fileObj.activationWbs=this.ProjectId+'.'+activationFormArray[j]?.get('spendType')?.value;
                  }
                  uploadAttaArray.push(fileObj);
                  resolve();          
                });              
            });
          }       
        }       
      }
      resolve();
    });
    if(uploadAttaArray.length>0){
      this.activationService.saveActivationSupportingDocuments(uploadAttaArray).subscribe({
        //Adding responses from the upload attachment logic app by testing if the response has a valid filepath. 
        next: (resp: any) => {
         for(let i:any = 0; i < resp.length; i++) {
          if(resp[i].response.filepath.length != 0 || resp[i].response.filepath != 'NA'){
              this.docCount =this.docCount+1
          }
        }
         
        if(resp.length ==this.docCount){
         this._snackBar.open("Successfully Uploaded " + this.docCount + ` Files for the activation ${resp[0].response.activationWbs}`, 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });
         this.uploadStatus=true;
         this.showSpinner = false;
         resolve(true);
        }
        //if the response does not contain filepath.
        else{
         this._snackBar.open(`Failed to Uploaded Files for the activation ${resp[0].response.activationWbs}` , 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });
         this.uploadStatus=false;
         this.showSpinner = false;
         resolve(true);
        }
        },
        error: (error: any) => {
         this._snackBar.open("Failed to upload attachments. "+ error, "X", {panelClass: ['error-snackbar']  });
         resolve(true);
        }
      });
    }
  });
  }

  trimActivationList(filename:String):string{
    const String2 = filename.slice(filename.indexOf('_')+1);
    const String1 = String2.slice(String2.indexOf('_')+1);
    return String1;
  }

  getAttachmentList(activationWbs:any,index:any){
    let activation=this.Project.get('activationsForms').get(index.toString());
    this.attachmentList=[];    
    this.projectService.getListOfDocuments(activationWbs).subscribe(
      {
        next:(resp:any) => {
          this.trackAttaCount.push(resp.length);
          if(resp!== null && resp!== undefined && resp.length!= 0)
          {
          this.attachmentList = resp;
          for(let file of this.attachmentList){
          file['trimmedFileName'] = this.trimActivationList(file.filename);
          file.filename = this.projectService.replaceCharacters(file.filename);
          file.filename = encodeURIComponent(file.filename);
          }
          this.attachmentList.reverse();
          activation.get('attachmentListArray')?.setValue(this.attachmentList);
        }
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of attachments. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      
      });  
    
  }

  deleteAttachment(attachment:any,activation:any){
    this.projectService.deleteAttachment(attachment.attachmentId).subscribe(
      {
        next:(resp:any) =>{
          const index =activation.get('attachmentListArray').value.indexOf(attachment);          
          activation.get('attachmentListArray').value.splice(index,1);
          this._snackBar.open("Deleted File " + attachment.trimmedFileName, 'Close', {panelClass:['green-snackbar'] });
        },
        error: (error: any) => {
          this._snackBar.open("Failed to delete attachment. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
    )
  }

  downloadAttachment(attachment:any){
    this.projectService.downloadAttachment(attachment.attachmentId).subscribe(
      {
        next: (resp: any) => {
          this.projectService.downloadAttachmentInUI(resp)
        }, error: (error: any) => {
          this._snackBar.open("Failed to download attachment. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
    )
  }

 closeAttachment(i:any,activation){
  activation.get('newAttaListArray').value.splice(i,1);
  }

  private readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }
  getTransferTypes(){
    return new Promise(resolve => {
      this.projectService.getTransferTypeList().subscribe(
        {
          next : (response:any) =>{
            this.transferTypes = response;
            this.transferTypes.sort((a,b)=> a.transferType.toLowerCase().localeCompare(b.transferType.toLowerCase()));
            this.defaultTransferType = 'Not Transfer'; 
            resolve(true);
          },
          error: (error: any) => {
            this._snackBar.open("Failed to load list of Transfer types. "+ error, "X", {panelClass: ['error-snackbar']  });
          }
        }
      )
      
    })
  }
  getfilterTransferType(activation:any){
    let type = activation.get('transferType').value;
    this.filterTransferTypes = (type ? this._FilterTransferTypes(type) : this.transferTypes.slice());
    if(activation.get('transferType').valid){
      this.getTransferTypeChange(activation.get('transferType').value,activation);

    }
    this.validateForm();
  }
  getTransferTypeChange(event:any,activation:any){
    this.selectedTransferType = this.transferTypes.filter((type)=>{
      return type.transferType.toUpperCase() == event.toUpperCase();
    });//fix for transfer type selecting from partial matches
    activation.get('transferType').setValue(this.selectedTransferType[0].transferType);
    activation.get('transferId').setValue(this.selectedTransferType[0].transferId);
  }

  clearActivationType(index:any){
    this.Project?.get('activationsForms')?.get(index.toString())?.get('activationDesc').setValue("");
  }
  getCreatedDate(createOn:any){
    const creationdate = new Date(createOn);
    const createdOn = this.datePipe.transform(creationdate,'MM/dd/yyyy');
    return createdOn
  }
  addAllocation(activation){
    let totalAllocationWeight = activation.get('totalAllocationWeight').value + 1;
    activation.get('totalAllocationWeight').setValue(totalAllocationWeight);
    let allocationForm = this.allocationsForms;
    allocationForm?.get('newAllocation')?.setValue(true);
    allocationForm.get('projectId')?.setValue(this.ProjectId);
    allocationForm.get('allocationType')?.setValue(activation.get('activationType').value); 
    if(allocationForm.get('allocationType')?.value=='product'){
      allocationForm.get('customerId')?.setValue(activation.get('singleCustomerId').value);
      allocationForm.get('productName')?.enable();
      allocationForm.get('productName')?.setValidators([Validators.required,validateProduct(this.products)]);
      allocationForm.get('customer')?.disable();
    }else{
      allocationForm.get('zplnLvl')?.setValue(activation.get('singleZplnLvl').value);
      allocationForm.get('customer')?.enable();
      allocationForm.get('customer')?.setValidators([Validators.required,validateCustomer(this.customers)]);
      allocationForm.get('productName')?.disable();
    }   
    allocationForm.get('activationWbs')?.setValue(activation.get('activationWbs').value);
    allocationForm.get('allocationWeight')?.setValue('1');
    activation.get("allocationsForms")?.push(allocationForm);
    this.showAllocationTable=true;
    this.reCalculateAllocation(this.formatTotalAmount(activation.get('activationAmount').value),activation);
  }
  DeleteAllocation(arrayIndex: any,activation: any) {  
    let allocationForm = activation.get('allocationsForms');
    let deleted = allocationForm.at(arrayIndex);    
    if(deleted.get('newAllocation').value==true){
      allocationForm.removeAt(arrayIndex);
      this.reCalculateAllocation(activation.get('activationAmount').value,activation);      
      this._snackBar.open("New allocation deleted. ", 'Close', {panelClass:['green-snackbar'] });
    }else{
      const dialogRef=this.dialog.open(ViewActivationConfirmationDialog, {
        data: {deleteMessage:"Do you want to delete the allocation accociated with activation "+activation.get('activationWbs').value+"?",
      deleteAlert:"On clicking DELETE, the allocation will be staged for deletion . The delete process will be completed once you save the activations."}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          allocationForm.get(arrayIndex.toString()).get('deleteFlag').setValue(true);
          allocationForm.get(arrayIndex.toString()).get('allocationWeight').setValue('0');
          allocationForm.get(arrayIndex.toString()).get('calculatedAllocationAmount').setValue('0');
          this.reCalculateAllocation(activation.get('activationAmount').value,activation);   
         
          this._snackBar.open("Allocation is staged for delete. ", 'Close', { panelClass:['green-snackbar'] });
        }
      });      
    } 
    if(!allocationForm.length){
      this.disableAddButton = true;
    }
  }
  calculateAllocation(event: any, index: any,activation:any) {
    let inputValue = event.target.value;
    if(inputValue.length>0 && !isNaN(inputValue)){
      let multiplier = this.allocationLogic(inputValue,activation.get('allocationsForms'));
      let totActAmount = activation.get('activationAmount').value;
      let calculatedAmount = (multiplier * totActAmount).toFixed(2);
      activation.get('allocationsForms').get(index.toString()).get('calculatedAllocationAmount').setValue(calculatedAmount);
      this.reCalculateAllocation(totActAmount,activation);
    }else{
      activation.get('allocationsForms').get(index.toString()).controls['allocationWeight'].markAsTouched();
    }
  }
  reCalculateAllocation(currentTotActAmount,activation:any) {
    let allocationForm=activation.get('allocationsForms');
    let calculatedTotalAmount = 0;
    let calculatedTotalWeight = 0;
    currentTotActAmount = currentTotActAmount === 0 ? this.formatTotalAmount(activation.get('activationAmount').value) : this.formatTotalAmount(currentTotActAmount);
    let calculatedAllocations = allocationForm.value;
    for (let t = 0; t < calculatedAllocations.length; t++) {
      let weight = calculatedAllocations[t].allocationWeight;
      let recalculateMultiplier = this.allocationLogic(weight,allocationForm);
      let recalculateAmount = (recalculateMultiplier * parseFloat(currentTotActAmount)).toFixed(2);
      calculatedTotalAmount = calculatedTotalAmount + parseFloat(recalculateAmount);
      calculatedTotalWeight = calculatedTotalWeight + parseFloat(weight);
      recalculateAmount =String(parseFloat(recalculateAmount).toFixed(2));
      allocationForm.get(t.toString()).get('calculatedAllocationAmount').setValue(recalculateAmount);
      allocationForm.get(t.toString()).get('allocationWeight').setValue(weight);
    }
    activation.get('totalAllocationWeight').setValue(calculatedTotalWeight.toFixed(2));
    if(calculatedTotalWeight>0){
      activation.get('totalAllocationAmount').setValue( Math.round(calculatedTotalAmount));
    }else{
      activation.get('totalAllocationAmount').setValue( Math.round(0));
    }
   
  }
  
allocationLogic(input: any,allocationForms:any) {
  let sumOfWeight: number = 0;
  let allAllocations = allocationForms.value;
  for (let j = 0; j < allAllocations.length; j++) {
    sumOfWeight = sumOfWeight + Number.parseFloat(allAllocations[j].allocationWeight);
  }
  let allocRatio = (parseFloat(input) / sumOfWeight);
  return allocRatio;
}
onChangeAmount(event:any,activation:any){
  let currentTotActAmount=this.formatTotalAmount(event.target.value);
  activation.get('totalAllocationAmount').setValue(currentTotActAmount);
  this.reCalculateAllocation(currentTotActAmount,activation);
  this.setAddalloc(activation);
}
setOpened(itemIndex) {
  this.currentlyOpenedItemIndex = itemIndex;
  // Logic for displaying a combination of buttons if there are draft and approved activations under one project id
  const statuslist = this.Project?.get('activationsForms').value.map(key => key.activationStatus) ?? [];
  //If a project owner is reviewing an activation in draft status, they must only see "Save & Close" and "Close" buttons
    if(statuslist[itemIndex] === 'draft' && statuslist.includes('approved')){
      this.showSave = true;
      this.showClose = false;
    }
    //If a project owner is reviewing an activation in approved status, they must only see "Save & Close" and "Save & Submit" buttons
    else if(statuslist[itemIndex] === 'approved' && statuslist.includes('draft')){
      this.showClose = true;
      this.showSave = false;
    }
  }
fieldReadOnly(activation){

    if(activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value!='approved' || activation.get('deleteFlag').value==true ||
          (activation.get('activationStatus').value!='approved' && !this.enableUpdate) || activation.get('closedFiscalYear').value==true){
            return true;
          }
  
}
allocationBasisEditCheck(activation){
  if(!activation.get('newActivation').value){
    return true;
  }
}
ReadOnlyAllocation(activation,allocationform){
  if((activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value!='approved')||
  allocationform.get('deleteFlag').value==true || activation.get('deleteFlag').value==true || allocationform.get('newAllocation').value!=true || (activation.get('activationStatus').value!='approved' && !this.enableUpdate) || activation.get('closedFiscalYear').value==true){
    return true;
  }
}
//Added the below function to edit the Allocation Weight in Draft Status
ReadOnlyAllocationWeight(activation: any,allocationform: any){
  const activationStatus = activation.get('activationStatus').value;
  const activationDeleteFlag = activation.get('deleteFlag').value;
  const activationClosedFiscalYear = activation.get('closedFiscalYear').value;
  const allocationformDeleteFlag = allocationform.get('deleteFlag').value;

  if (
    (activationStatus !== ActivationStatus.draft && activationStatus !== ActivationStatus.approved)
    || allocationformDeleteFlag
    || activationDeleteFlag
    || (activationStatus !== ActivationStatus.approved && !this.enableUpdate)
    || activationClosedFiscalYear
  ) {
    return true;
  }
}
AttachmentListCalculation(){
  this.noAttaActArray = [];
  this.submitArray = [];
  this.underReviewArray = [];
  return new Promise(resolve=> {
    for(let i=0;i<this.Project?.get('activationsForms').value.length;i++){  
      if(this.Project?.get('activationsForms').value[i]?.attachmentListArray?.length>0 || 
      this.Project?.get('activationsForms').value[i].newAttaListArray?.length>0
      ){  
          if(this.Project?.get('activationsForms').value[i].activationStatus == 'draft'){
            this.submitArray.push(this.trackActivationArray[i].activationWbs);
          }else{
            this.underReviewArray.push(this.trackActivationArray[i].activationWbs);
          }
        
        }else{
          this.noAttaActArray.push(this.trackActivationArray[i].activationWbs);
        } 
        if((this.submitArray.length+this.noAttaActArray.length+this.underReviewArray.length) == this.trackActivationArray.length) {
          resolve(true);
        }
      }
    });

}
  async submitForApproval(){
  this.showSpinner=true;
  await this.AttachmentListCalculation();
  return new Promise(resolve => {
  if(this.submitArray?.length){
    const dialogRef = this.dialog.open(ViewActivationConfirmationDialog, {
      data: {activationWbs:this.submitArray}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let actObj = { 'activationWbs': this.submitArray };
        this.activationService.submitForApproval(actObj).subscribe(
          {
            //Adding responses from the submit for approval logic app by checking if the response have a valid activation WBS.
            next: (response: any) => {
              //if the response has valid activation WBS.
              if(response[0].activationWbs.length !=0 || response[0].activationWbs != 'NA' ){
                this.showSpinner = false;
                this._snackBar.open(`Activations ${response[0].activationWbs} submitted for approval.`, 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });   
                resolve(true);
                this.reloadToDoashboard();

              } 
              //if the response does not have a valid activation WBS.
              else{
                this.showSpinner = false;
                this._snackBar.open(`Failed to submit the activation ${response[0].activationWbs} for approval, Please try again`, 'Close', { 'duration': 3000, panelClass:['green-snackbar'] });   
                resolve(true);
              }
            },
            error: (err: any) => {
              this._snackBar.open("Failed to submit activations for approval. "+ err, "X", {panelClass: ['error-snackbar']  });
              this.showSpinner = false;
              resolve(true);
            }
          }
        );
      }
    });
  }else if(this.noAttaActArray?.length && ((this.submitArray.length+this.noAttaActArray.length+this.underReviewArray.length) == this.Project?.get('activationsForms').value.length)){
    this.showSpinner = false;
    const dialogRef = this.dialog.open(ViewActivationConfirmationDialog, {
            data: {noAttaActArray:this.noAttaActArray}
          });
          resolve(true);
  }
});

}
async submitConditionCheck(){
  this.showSpinner = true;
  let editedAttachments = false;
  let nonEdited = true;
  let activationFormControls=(this.Project.get('activationsForms')as FormArray).controls;
  for(let i=0;i<activationFormControls.length;i++) {
   if(this.checkAttachments(activationFormControls[i],i)==true){
      editedAttachments = true;
      break;
    }
  }
  for(let i=0;i<activationFormControls.length;i++) {
     if(this.checkEditedActivation(activationFormControls[i],i)==true){   
       nonEdited = false;
       break;
     }
   }
//conditional check for only attachments added and no changes in activation.
  if(editedAttachments && nonEdited){
    await this.uploadAttachments();
    //check whether upload is success or failure
    if(this.uploadStatus)
    {
      //Check if any fields are changed on an Approved project to submit a CR. If the only edits made are adding new attachments, then a CR will not be triggered and the user will be redirected to the dashboard.
      if(this.editedCR && !this.editedDraft){
        this.reloadToDoashboard();
      }
     //Check if any fields are changed on draft project. If the only edits made are adding new attachments to a Draft project, then the project will be submitted for approval after the attachments have been successfully uploaded.
      else if(this.editedDraft){
        await this.submitForApproval();
      }
      
    }
    
  }
  //if the activations are edited.
  else if(!nonEdited){
    await this.saveActivation();
    //check whether save is successful or not.
    if(this.saveStatus){
      //Check if any fields are changed on an Approved project to submit a CR, then reload to dashboard after successful save. This save action will trigger a CR.
      if(!this.editedDraft && this.editedCR){
        this.reloadToDoashboard();
      }
      //if only draft is edited
      else if(this.editedDraft && !this.editedCR){
        //if attachements are added.
        if(editedAttachments){
          //after successful upload, call logic app for submit.
          if(this.uploadStatus){
            await this.submitForApproval();
          }
        }
        //if no attachments are added.
        else if(!editedAttachments){
          await this.submitForApproval();
        }
       
      }
    }
    
  }else if(nonEdited && this.editedCR && !editedAttachments && !this.editedDraft){
    this._snackBar.open("No changes made that require a change request to be submitted. Please select the green “close” button.", "Close"); 
  }
  else if(nonEdited  && !editedAttachments){
      await this.submitForApproval();
    }
  }
//check for attachments during submit
checkAttachments(activation:any,index){
  if((activation?.get('newAttaListArray')?.value?.length>0) ){
    return true;
  }else{
    return false;
  }
}
//close button for approved activations
closeButton(){
  let count = 0;
  let activationFormControls=(this.Project.get('activationsForms')as FormArray).controls;
  for(let i=0;i<activationFormControls.length;i++) {
    if (this.checkEditedActivation(activationFormControls[i],i)==true){     
      count++;
      this._snackBar.open("The edit you made requires a change request to be submitted. Please select the green “save & submit button”." , "Close");
      break;
    }else{
      count++;
      if(count == activationFormControls.length){
        this.reloadToDoashboard();
      }
    }
  }
}
ObjectKeys(obj : any): string[]{
  return Object.keys(obj);
}
}
