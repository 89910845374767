import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { of, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from './environments/environment';
import { ProjectService } from './services/project.service';
import { DataCacheService } from './shared/services/dataCache.service';
import { HelperService } from './shared/services/helper.service';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'SIFT';
  private _router: Subscription;
  public env: String = environment.API_URL;
  public user: any;
  public ShowProgressBar: boolean = true;
  public token: any;
  public UserId: String = "UserInfoNotAvailable@conagrafoods.com";
  public UserName: String = "John Doe";
  public UserClaims: any[] = [];
  EnvFile: any = environment;
  public initials: any;
  public tokenExpiry: any;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router, private CacheService: DataCacheService, private helper: HelperService,
    private msalBroadcastService: MsalBroadcastService, private authService: MsalService,
    private projectService: ProjectService) {
    let token = this.CacheService.get('token');
    if (token) {
      this.setUser();
    }
    else {
      this.ShowProgressBar = false;
    }
  }

  setUser() {
    this.UserId = this.CacheService.get('zid');
    this.UserName = this.CacheService.get('name');
    this.token = this.CacheService.get('token');
    this.initials = this.CacheService.get('initials');
    this.ShowProgressBar = false;
    this.tokenExpiry = this.helper.epochConverter(this.CacheService.get('exp'));
  }
  ngOnInit() {
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$)).subscribe(() => {
      this.isIframe = window !== window.parent && !window.opener;
      this.setLoginDisplay();
      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    });
  }

  async setLoginDisplay() {
    let accounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = accounts.length > 0;
    let account = accounts[0];
    if (!this.loginDisplay) {
      this.authService.loginRedirect();
    }
    else if (account) {
      this.setUserInfo(account);
      let requestedAttachment = this.CacheService.get('requestedAttachment');
      if (requestedAttachment) {
        let fileName = requestedAttachment.substring(requestedAttachment.lastIndexOf('/') + 1);
        let activation = fileName.split('_')[0];
        this.projectService.getListOfDocuments(activation).subscribe(data => {
          let matchedDoc = data.filter(x => x.filename === fileName.replace(/%20/g, ' '));
          this.projectService.downloadAttachment(matchedDoc[0].attachmentId).subscribe(
            {
              next: (resp: any) => {
                this.projectService.downloadAttachmentInUI(resp);
                if (document.getElementsByClassName('loader').length && !document.getElementsByClassName('main-panel').length)
                  document.getElementsByClassName('loader')[0].remove();
                return of(false);
              }
            }
          )
          return of(false);
        })
        this.CacheService.delete('requestedAttachment');
      }
    }
  }

  setUserInfo(tokenRes: any) {
    this.CacheService.put("homeAccountId", tokenRes.homeAccountId);
    let UserId = tokenRes.username;
    let name = tokenRes.name.split("(")[0];
    UserId = UserId.split("@")[0];
    this.CacheService.put("name", name.split(",")[1] + name.split(",")[0]);
    this.CacheService.put("zid", UserId);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
