export class Activations{
    activationWbs:string;
    clarizenNumber:string;
    mmNumber: string;
    projectActivationDesc:string;
    numberOfSKUs:number;
    eventInitiative:string;
    fiscalYear:number;
    startDate:string;
    endDate:string;
    activationStatus:string;
    activationAmount:string;
    activationType:string;
    projectId:string;
    spendType:string;
    transferId:number;
    vendorId:string;
    deleteFlag:boolean;
    allocations:Array<Allocations>;
    changeRequestComment:string;//new attribute for CR comment
    totalPiecesOfArt:string;
}

export class Allocations{
    allocationWbs:string;
    allocationDesc:string;
    allocationWeight:string;
    allocationType:string;
    calculatedAllocationAmount:string;
    activationWbs:string;
    projectId:string;
    customerId:string;
    zplnLvl:string;
    deleteFlag:boolean;
}