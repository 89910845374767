import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivationService } from '../../services/activation.service';

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog.component.html'
})
export class ViewActivationConfirmationDialog {

  public ActivationsArray: any;
  public isDataPresent:boolean = false;
  public noAttaActivationsArray:any;
  NewSavedActivations: any;
  SavedActivations: any;
  deleteMessage:any;
  siftLocked :boolean;
  CRcomment = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private _snackBar:MatSnackBar,private dialogRef: MatDialogRef<ViewActivationConfirmationDialog>,private service: ActivationService) {
    this.ActivationsArray = data.activationWbs;
    this.SavedActivations=data.Savedactivations;
    this.NewSavedActivations=data.NewActivations;
    this.deleteMessage=data.deleteMessage;
    this.noAttaActivationsArray=data.noAttaActArray;
    if(data.enablePopUp == true){
      this.siftLocked = data;
    }else{
      this.siftLocked = false;
    }
  }
  close(){
  }
  onProceed(){
    //check whether CR comment is provided or not
    if(this.data.changeReqList?.length>0){
      if(!this.CRcomment?.length){
        this._snackBar.open("Please enter a comment prior to submitting the change request", "X");
      }
      else{
        let CrComment = {};
        CrComment['CRcomment']=this.CRcomment;
        CrComment['wbs']=this.data.changeReqList;
        this.service.setCRcomment(CrComment);
        this.dialogRef.close(true);
      }
    }else{
        this.dialogRef.close(true);
    }
  }
}
