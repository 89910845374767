import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
import {DataCacheService} from '../shared/services/dataCache.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouteGuard } from '../guard/routeGuard';
import { validateBudgetGrp, validateProjectOwner } from './project-form.validator';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnInit {
  ProjectFormGroup!: FormGroup;
  ProjectForm!: any;
  ProjectOwner: any;
  ProjectId: any;
  filterBudgetGroups!: any;
  BudgetGroups: any[] = [];
  id: any;
  selectedBgID:any;
  nextFlag = false;
  projectDetails : any = {};
  showSpinner = true;
  selectedBgDesc: any;
  descCount = 0;
  projectOwners : any[] = [];
  filteredOwners!: any;
  constructor(public _formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService, 
    private cacheService:DataCacheService, private router:Router, private _snackBar:MatSnackBar, public guard:RouteGuard) {
   }

  async ngOnInit() {  
   
    this.ProjectForm = this._formBuilder.group({
      ProjectDetails: this._formBuilder.group({
        projectId: new FormControl(""),
        projectTitle: new FormControl("", [Validators.required, Validators.maxLength(50)]),
        projectDescription: new FormControl("", [Validators.required,Validators.maxLength(500)]),
        projectOwnerZid: new FormControl(this.ProjectOwner),
        createdBy: new FormControl(this.ProjectOwner),
        modifiedBy: new FormControl(this.ProjectOwner),
        fcstVersion: new FormControl("", [Validators.required]),
        budgetGroupId: new FormControl("", [Validators.required]),
        formStatus:new FormControl("New")
      })
    })
    await this.getBudgetGroupsNoFilter();
    this.id = this.route.snapshot.paramMap.get("projectId");
      if (this.id != 0) {
        let pIdList = this.id.split(".");
        this.id=pIdList[0]+'.'+pIdList[1];//getting projectId from wbs
        this.nextFlag = true; 
        //service for getting project-details if id = 0
        this.projectService.getProjectBaseObject(this.id).subscribe(
          {
            next: async (data1) =>{
              this.projectDetails = data1;
              let projectArray = [];
              projectArray.push(this.projectDetails);
              await this.getListOfOwners(this.projectDetails.budgetGroupId);  
              this.mapResponseToProject(this.projectDetails);
              this.guard.setprojectForm(this.projectDetails);
            }, error: (error: any) => {
              this._snackBar.open("Failed to load project details. "+ error, "X", {panelClass: ['error-snackbar']  });
            }
        });   

      }else{       
        this.ProjectOwner = this.cacheService.get('name')+' - '+this.cacheService.get('zid');//adding name to project-owner
        this.ProjectForm?.get('ProjectDetails')?.get('projectOwnerZid')?.setValue(this.ProjectOwner);
        this.ProjectForm?.get('ProjectDetails')?.get('createdBy')?.setValue(this.ProjectOwner);
        this.ProjectForm?.get('ProjectDetails')?.get('modifiedBy')?.setValue(this.ProjectOwner);
        this.showSpinner = false;
        this.guard.setprojectForm(this.ProjectForm?.get('ProjectDetails').value);
      }
  this.ProjectForm.get('ProjectDetails').get('budgetGroupId').setValidators([validateBudgetGrp(this.BudgetGroups)]);

  }
  getListOfOwners(budgetGroupId:any){
    return new Promise(resolve => {
      this.projectService.getListofOwners(budgetGroupId).subscribe({
         next: (owners) =>{
          this.projectOwners = owners;
          for(let key of this.projectOwners){
            let nameSplit = key.projectOwnerName.split(' - ');
            key.projectOwnerName = nameSplit[1]+" - "+nameSplit[0];
          }
          this.projectOwners.sort((a,b)=> a.projectOwnerName.toLowerCase().localeCompare(b.projectOwnerName.toLowerCase()));
          resolve(true); 
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of project owners. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
       });
      });
  }
  getFilteredProjectOwner(value:any){
    let owner = value;
    this.filteredOwners = (owner ? this._FilterProjectOwners(owner) : this.projectOwners.slice());
 
  }
  private _FilterProjectOwners(value: any) {
    const filterValue = value.toLowerCase();
   return this.projectOwners.filter((owner:any) => owner.projectOwnerName.toLowerCase().includes(filterValue));
  }
  getFilteredBdgtGrp(value:any){
    let bg=value
    this.filterBudgetGroups =  (bg ? this._FilterBudgetGroups(bg) : this.BudgetGroups.slice());
  } 
  getBudgetGroupsNoFilter() {
   return new Promise(resolve=> {
      this.projectService.getBudgetGroup().subscribe({
        next: (budgetgrpobj) =>{
          this.BudgetGroups = budgetgrpobj;
          this.BudgetGroups.sort((a,b)=> a.budgetGroupDesc.toLowerCase().localeCompare(b.budgetGroupDesc.toLowerCase()));
          resolve(true);
        }, error: (error: any) => {
          this._snackBar.open("Failed to load Budget Groups. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      });
    });
  }
  private _FilterBudgetGroups(value: any) {
    const filterValue = value.toLowerCase();
    return this.BudgetGroups.filter(budgetgrp => budgetgrp.budgetGroupDesc.toLowerCase().includes(filterValue));
  }
  async mapResponseToProject(resp: any) {
    let obj = {
      budgetGroupId: resp.budgetGroupId,
      createdBy: resp.createdBy,
      modifiedBy: resp.modifiedBy,
      projectDescription: resp.projectDescription,
      projectId: resp.projectId,
      projectOwnerZid: resp.projectOwnerZid,
      projectTitle: resp.projectTitle
    }
    this.ProjectForm?.get('ProjectDetails')?.get('formStatus')?.setValue("Existing");
    this.ProjectForm?.get('ProjectDetails')?.patchValue(obj);    
    this.ProjectForm.get('ProjectDetails').get('projectOwnerZid').setValidators([validateProjectOwner(this.projectOwners)]);
    this.setBudgetGroupDescription(resp.budgetGroupId);
    this.showSpinner = false;
  }
  setProjectForm(){
    this.descCount = this.ProjectForm?.get('ProjectDetails')?.get('projectDescription').value?.length;
    if(this.id == 0){
      if(this.ProjectForm?.get('ProjectDetails')?.get('projectTitle')?.valid && this.ProjectForm?.get('ProjectDetails')?.get('projectDescription')?.valid &&
      this.ProjectForm?.get('ProjectDetails')?.get('budgetGroupId')?.valid){
        this.nextFlag = true;
      }else{
        this.nextFlag = false;
      }
    }else if(this.id != 0){
      if(this.ProjectForm?.get('ProjectDetails')?.get('projectTitle')?.valid && this.ProjectForm?.get('ProjectDetails')?.get('projectDescription')?.valid &&
      this.ProjectForm?.get('ProjectDetails')?.get('budgetGroupId')?.valid && this.ProjectForm?.get('ProjectDetails')?.get('projectOwnerZid')?.valid){
        this.nextFlag = true;
      }else{
        this.nextFlag = false;
      }
    }
    this.guard.setprojectForm(this.ProjectForm?.get('ProjectDetails').value);
  }
  async saveProject(){
   await this.changeBudgetGrpId();
      if(this.ProjectForm.value.ProjectDetails.projectId == ""){
         this.projectService.saveProjectBaseDetails(this.ProjectForm.value.ProjectDetails).subscribe({
          next: async (apidata) =>{
            this.projectDetails = apidata;
            //passing project-details to activations for new project
            let projectArray = [];
             projectArray.push(this.projectDetails);
             this.projectService.disableButton.next(true);
             this.projectService.projectId.next(this.projectDetails.projectId);
             this.projectService.projectDetails.next(projectArray);
             this.mapResponseToProject(this.projectDetails);
             this.guard.setprojectForm(this.projectDetails);
            this._snackBar.open("Project Details Saved Successfully" , "Close", {"duration": 1000});
          }, error: (error: any) => {
            this._snackBar.open("Failed to save Project Details. "+ error, "X", {panelClass: ['error-snackbar']  });
          }
        });
       }else{
         if(this.ProjectForm?.get('ProjectDetails')?.get('projectTitle')?.value != this.projectDetails.projectTitle || this.ProjectForm?.get('ProjectDetails')?.get('projectDescription')?.value != this.projectDetails.projectDescription ||
         this.ProjectForm?.get('ProjectDetails')?.get('projectOwnerZid')?.value != this.projectDetails.projectOwnerZid){
          this.projectService.editProjectBaseDetails(this.ProjectForm.value.ProjectDetails).subscribe({
            next: (apidata) =>{
              this.projectDetails = apidata
              this.mapResponseToProject(this.projectDetails);
              this._snackBar.open("Project Details Updated Successfully" , "Close",{"duration": 1000});
            }, error: (error: any) => {
              this._snackBar.open("Failed to update Project Details. "+ error, "X", {panelClass: ['error-snackbar']  });
            }
          });
         }

       }
      }
changeBudgetGrpId(){
   return new Promise(resolve => {
      if(isNaN(this.ProjectForm?.get('ProjectDetails')?.get('budgetGroupId')?.value)){
        //exact match search for budgetGroupDesc
        this.selectedBgID = this.BudgetGroups.find(budgetGroups => budgetGroups.budgetGroupDesc.toLowerCase() === this.ProjectForm?.get('ProjectDetails')?.get('budgetGroupId')?.value.toLowerCase());
        this.ProjectForm.value.ProjectDetails.budgetGroupId = this.selectedBgID.budgetGroupId;
      }
      resolve(true);
    });
   }
   setBudgetGroupDescription(budgetGroupId:any){
     //exact match search for budgetGroupId
    this.selectedBgDesc = this.BudgetGroups.find(budgetGroups => budgetGroups.budgetGroupId == budgetGroupId);   
    this.ProjectForm.get('ProjectDetails').get('budgetGroupId').setValue(this.selectedBgDesc.budgetGroupDesc);    
   }
   async updateProjectDetails(){
    await this.changeBudgetGrpId();
    if(this.ProjectForm?.get('ProjectDetails')?.get('projectTitle')?.value != this.projectDetails.projectTitle || this.ProjectForm?.get('ProjectDetails')?.get('projectDescription')?.value != this.projectDetails.projectDescription ||
    this.ProjectForm?.get('ProjectDetails')?.get('projectOwnerZid')?.value != this.projectDetails.projectOwnerZid){
     this.projectService.editProjectBaseDetails(this.ProjectForm.value.ProjectDetails).subscribe({
       next: (apidata) =>{
         this.projectDetails = apidata
         this.mapResponseToProject(this.projectDetails);
         this.router.navigate(['dashboard']);
         this._snackBar.open("Project Details Updated Successfully" , "Close",{"duration": 1000});
       }, error: (error: any) => {
         this._snackBar.open("Failed to update Project Details. "+ error, "X", {panelClass: ['error-snackbar']  });
       }
     });
    }
   }
}
