<h2 *ngIf="id==0">Please wait for sometime to get the project saved. If Project Details are not provided please go back to Project tab, 
  provide the neccessary details and click to 'SAVE AND CONTINUE' button to create Activations.</h2>
<div *ngIf="id!=0" class="row justify-content-start">  
  <div *ngIf="!showSpinner" class="col-sm-3">
    <button mat-raised-button  class="btn btn-success p-2" [disabled]="disableAddButton" (click)="NewActivation(true)">
      <mat-icon>add</mat-icon> Add activation
    </button> 
  </div >
</div>
<br/>
<form [formGroup]="Project" action="" method="" *ngIf="id!=0 && Project?.get('activationsForms')?.controls.length>0">
  <div class="activation-form" formArrayName="activationsForms">
    <div>
    <mat-accordion>
      <mat-expansion-panel
      *ngFor="let activation of Project?.get('activationsForms')?.controls;let activationindex=index;first as isFirst" 
      [formGroup]="Project.get('activationsForms').controls[activationindex]"
      (opened)="setOpened(activationindex);" [expanded]="currentlyOpenedItemIndex == activationindex">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <span *ngIf="activation.get('newActivation')?.value !=true">{{activation.get('activationWbs').value}} - {{(activation.get('activationStatus').value).toUpperCase()}}</span>
            <span *ngIf="activation.get('newActivation')?.value ==true">New Activation</span>            
          </mat-panel-title>
          <mat-description>
            <mat-icon *ngIf="activation.get('activationStatus')?.value=='draft'" style="font-size: large;color: white;" (click)="cancelActivation(activation,activationindex)">delete</mat-icon>
          </mat-description>
        </mat-expansion-panel-header> 
        <br/>  
        <div style="color:red" *ngIf="activation.get('deleteFlag').value==true">Activation is staged for  delete.</div>    
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('newActivation').value==false}">
        <mat-label>Activation Type</mat-label>
        <input matInput aria-label="Activation Type" [matAutocomplete]="pjctactivation"
        (keyup)="getfilteractivation(activation)"
        (focus)="getfilteractivation(activation)"
        [ngStyle]="{'color': activation.get('newActivation').value==false ? 'lightgray' : 'black'}"
        [readonly]="activation.get('newActivation').value!=true"
          formControlName="activationDesc" >
          <mat-icon matSuffix *ngIf="!activation.get('newActivation').value==false"
          style="font-size: large;color: #4caf50;" (click)="clearActivationType(activationindex)">refresh</mat-icon>
        <mat-autocomplete #pjctactivation="matAutocomplete"  (optionSelected)="selectActivationType($event.option.value,activation)">
          <mat-option *ngFor="let item of filterActivations" [value]="item.activationDesc">
            {{item.activationDesc}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="activation?.get('activationDesc')?.errors?.noMatch">Enter valid option</mat-error>
      </mat-form-field>
    </div> 
    <div class="col-sm-6">
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Transfer Type</mat-label>
        <input matInput aria-label="Transfer Type" [matAutocomplete]="transferType"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
        (keyup)="getfilterTransferType(activation)"
        (focus)="getfilterTransferType(activation)"
        [readonly]="fieldReadOnly(activation)"
        formControlName="transferType">
        <mat-autocomplete #transferType="matAutocomplete"  (optionSelected)="getTransferTypeChange($event.option.value,activation)">
          <mat-option *ngFor="let type of filterTransferTypes" [value]="type.transferType">
            {{type.transferType}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="activation?.get('transferType')?.errors?.noMatch">Enter valid option</mat-error>
      </mat-form-field> 
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Activation Amount</mat-label>
        <input matInput placeholder="1,000,000.00" formControlName="activationAmount" 
        [readonly]="fieldReadOnly(activation)"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
        (blur)="transform($event,activation)" (change)="onChangeAmount($event,activation)">
        <mat-error *ngIf="activation?.get('activationAmount')?.errors?.noMatch">Enter valid amount</mat-error>        
      </mat-form-field>
    </div>
    <div class="col-sm-6"> 
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value=='approved' || activation.get('deleteFlag').value==true || activation.get('activationStatus').value=='under_review'
    || activation.get('closedFiscalYear').value==true}">
        <mat-label>Fiscal Year</mat-label>
        <input matInput aria-label="fiscalYear" [matAutocomplete]="fYear"
          (keyup)="getFilteredFiscalyear(activation)"
          (focus)="getFilteredFiscalyear(activation)"
          [readonly]="fieldReadOnly(activation)"
          [ngStyle]="{'color': activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value=='approved' || activation.get('deleteFlag').value==true || activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
          formControlName="fiscalYear">
         <mat-autocomplete #fYear="matAutocomplete" (optionSelected)="SetMinMaxDates($event.option.value,activation)">
          <mat-option *ngFor="let fy of filterFiscalYear" [value]="fy.fiscalYear">
            {{fy.fiscalYear}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="activation?.get('fiscalYear')?.errors?.noMatch && activation.get('activationStatus').value!='approved'">Enter valid option</mat-error>
        <mat-error *ngIf="activation?.get('fiscalYear')?.errors?.noMatch && activation.get('activationStatus').value=='approved'">Fiscal year provided is closed</mat-error>
      </mat-form-field>
    </div>  
    <div class="col-sm-6">
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Clarizen Number</mat-label>
        <input matInput placeholder="Clarizen Number" formControlName="clarizenNumber"
        [readonly]="fieldReadOnly(activation) || !activation.get('isSpendTypeBD')?.value"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}">
          <mat-error *ngIf="activation?.get('clarizenNumber')?.errors?.noMatch">Enter valid number</mat-error>
        </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="mr-auto" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Start Date (MM/DD/YYYY)</mat-label>
        <input matInput [matDatepicker]="startdate" formControlName="startDate" placeholder="MM/DD/YYYY"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true? 'lightgray' : 'black'}"
        [min]="activation.get('minDate').value" [max]="activation.get('maxDate').value"
        [readonly]="fieldReadOnly(activation)" (keyup)="validateForm()">
        <mat-datepicker-toggle matSuffix [for]="startdate" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}"></mat-datepicker-toggle>
        <mat-datepicker #startdate></mat-datepicker>
        <mat-error *ngIf="activation?.get('startDate')?.errors?.noMatch">Enter valid option</mat-error>
      </mat-form-field>

    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Number Of SKUs</mat-label>
        <input matInput placeholder="Number of SKUs" formControlName="numberOfSKUs"
        [readonly]="fieldReadOnly(activation) || !activation.get('isSpendTypeBD')?.value"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}">
          <mat-error *ngIf="activation?.get('numberOfSKUs')?.errors?.noMatch">Enter valid number</mat-error>
        </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="ml-auto" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>End Date (MM/DD/YYYY)</mat-label>
        <input matInput [matDatepicker]="enddate" formControlName="endDate" placeholder="MM/DD/YYYY"
        [readonly]="fieldReadOnly(activation)" (keyup)="validateForm()"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
        [min]="activation.get('minDate').value" [max]="activation.get('maxDate').value" >
        <mat-datepicker-toggle matSuffix [for]="enddate" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}"></mat-datepicker-toggle>
        <mat-datepicker #enddate></mat-datepicker>
        <mat-error *ngIf="activation?.get('endDate')?.errors?.noMatch">Enter valid option</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-start">
  <div class="col-sm-6">
    <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
      <mat-label>Material Master Number</mat-label>
      <input matInput placeholder="Material Master Number" formControlName="mmNumber"
      [readonly]="fieldReadOnly(activation) || !activation.get('isSpendTypeBD')?.value"
      [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}">
        <mat-error *ngIf="activation?.get('mmNumber')?.errors?.noMatch">Enter valid number</mat-error>
      </mat-form-field>
  </div>
  <div class="col-sm-6">
    <mat-form-field class="form-fullwidth" appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value=='approved' || activation.get('deleteFlag').value==true || activation.get('activationStatus').value=='under_review'
  || activation.get('closedFiscalYear').value==true}">
      <mat-label>Vendor</mat-label>
      <input matInput aria-label="vendors" [matAutocomplete]="vendor" formControlName="vendorName"       
      (keyup)="getFilteredVendors(activation)"
      (focus)="getFilteredVendors(activation)" 
      [readonly]="fieldReadOnly(activation)"
      [ngStyle]="{'color': activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value=='approved' || activation.get('deleteFlag').value==true || activation.get('activationStatus').value=='under_review' || 
      activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}">
      <mat-autocomplete #vendor="matAutocomplete" (optionSelected)="selectVendor($event.option.value,activation)" >
        <mat-option *ngFor="let vendor of filterVendors" [value]="vendor.vendorName">
          {{vendor.vendorName}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="activation?.get('vendorName')?.errors?.noMatch">Enter valid option</mat-error>
    </mat-form-field>
  </div>
</div>
  <div class="row justify-content-start">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
        <mat-label>Total Pieces of Art</mat-label>
        <input matInput placeholder="Total Pieces of Art" formControlName="totalPiecesOfArt"
        [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
          [readonly]="fieldReadOnly(activation)">
          <mat-error *ngIf="activation?.get('totalPiecesOfArt')?.errors?.noMatch">Enter valid number</mat-error>
        </mat-form-field>
    </div>
</div>
<!--accordion for allocations START-->  
<div class="row justify-content-start pt-4">
  <div class="col-sm-2">
    <button type="button" mat-raised-button class="btn btn-success p-2" (click)="addAllocation(activation)"
    [disabled]="(activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value!='approved') ||(activation.get('disabledAddAlloc').value==true) ||
    (activation.get('deleteFlag').value==true) || activation.get('closedFiscalYear').value==true">
      Add Allocation
  </button>
  </div>
  <div class="col-sm-4">
    <p>Select Allocation Basis</p>
    <mat-radio-group formControlName="activationType" (change)="changeAllocationType($event,activation)"
      class="allocation-radio-group">
      <mat-radio-button [disabled]="!activation.get('newActivation').value==true" *ngFor="let type of typesOfAllocations" [value]="type">
      <span style="color: black;">{{type}}</span><span>&nbsp;&nbsp;</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="activation.get('activationType').value=='product'" class="col-sm-6">
    <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
      <mat-label>Customer</mat-label>
      <input matInput aria-label="customers" [matAutocomplete]="customer" formControlName="singleCustomer"
      [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
      (keyup)="getFilteredCustomers(activation)" (focusout)="setAddalloc(activation)"
      (focus)="getFilteredCustomers(activation)" [readonly]="allocationBasisEditCheck(activation)">
      <mat-autocomplete #customer="matAutocomplete" (optionSelected)="setCustomerAllocationbasis($event.option.value,activation)">
        <mat-option *ngFor="let customer of filterCustomers" [value]="customer.customerName">
          {{customer.customerName}}
        </mat-option>
      </mat-autocomplete>
    <mat-hint align="start"><strong>Select the single customer in which you want to spread multiple products
        against</strong> </mat-hint>
        <mat-error *ngIf="activation?.get('singleCustomer')?.errors?.noMatch">Enter valid option</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="activation.get('activationType').value=='customer'" class="col-sm-6">
    <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
      <mat-label>Product</mat-label>
      <input matInput aria-label="product" [matAutocomplete]="product" formControlName="singleProduct"
      [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
      (keyup)="getFilteredProducts(activation)" 
      (focus)="getFilteredProducts(activation)" (focusout)="setAddalloc(activation)"
      [readonly]="allocationBasisEditCheck(activation)">
      <mat-autocomplete #product="matAutocomplete" [panelWidth]="'auto'" (optionSelected)="setProductAllocationbasis($event.option.value,activation)">
        <mat-option *ngFor="let product of filterProducts" [value]="product.productDescription">
          {{product.productDescription}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint align="start"><strong>Select the single product in which you want to spread multiple customers
          against</strong> </mat-hint>
      <mat-error *ngIf="activation?.get('singleProduct')?.errors?.noMatch">Enter valid option</mat-error>
    </mat-form-field>
    </div>
</div>
<br/>
<div class="row">
  <div class="col-sm-12" *ngIf="activation?.get('allocationsForms').controls.length>0">
        <div class="table-responsive bg-white px-2">
            <table class="table">
                <thead>
                  <tr class="border-bottom">
                    <th class="text-left table-header">#</th>
                    <th class="text-left table-header" style="width: 40%" *ngIf="activation?.get('activationType').value=='product'">Product</th>
                    <th class="text-left table-header" style="width: 40%" *ngIf="activation?.get('activationType').value=='customer'">Customer</th>
                    <th class="text-left table-header">Allocation Weight</th>
                    <th class="text-left table-header">Allocation Amount</th>
                    <th class="text-left table-header">Actions</th>
                  </tr>
                </thead>
                <tbody formArrayName="allocationsForms">
                  <tr *ngFor="let allocationform of activation?.get('allocationsForms').controls; let i = index" [formGroupName]="i" >                    
                    
                    <th class="text-left">{{ i+1 }}</th>
                    
                      <td class="text-left" style="width: 40%" *ngIf="activation.get('activationType').value=='product'">
                        <div style="color:red" *ngIf="allocationform.get('deleteFlag').value==true">Allocation is staged for deletion.</div>
                        <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
                            <mat-label>Product</mat-label>
                            <input matInput aria-label="product" [matAutocomplete]="product" formControlName="productName"
                            (keyup)="getFilteredProductsAllocation(activation,i)"
                            (focus)="getFilteredProductsAllocation(activation,i)"
                            [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
                            [readonly]="ReadOnlyAllocation(activation,allocationform)">
                            <mat-autocomplete #product="matAutocomplete" [panelWidth]="'auto'" (optionSelected)="setProduct($event.option.value,allocationform,activation?.get('allocationsForms').controls,activation)">
                              <mat-option *ngFor="let product of filterProducts" [value]="product.productDescription">
                                {{product.productDescription}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="allocationform?.get('productName')?.errors?.noMatch">Enter valid option</mat-error>                           
                        </mat-form-field>
                        </td>                        
                        <td class="text-left" style="width: 40%" *ngIf="activation?.get('activationType').value=='customer'">
                          <div style="color:red" *ngIf="allocationform.get('deleteFlag').value==true">Allocation is staged for deletion.</div>                        
                          <mat-form-field appearance="outline" [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">                        
                              <mat-label>Customer</mat-label>
                              <input matInput aria-label="customers" [matAutocomplete]="customer" formControlName="customer"
                              (keyup)="getFilteredCustomersAllocation(activation,i)"
                              (focus)="getFilteredCustomersAllocation(activation,i)"
                              [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
                              [readonly]="ReadOnlyAllocation(activation,allocationform)">
                              <mat-autocomplete #customer="matAutocomplete" (optionSelected)="setCustomer($event.option.value,allocationform,activation?.get('allocationsForms').controls,activation)">
                                <mat-option *ngFor="let customer of filterCustomers" [value]="customer.customerName">
                                  {{customer.customerName}}
                                </mat-option>
                              </mat-autocomplete>
                              <mat-error *ngIf="allocationform?.get('customer')?.errors?.noMatch">Enter valid option</mat-error>       
                          </mat-form-field>                     
                        </td>
                      <td class="text-left">
                        <mat-form-field [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
                          <input matInput placeholder="Allocation Weight*" formControlName="allocationWeight" (blur)="calculateAllocation($event,i,activation)"
                          [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}"
                          [readonly]="ReadOnlyAllocationWeight(activation,allocationform)">
                          <mat-error *ngIf="allocationform?.get('allocationWeight')?.errors as errors">
                            <div *ngFor = "let errorKey of ObjectKeys(errors)">{{errors[errorKey]}}</div>
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td class="text-left">
                        <mat-form-field [ngClass]="{disabled:activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true}">
                          <input matInput [ngStyle]="{'color': activation.get('activationStatus').value=='under_review' || activation.get('closedFiscalYear').value==true ? 'lightgray' : 'black'}" placeholder="Allocation" formControlName="calculatedAllocationAmount" readonly>
                        </mat-form-field>
                      </td>
                      <td class="text-left" matTooltip="Cannot Delete Allocation ,since activation is Approved.You can update allocation weight."
                      [matTooltipDisabled]="!(activation?.get('activationStatus').value=='approved' && allocationform.get('newAllocation').value==false)">
                        <button type="button" mat-icon-button color="warn" (click)="DeleteAllocation(i,activation)"
                        [disabled]="activation?.get('activationStatus').value=='approved' && allocationform.get('newAllocation').value==false"
                         *ngIf="(activation?.get('activationStatus').value=='draft' ||activation?.get('activationStatus').value=='approved') && allocationform.get('deleteFlag').value==false"><mat-icon matTooltip="Delete Allocation">cancel</mat-icon></button>
                      </td>
                    
                  </tr>
                  <tr style="font-weight: 800;font-size: 1.0625rem;">
                    <td></td>
                    <td style="text-align: center;">
                      Allocation Totals
                    </td>
                    <td>
                      {{activation.get('totalAllocationWeight').value | number:'1.2-2'}}
                    </td>
                    <td>
                      {{activation.get('totalAllocationAmount').value | currency:' '}}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

        </div>
      </div>
    </div>   
<!--accordion for allocations END-->
<div class="row justify-content-start">
  <div class="col-sm-3">
    <button type="button" mat-raised-button (click)="upload$activationindex.click()" class="btn btn-success p-2"  
    [disabled]="(activation.get('activationStatus').value!='draft' && activation.get('activationStatus').value!='approved') ||
    (activation.get('deleteFlag').value==true)">
      Add Attachment</button>
        <input [accept] = "['.doc','.docx','.xls','.xlsx','.pdf','.msg','.jpg','.png']" type="file" #upload$activationindex style="display:none;" (change)="selectFiles($event,activation)" />
  </div>
</div>

<div *ngFor="let item of activation.get('newAttaListArray').value;let i = index" class="listSpacing row mt-2" [ngClass]="grid ? 'col-6' : 'col-7'">
  <div class="my-2" ><mat-icon style="font-size:medium;">attach_file</mat-icon>{{item.name}}<span style="float:right"><span>&nbsp;</span>
    <button mat-icon-button>  
      <mat-icon title="Remove Attachment" style="font-size:medium ;vertical-align: super" (click) = "closeAttachment(i,activation)">close</mat-icon>  
    </button></span></div>
</div>
<br/>

<div class="row">
  <div class="col-sm-12" *ngIf="activation?.get('attachmentListArray')?.value && activation.get('attachmentListArray').value.length>0">
      <div class="table-responsive bg-white px-2">
        <table class="table">
          <thead>
            <tr >
              <th class="text-left table-header" style="width: 40%">Files</th>
              <th class="text-left table-header">Dates Added</th>
              <th class="text-left table-header">Download</th>
              <th class="text-left table-header">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor = "let Attachment of activation.get('attachmentListArray').value">
              <td class="text-left p-1" style="width: 40%"><a href="{{'#/attachments/'+Attachment.filename}}" target="_self">{{Attachment.trimmedFileName}}</a></td>
              <td class="text-left p-1">{{getCreatedDate(Attachment.createdOn)}}</td>
              <td class="text-left p-1"><mat-icon (click)="downloadAttachment(Attachment)">file_download</mat-icon></td>
              <!-- disabling delete button if status is approved -->
              <td class="text-left p-1"><button mat-icon-button (click)="deleteAttachment(Attachment,activation)" 
                [disabled]="activation.get('activationStatus').value !='draft'"><mat-icon>remove_circle</mat-icon></button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</mat-expansion-panel>
</mat-accordion>

</div>
</div>
</form>
<div [ngStyle]="{'display': showSpinner ? 'none' : 'block'}">
  <div class="card-footer">
    <div class="mr-auto d-flex justify-content-start" >
      <button   class="btn btn btn-previous btn-fill btn-default btn-wd previous" name="previous" value="Previous" >Previous</button>

  </div>
  <div class="ml-4 d-flex justify-content-end" >
    <button *ngIf="showClose"  class="btn  btn-fill btn-success btn-wd"  (click)='closeButton()'>Close</button>

</div> 
    <div class="ml-4 d-flex justify-content-end" >
        <button  *ngIf="showSave" class="btn  btn-fill btn-success btn-wd"  (click)='saveConditionCheck()'>Save & Close</button>
  
    </div>
    <div class="ml-4 d-flex justify-content-end" >
  
        <button *ngIf="showSubmit"  class="btn  btn-fill btn-success btn-wd" (click)='submitCondition()'>Save & Submit</button>  
    </div> 
  
    <div class="clearfix"></div>
  </div>
</div>
<div *ngIf="showSpinner" class="spinner_overlay"></div>
<mat-spinner *ngIf="showSpinner" style="padding-bottom: 40%;margin: auto" strokeWidth="5"></mat-spinner>
