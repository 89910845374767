<form [formGroup]="ProjectForm" action="" method="">
    <div class="row justify-content-start" formGroupName="ProjectDetails">
        <div class="col-6">
            <mat-form-field appearance="outline" class="not-text" *ngIf="!showSpinner">
                <mat-label>Project Title</mat-label>
                <input matInput placeholder="Specify title for your project" formControlName="projectTitle" required (input)="setProjectForm()">
                <mat-error error-style *ngIf="!ProjectForm.get('ProjectDetails').get('projectTitle').valid">Enter a valid value(maximum 50 characters)</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="not-text" *ngIf="this.ProjectForm?.get('ProjectDetails')?.get('formStatus').value=='New' && id == 0">
                <mat-label>Budget Group</mat-label>
                <input matInput aria-label="Budget Group" [matAutocomplete]="budgetgroup"
                (keyup)="getFilteredBdgtGrp($event.target.value)"   (keyup)="setProjectForm()"  (focus)="setProjectForm()" 
                (focus)="getFilteredBdgtGrp($event.target.value)"
                    formControlName="budgetGroupId">
                <mat-autocomplete #budgetgroup="matAutocomplete" (optionSelected)="setProjectForm()">
                    <mat-option *ngFor="let budgetgrp of filterBudgetGroups" [value]="budgetgrp.budgetGroupDesc">
                        {{budgetgrp.budgetGroupDesc}}
                    </mat-option>
                    </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline"class="not-text disabled" *ngIf="this.ProjectForm?.get('ProjectDetails')?.get('formStatus').value=='Existing'">
                <mat-label>Budget Group</mat-label>
                <input matInput aria-label="Budget Group" [matAutocomplete]="budgetgroupEdit"
                [readonly]="true" formControlName="budgetGroupId" style="color:lightgray;">
                <mat-autocomplete #budgetgroupEdit="matAutocomplete" >
                    <mat-option *ngFor="let budgetgrp of BudgetGroups" [value]="budgetgrp.budgetGroupDesc">
                        {{budgetgrp.budgetGroupDesc}}
                    </mat-option>
                    </mat-autocomplete>                
            </mat-form-field>
            <mat-form-field appearance="outline"class="not-text" *ngIf="!showSpinner">
                <mat-label>Project Owner</mat-label>
                <input matInput aria-label="Project Owner" (keyup)="getFilteredProjectOwner($event.target.value)" 
                (focus)="getFilteredProjectOwner($event.target.value)" (keyup)="setProjectForm()"  (focus)="setProjectForm()" 
                placeholder="Specify Owner for your project" formControlName="projectOwnerZid" 
                [matAutocomplete]="owner" [readonly]="id==0">
                <mat-autocomplete #owner="matAutocomplete" (optionSelected)="setProjectForm()">
                    <mat-option *ngFor="let owner of filteredOwners" [value]="owner.projectOwnerName">
                        {{owner.projectOwnerName}}
                    </mat-option>
                    </mat-autocomplete>  
                    <mat-error error-style *ngIf="!ProjectForm.get('ProjectDetails').get('projectOwnerName')?.errors?.noMatch">Enter a valid option.</mat-error>
                </mat-form-field>
            </div>
        <div class="col-6">
            <mat-form-field appearance="outline" *ngIf="!showSpinner">
                <mat-label>Project Description</mat-label>
                <textarea type="text" class="text" matInput class="mb-3"
                formControlName="projectDescription" 
                required (input)="setProjectForm()" ></textarea>
                <mat-hind class="hind-error" *ngIf="ProjectForm.get('ProjectDetails').get('projectDescription').valid">{{descCount}}/500</mat-hind>
                <mat-hind class="hind-error" *ngIf="!ProjectForm.get('ProjectDetails').get('projectDescription').valid && descCount!=0" style="color:red">{{descCount}}/500</mat-hind>
            </mat-form-field>
        </div>
            <div class="col-sm-6">
            <div *ngIf="this.ProjectForm?.get('ProjectDetails')?.get('projectId').value!=''">
                <mat-form-field appearance="outline" class="not-text" *ngIf="this.ProjectForm?.get('ProjectDetails')?.get('projectId').value!=''">
                    <mat-label>Project ID</mat-label>
                    <input matInput placeholder="Project ID" formControlName="projectId" readonly>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" d-flex justify-content-end pr-3 pt-2"> 
        <div class="row">
            <button *ngIf="!showSpinner && id!=0"  mat-raised-button  [ngStyle]="{'display': showSpinner ? 'none' : 'block'}" class="btn btn-outline-success bg-white ml-4" [disabled]="!nextFlag" (click)="updateProjectDetails()">
                SAVE AND CLOSE
                   </button>
            <button class="save-button" [ngStyle]="{'display': showSpinner ? 'none' : 'block'}" class="btn btn-next btn-fill btn-success btn-wd ml-4" name="next" [disabled]="!nextFlag" 
            value="Next" (click)="saveProject()">SAVE AND CONTINUE</button>
        </div>
    </div>
</form>
<div *ngIf="showSpinner" class="spinner_overlay"></div>
<mat-spinner *ngIf="showSpinner" strokeWidth="5"></mat-spinner>
