import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './header/dashboard/dashboard.component';
import { DashboardResolverService } from './shared/services/resolvers/dashboard-resolver.service';
import { AttachmentsComponent } from './attachments/attachments.component';
import { FileGuard } from './attachments/file.guard';
import { ReadonlyReportComponent } from './readonly-report/readonly-report.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { RouteGuard } from './guard/routeGuard';
import { MyApprovalsComponent } from './my-approvals/my-approvals.component';
import { PathGuard } from './my-approvals/pathGuard.guard';


const isIframe = window !== window.parent && !window.opener;

const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        pathMatch: 'full',
        component: DashboardComponent
    },
    {
        path: 'code',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'myapprovals',
        pathMatch: 'full',
        component: MyApprovalsComponent,
        canActivate:[PathGuard]
    },
    {
        path: 'project-details/:projectId',
        pathMatch: 'full',
        component: ProjectDetailsComponent,
        canActivate:[RouteGuard],
        canDeactivate:[RouteGuard]
    },
    {
        path: 'project-details-CR/:projectId',
        pathMatch: 'full',
        component: ProjectDetailsComponent,

    },
    {
        path: 'attachments/:fileName',
        pathMatch: 'full',
        component: AttachmentsComponent,
        canActivate: [FileGuard]
    },
    {
        path : 'project-report/:projectId',
        pathMatch: 'full',
        component: ReadonlyReportComponent
    },
    {
        path : 'myapprovals/project-report/:projectId',
        pathMatch : 'full',
        component : ReadonlyReportComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: 'reload', useHash: true, initialNavigation: !isIframe ? 'enabled' : 'disabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
