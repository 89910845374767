export function renderInput(data, type, row) : string{
    return '<input type="file" #upload' + row + ' multiple accept = ".doc,.docx,.xls,.xlsx,.pdf,.msg,.jpg,.png">';
}
export function renderAttachmentApproval(data, type, row) : string{
    return '<span style="transform: rotate(90deg)" class="material-symbols-outlined popoverButtonApproval" type="button" style="padding-right: 20%; cursor: pointer" >attachment</span>';
}
export function renderAttachmentCR(data, type, row) : string{
    return '<span style="transform: rotate(90deg)" class="material-symbols-outlined popoverButtonCR" type="button" style="padding-right: 20%; cursor: pointer" >attachment</span>';
}
export function renderInputField(data, type, row) : string{
    return '<input name="comment" type="text"  [value] = 0 >';
}
export function renderExpand(data, type, row) : string{
    return '<span class="material-symbols-outlined" style="padding-right: 20%; cursor: pointer">expand_more</span>';
}
export function renderWbs(data, type, row) : string{
    return (
        '<a href="#/myapprovals/project-report/' +
        data +
        '">' +
        data +
        '</a>'
      );
}

 export function getErrorMessage(error: unknown) {
    return error instanceof Error
      ? error.message
      : 'Something went wrong.';
  }