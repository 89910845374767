import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { ViewChangeRequestDialog } from '../new-project/template/change/change.review';
import { HelperService } from '../shared/services/helper.service';
import { ViewConfirmationDialog } from '../new-project/template/modal/dialog.component';
import { ActivationService } from '../services/activation.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  disableButton : boolean=false;
  type: FormGroup;
  id:any;
  formTitle:any;
  CRs:any[]=[];
  activationSelected : any;
  activationStatus:any;
  projectForm : any;
  activationFormData: any;
  activationSelectedArray: any;
  enableSubmit: boolean=false;
  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, 
    private projectService:ProjectService,private helper:HelperService) { }

  ngOnInit(): void {

    this.helper.broadcastActivationWbsStatus.subscribe({
      next:(activationWbsStatus:any)=>{
            this.activationStatus = activationWbsStatus;
          }
        }
      )
    this.helper.broadcastactivationWbs.subscribe({
      next:(activationWbs:any)=>{
            this.activationSelected = activationWbs;
          }
        }
      )
      
    this.id = this.activatedRoute.snapshot.paramMap.get("projectId");
    if(this.id==0){
      this.formTitle = 'Create New Project';
    }
    else {
      this.formTitle = 'Project Details'
    }
    this.projectService.projectIdObservable.subscribe((data:any)=>{
      this.id=data;
      if(this.id!=0){
        this.formTitle = 'Project Details';         
      }
      });
      this.projectService.disableButtonObservable.subscribe((elem:any)=>{
        this.disableButton=elem;
      });
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    this.type = this.formBuilder.group({});

    // Wizard Initialization
    (<any>$('.card-wizard')).bootstrapWizard({
      'tabClass': 'nav nav-pills',
      'nextSelector': '.btn-next',
      'previousSelector': '.btn-previous',

      onNext: function (tab, navigation, index) {
        var $valid = true;
        // var $valid = $('.card-wizard form').valid();
        if (!$valid) {
          return false;
        }
      },

      onInit: function (tab: any, navigation: any, index: any) {

        // check number of tabs and fill the entire row
        let $total = navigation.find('li').length;
        let $wizard = navigation.closest('.card-wizard');

        let $first_li = navigation.find('li:first-child a').html();
        let $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
        $('.card-wizard .wizard-navigation').append($moving_div);

        $total = $wizard.find('.nav li').length;
        let $li_width = 100 / $total;

        let total_steps = $wizard.find('.nav li').length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        let mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find('.nav li').css('width', $li_width + '%');

        let step_width = move_distance;
        move_distance = move_distance * index_temp;

        let $current = index + 1;

        if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
          move_distance -= 8;
        } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
          move_distance += 8;
        }

        if (mobile_device) {
          let x: any = index / 2;
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }

        $wizard.find('.moving-tab').css('width', step_width);
        $('.moving-tab').css({
          'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
          'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

        });
        $('.moving-tab').css('transition', 'transform 0s');
      },

      onTabClick: function (tab: any, navigation: any, index: any) {

        // const $valid = $('.card-wizard form').valid();
        const $valid = true;

        if (!$valid) {
          return false;
        } else {
          return true;
        }
      },

      onTabShow: function (tab: any, navigation: any, index: any) {
        let $total = navigation.find('li').length;
        let $current = index + 1;
        elemMainPanel.scrollTop = 0;
        const $wizard = navigation.closest('.card-wizard');

        // If it's the last tab then hide the last button and show the finish instead
        if ($current >= $total) {
          $($wizard).find('.btn-next').hide();
          $($wizard).find('.btn-finish').show();
          //$($wizard).find('.btn-save').show();
        } else {
          $($wizard).find('.btn-next').show();
          $($wizard).find('.btn-finish').hide();
         //$($wizard).find('.btn-save').hide();
        }

        const button_text = navigation.find('li:nth-child(' + $current + ') a').html();

        setTimeout(function () {
          $('.moving-tab').text(button_text);
        }, 150);

        const checkbox = $('.footer-checkbox');

        if (index !== 0) {
          $(checkbox).css({
            'opacity': '0',
            'visibility': 'hidden',
            'position': 'absolute'
          });
        } else {
          $(checkbox).css({
            'opacity': '1',
            'visibility': 'visible'
          });
        }
        $total = $wizard.find('.nav li').length;
        let $li_width = 100 / $total;

        let total_steps = $wizard.find('.nav li').length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        let mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find('.nav li').css('width', $li_width + '%');

        let step_width = move_distance;
        move_distance = move_distance * index_temp;

        $current = index + 1;

        if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
          move_distance -= 8;
        } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
          move_distance += 8;
        }

        if (mobile_device) {
          let x: any = index / 2;
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }

        $wizard.find('.moving-tab').css('width', step_width);
        $('.moving-tab').css({
          'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
          'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

        });
      }
    });
  }

  ngAfterViewInit() {

    $(window).resize(() => {
      $('.card-wizard').each(function () {
        setTimeout(() => {
          const $wizard = $(this);
          const index = (<any>$wizard).bootstrapWizard('currentIndex');
          let $total = $wizard.find('.nav li').length;
          let $li_width = 100 / $total;

          let total_steps = $wizard.find('.nav li').length;
          let move_distance = $wizard.width() / total_steps;
          let index_temp = index;
          let vertical_level = 0;

          let mobile_device = $(document).width() < 600 && $total > 3;
          if (mobile_device) {
            move_distance = $wizard.width() / 2;
            index_temp = index % 2;
            $li_width = 50;
          }

          $wizard.find('.nav li').css('width', $li_width + '%');

          let step_width = move_distance;
          move_distance = move_distance * index_temp;

          let $current = index + 1;

          if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
            move_distance -= 8;
          } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
            move_distance += 8;
          }

          if (mobile_device) {
            let x: any = index / 2;
            vertical_level = parseInt(x);
            vertical_level = vertical_level * 38;
          }

          $wizard.find('.moving-tab').css('width', step_width);
          $('.moving-tab').css({
            'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
            'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
          });

          $('.moving-tab').css({
            'transition': 'transform 0s'
          });
        }, 500)

      });
    });

  }

  
 
  
}
