import { __core_private_testing_placeholder__ } from "@angular/core/testing";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validateActivationType(activationTypes:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value?.toUpperCase();        
        if (!(activationTypes.find(s=>s.activationDesc.toUpperCase()==selection))) {//validation for case sensitivity
            return { noMatch: true };
        }else{
        return null;
        }
    }; 

}
export function validateTransferType(transferTypes:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value?.toUpperCase();           
        if (!(transferTypes.find(s=>s.transferType.toUpperCase()==selection))) {//validation for case sensitivity
            return { noMatch: true };
        }else{
        return null;
        }
    }; 
    
}
export function validatefiscalYear(fiscalYear:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value;           
        if (!(fiscalYear.find(s=>s.fiscalYear==selection))) {
            return { noMatch: true };
        }else{
        return null;
        }
    }; 
    
}
export function validateVendor(vendors:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value?.toUpperCase();       
        if (!(vendors.find(s=>s.vendorName.toUpperCase()==selection))) {//validation for case sensitivity
            return { noMatch: true };
        }else{
        return null;
        }
    }; 
    
}
export function validateCustomer(customers:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value?.toUpperCase();           
        if (!(customers.find(s=>s.customerName.toUpperCase()==selection))) {//validation for case sensitivity
            return { noMatch: true };
        }else{
        return null;
        }
    }; 
    
}
export function validateProduct(products:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value?.toUpperCase();           
        if (!(products.find(s=>s.productDescription.toUpperCase()==selection))) {//validation for case sensitivity
            return { noMatch: true };
        }else{
        return null;
        }
    };    
}
export function validateAmount(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value; 
        let isValid = /^-?[0-9,]*[.]?[0-9,]*$/.test(selection);          
        if (!isValid) {
            return { noMatch: true };
        }else{
        return null;
        }
    };    
}
export function validateNumberString(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value; 
        let isValid = /^[0-9]*[.]?[0-9]*$/.test(selection);          
        if (!isValid) {
            return { noMatch: true };
        }else{
        return null;
        }
    };    
}
export function validateDecimalPlaces(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value;
        const errors: ValidationErrors = {}; 
        const isValid = /^[0-9]*[.]?[0-9]*$/.test(selection);
        const isAccurate =  /^(0|[1-9]\d*)(\.\d{1,2})?$|^0?\.\d{1,2}$/.test(selection);  
        if(!isValid || !selection?.length) {
            errors['numberField'] = 'Enter valid allocation weight.';
        }else if(isValid && !isAccurate){
            errors['notAccurate'] = 'Please round allocation weight to 2 decimal places.'
        }
        return Object.keys(errors).length ? errors : null;
    };    
}