export const environment = {
    production: true,
    API_URL : 'https://sift-backend-dev.conagra.com',
    ORCH_API_URL : 'https://siftorchapi-dev.conagra.net',
    AUTH_TOKEN_URL : 'https://sift-dev.conagra.com',
    MS_CLIENT_ID: '6da69d00-89cb-4972-931b-b29198cf5b74',
    MS_AUTHORITY: 'https://login.microsoftonline.com/6b0d6faa-4dc1-485c-ac89-6a4aae225fbd',
    REDIRECT_URI: 'https://sift-dev.conagra.com',  
    TENANT_ID:'6b0d6faa-4dc1-485c-ac89-6a4aae225fbd',
    LOGOUT_URL: 'https://sift-dev.conagra.com/.auth/logout?post_logout_redirect_uri=https://sift-dev.conagra.com/',
    appInsights: {
      instrumentationKey: '82dea291-0272-4b07-b6a2-1679b8a73779'
    },
    APPROVAL_URL: 'https://prod-00.northcentralus.logic.azure.com:443/workflows/864909099cc1442eb001b1cdd4d2a9d2/triggers/manual/paths',
    ATTACHMENT_URL:'https://prod-09.northcentralus.logic.azure.com:443/workflows/702c9a6651994a1593126c707914c1d2/triggers/manual/paths',
    ACTIVATION_URL:'https://prod-09.northcentralus.logic.azure.com:443/workflows/efbb6e415f94449886d5e6909b271c4f/triggers/manual/paths',
    APPROVE_REJECT_URL:'https://prod-29.northcentralus.logic.azure.com:443/workflows/f781f52d41174f12875e22151729c34d/triggers/manual/paths'
  };
