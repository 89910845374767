import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataCacheService } from '../shared/services/dataCache.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TableData } from '../header/dashboard/dashboard.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ActivationService } from '../services/activation.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { getErrorMessage, renderAttachmentApproval, renderAttachmentCR, renderExpand, renderInput, renderInputField, renderWbs } from '../services/MyApprovalsHelper';

declare var $: any;

@Component({
  selector: 'app-my-approvals',
  templateUrl: './my-approvals.component.html',
  styleUrls: ['./my-approvals.component.css'],
})
export class MyApprovalsComponent implements OnInit, OnDestroy {
  dtOptionsApproval: any;
  dtOptionsCR: any;
  projectActivations: any = [];
  readRecordCount = 0;
  allDataLoaded: boolean = false;
  noDataFound: boolean = false;
  CRList: any[] = [];
  ApprovalDataSource: any[] = [];
  CRDataSource: any[] = [];
  public dataTableApproval: TableData;
  public dataTableCR: TableData;
  datatablesApproval: any;
  datatablesCR: any;
  newActCount = 0;
  crCount = 0;
  disableButton = false;
  showWarningApproval = false;
  showWarningCR = false;
  selectedFiles = [];
  uploadAttaArray = [];
  @ViewChild('datatablesApproval', { static: true }) table1;
  private readonly _destroying$ = new Subject<void>();
  @ViewChild('datatablesCR', { static: true }) table2;

  constructor(
    private projectservice: ProjectService,
    private _snackBar: MatSnackBar,
    private cacheService: DataCacheService,
    private router: Router,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private activationService: ActivationService
  ) {
    this.getListOfApprovals();
    //Get Activations Pending My Approval
  }
  getListOfApprovals() : void{
    const userZid = this.cacheService.get('zid');
    const status = 'pending';
    this.projectservice
      .getActivationsPendingApproval(userZid, status)
      .subscribe({
        next: (resp: any) => {
          this.cacheService.delete('requestedURL'); //deleting url from cache after redirecting
          if (resp.length === 0) {
            this.noDataFound = this.allDataLoaded = true;
          }

          for (let i = 0; i < resp.length; i++) {
            //getting details of activations
            this.getApprovalDetails(resp[i], resp);
          }
        },
        error: (error: unknown) => {
          this._snackBar.open(
            'Failed to load list of activations pending for approval. ' + getErrorMessage(error),
            'X',
            { panelClass: ['error-snackbar'] }
          );
        },
      });
  }
  getApprovalDetails(allocation, resp) {
    this.projectservice.getAllocations(allocation.activationWBS).subscribe({
      next: async (activationObj: any) => {
        this.readRecordCount++;
        let [recordObject] = activationObj;
        activationObj[0].attachments = await this.getAttachments(
          activationObj[0]
        );
        recordObject['submittedOn'] = allocation.submittedOn;
        recordObject['approvalType'] = allocation.approvalType;
        if (activationObj[0].approvalType == 'newActivation') {
          this.projectActivations.push(activationObj[0]);
          this.newActCount++;
          //load table after all datta is fetched
          if (resp.length == this.newActCount + this.crCount) {
            this.buildProjectGridObject(this.projectActivations);
            this.buildProjectGridObject(this.CRList);
            this.allDataLoaded = true;
          }
        } else if (activationObj[0].approvalType == 'changeRequest') {
          //change requests details

          await this.projectservice
            .listCRByActivation(activationObj[0].activationWbs.activationWbs)
            .subscribe({
              next: async (CR) => {
                let lastIndex = CR.length - 1;
                if (CR.length > 1) {
                  CR = CR.sort((n1, n2) => n1.changeId < n2.changeId);
                }

                let lastCR = CR[lastIndex];
                activationObj[0].crComment = lastCR.changeRequestComment;

                await this.projectservice
                  .getChangeFields(lastCR.changeId)
                  .subscribe({
                    next: (change) => {
                      activationObj[0].LastChange = change;
                      this.CRList.push(activationObj[0]);
                      this.crCount++;
                      //load table after all datta is fetched
                      if (resp.length == this.newActCount + this.crCount) {
                        this.buildProjectGridObject(this.projectActivations);
                        this.buildProjectGridObject(this.CRList);
                        this.allDataLoaded = true;
                      }
                    },
                    error: (error: unknown) => {
                      this._snackBar.open(
                        'Failed to load field changes for change request. ' +
                        getErrorMessage(error),
                        'X',
                        { panelClass: ['error-snackbar'] }
                      );
                    },
                  });
              },
              error: (error: unknown) => {
                this._snackBar.open(
                  'Failed to load list of change requests. ' + getErrorMessage(error),
                  'X',
                  { panelClass: ['error-snackbar'] }
                );
              },
            });
        }
      },
      error: (error: unknown) => {
        this._snackBar.open(
          'Failed to load activation details. ' + getErrorMessage(error),
          'X',
          { panelClass: ['error-snackbar'] }
        );
      },
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    if ($('.popoverButtonCR').length > 0) {
      $('.popoverButtonCR').popover('hide');
    }
    if ($('.popoverButtonApproval').length > 0) {
      $('.popoverButtonApproval').popover('hide');
    }
  }
  async ngOnInit(): Promise<void> {
    //Project Approvers were facing issues with viewing attachments and seeing their comments in approval emails after they have approved or rejected the first project in their new activation and change request approval queues (INC002066467, INC002024053).
    //In order to mitigate this, we have reinitialized the data table instances each time the user's My Approvals page gets refreshed after their project approvals.
    //Check if the datatablesApproval element is already a Datatable and await the result.
    await $.fn.dataTable.isDataTable('#datatablesApproval');
    const isDataTableApproval = await $.fn.dataTable.isDataTable(
      '#datatablesApproval'
    );
    if (isDataTableApproval) {
      $('#datatablesApproval').DataTable().destroy();
    }
    // Check if the datatablesCR element is already a Datatable and await the result.
    // While implementing the code, we encountered an error "Cannot read properties of undefined (reading 'aDataSort')" when the await was used only once.
    // We have tried different approaches to address the issue without the second await, the error persisted. Hence, the second await was added as a necessary workaround.
    await $.fn.dataTable.isDataTable('#datatablesCR');
    const isDataTableCR = await $.fn.dataTable.isDataTable('#datatablesCR');
    if (isDataTableCR) {
      $('#datatablesCR').DataTable().destroy();
    }

    (async () => {
      while (this.allDataLoaded !== true)
        await new Promise((resolve) => setTimeout(resolve, 2000));
      //datatable initialalization for new Activation
      this.dtOptionsApproval = {
        pagingType: 'full_numbers',
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, 'All'],
        ],
        pageLength: 25,
        dom: 'lrtip',
        data: this.ApprovalDataSource,
        language: {
          emptyTable: 'No Pending Approvals',
        },
        columns: [
          {
            orderable: false,
            className: 'select-checkbox',
            data: null,
            defaultContent: '',
          },
          {
            title: 'Approver Comments',
            className: 'input-action',
            orderable: false,
            data: '',
            defaultContent: '',
            render: renderInputField,
          },
          { title: 'Submitted', data: 'submittedOn' },
          {
            title: 'Activation WBS',
            className: 'NavigationColumn',
            orderable: false,
            data: 'activationWbs',
            defaultContent: '',
            render: renderWbs,
          },
          { title: 'Amount', data: 'amount', className: 'text-right' },
          { title: 'Start Date', data: 'startDate' },
          { title: 'End Date', data: 'endDate' },
          {
            title: 'Attachments',
            className: 'download-action text-center', //styles for attachment icon
            orderable: false,
            data: null,
            defaultContent: '',
            render: renderAttachmentApproval,
          },
          {
            title: 'Title',
            data: 'title',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Activation Description',
            data: 'activationDesc',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Project Owner',
            data: 'projectOwner',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Vendor',
            data: 'vendor',
            render: $.fn.dataTable.render.text(),
          },
          { title: 'Number of SKUs', data: 'noOfSku' },
          { title: 'Clarizen Number', data: 'clarizenNo' },
          {
            title: 'Add Attachments',
            className: 'add-attachment',
            orderable: false,
            data: '',
            defaultContent: '',
            render: renderInput,
          },
        ],
        scrollX: true, //horizontal scroll
        ordering: false,
        info: false,
        autoWidth: true, //enabling columns width to adjust with screen size
        select: {
          style: 'multi',
          selector: 'td:first-child',
        },
      };
      this.datatablesApproval = $(this.table1.nativeElement);
      this.datatablesApproval.DataTable(this.dtOptionsApproval);

      //datatable initialization for CR
      this.dtOptionsCR = {
        pagingType: 'full_numbers',
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, 'All'],
        ],
        pageLength: 25,
        dom: 'lrtip',
        data: this.CRDataSource,
        language: {
          emptyTable: 'No Pending Approvals',
        },
        columns: [
          {
            className: 'view-action',
            orderable: false,
            data: '',
            defaultContent: '',
            render: renderExpand,
          },
          {
            orderable: false,
            className: 'select-checkbox',
            targets: 0,
            data: null,
            defaultContent: '',
          },
          {
            title: 'Approver Comments',
            className: 'input-action',
            orderable: false,
            data: null,
            defaultContent: '',
            render: renderInputField,
          },

          { title: 'Submitted', data: 'submittedOn' },

          {
            title: 'Activation WBS',
            className: 'NavigationColumn',
            orderable: false,
            data: 'activationWbs',
            defaultContent: '',
            render: renderWbs,
          },
          { title: 'Amount', data: 'amount', className: 'text-right' },
          { title: 'Start Date', data: 'startDate' },
          { title: 'End Date', data: 'endDate' },
          {
            title: 'Attachments',
            className: 'download-action text-center', //style changes for download icon
            orderable: false,
            data: null,
            defaultContent: '',
            render: renderAttachmentCR,
          },
          {
            title: 'Change Request Comment',
            data: 'crComment',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Title',
            data: 'title',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Activation Description',
            data: 'activationDesc',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Project Owner',
            data: 'projectOwner',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Vendor',
            data: 'vendor',
            render: $.fn.dataTable.render.text(),
          },
          { title: 'Number of SKUs', data: 'noOfSku' },
          { title: 'Clarizen Number', data: 'clarizenNo' },
          {
            title: 'Add Attachments',
            className: 'add-attachment',
            orderable: false,
            data: '',
            defaultContent: '',
            render:renderInput,
          },
        ],
        scrollX: true, //horizontal scroll
        ordering: false,
        scrollCollapse: true,
        info: false,
        autoWidth: true, //enabling columns width to adjust with screen size
        select: {
          style: 'multi',
          selector: 'td:nth-child(2)',
        },
      };
      this.datatablesCR = $(this.table2.nativeElement);
      this.datatablesCR.DataTable(this.dtOptionsCR);
      $('th').css('font-weight', 'bold');
      $('label').css('color', 'black'); //style for pagination count
      $('label').css('padding-left', '1rem'); //style for pagination count
      $('div.dataTables_scrollHeadInner').css(
        'border-bottom',
        '2px solid #C0C0C0'
      ); //horizontal rule for header
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css(
        'background',
        '#e1e1e1'
      ); //footer color
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css(
        'padding',
        '1rem'
      ); //footer padding
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css(
        'margin-top',
        '0.5rem'
      ); //footer padding
      $('table').css('border-bottom', '2px solid #C0C0C0');
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css(
        'margin-bottom',
        '0rem'
      );
      $('div.dataTables_scroll').css('padding-right', '1rem');
      $('div.dataTables_scroll').css('padding-left', '1rem');
      $('td.view-action').css('padding-top', '0.5rem');
      $('td:last-child').css('padding-right', '1rem');
      //child row for CR table
      $('#datatablesCR tbody').on('click', 'td:first-child', function () {
        var table = $('#datatablesCR').DataTable();
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        var $row = $(this).parents('tr');
        var rowData = table.row($row).data();

        if (row.child.isShown()) {
          // This row is already open - close it.
          row.child.hide();
          tr.removeClass('shown');
        } else {
          // Open row.
          row
            .child(
              $(
                '<tr>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td>' +
                  'Old Values' +
                  '</td>' +
                  '<td class="text-right">' +
                  rowData.oldAmount +
                  '</td>' +
                  '<td>' +
                  rowData.oldstartdate +
                  '</td>' +
                  '<td>' +
                  rowData.oldendDate +
                  '</td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '<td></td>' +
                  '</tr>'
              )
            )
            .show();
          tr.addClass('shown');
          setTimeout(() => {
            $('#datatablesCR').DataTable().columns.adjust().responsive.recalc();
          }, 300);
        }
      });
      await $.fn.dataTable.isDataTable('#datatablesApproval');
      if ($.fn.dataTable.isDataTable('#datatablesApproval') == true) {
        setTimeout(() => {
          $('#datatablesApproval')
            .DataTable()
            .columns.adjust()
            .responsive.recalc();
        }, 300);
        //adjusting columns with screen size for new activation table
      }
      await $.fn.dataTable.isDataTable('#datatablesCR');
      if ($.fn.dataTable.isDataTable('#datatablesCR') == true) {
        setTimeout(() => {
          $('#datatablesCR').DataTable().columns.adjust().responsive.recalc();
        }, 300);
        //adjusting columns with screen size for CR table
      }
    })();
    let globalThis = this;
    //input field for comments new activation table
    $('#datatablesApproval').on(
      'keydown keyup keypress',
      'td.input-action',
      function () {
        var table = $('#datatablesApproval').DataTable();
        var current_row = this;
        var cell = $(current_row).find('input').val();
        var data = table.row($(this).closest('tr')).data();
        if (cell.length > 250) {
          globalThis.disableButton = true;
          globalThis.showWarningApproval = true;
          $(current_row).find('input').css('border', '4px solid red');
        } else {
          globalThis.disableButton = false;
          globalThis.showWarningApproval = false;
          $(current_row).find('input').css('border', '2px solid black');
          data.comment = cell; //binding input comment field to table data
        }
      }
    );
    //method for adding attachments on new approval
    $('#datatablesApproval').on('change ', 'td.add-attachment', function (e) {
      var table = $('#datatablesApproval').DataTable();
      var data = table.row($(this).closest('tr')).data();
      let file = e.target.files.item(0);
      if(globalThis.projectservice.fileIsTooLarge(file)) {
        globalThis._snackBar.open(
          'File size greater than 50MB. Please select a smaller attachment.',
          'Close',
          { panelClass: ['red-snackbar'] }
        );
      } else if (file.name.length > 180) {
        //adding validation for length of file name
        globalThis._snackBar.open(
          'The file name is over 180 characters, please reupload the file with a shorter title in order to submit an activation',
          'Close',
          { panelClass: ['red-snackbar'] }
        );
      } else {
        data.newAttachment.push(file);
        let listItem = $('<li></li>').text(file.name);
        $(this).append(listItem);
        $('td').css('vertical-align', 'top');
        $('#datatablesApproval')
          .DataTable()
          .columns.adjust()
          .responsive.recalc();
      }
    });
    //method for adding attachments on CR
    $('#datatablesCR').on('change ', 'td.add-attachment', function (e) {
      var table = $('#datatablesCR').DataTable();
      var data = table.row($(this).closest('tr')).data();
      let file = e.target.files.item(0);
      if(globalThis.projectservice.fileIsTooLarge(file)) {
        globalThis._snackBar.open(
          'File size greater than 50MB. Please select a smaller attachment.',
          'Close',
          { panelClass: ['red-snackbar'] }
        );
      } else if (file.name.length > 180) {
        //adding validation for length of file name
        globalThis._snackBar.open(
          'The file name is over 180 characters, please reupload the file with a shorter title in order to submit an activation',
          'Close',
          { panelClass: ['red-snackbar'] }
        );
      } else {
        data.newAttachment.push(file);
        let listItem = $('<li></li>').text(file.name);
        $(this).append(listItem);
        $('td').css('vertical-align', 'top');
        $('#datatablesCR').DataTable().columns.adjust().responsive.recalc();
      }
    });
    //input field for comments Cr table
    $('#datatablesCR').on(
      'keydown keyup keypress focus',
      'td.input-action',
      function () {
        var table = $('#datatablesCR').DataTable();
        var current_row = this;
        var cell = $(current_row).find('input').val();
        var data = table.row($(this).closest('tr')).data();
        if (cell.length > 250) {
          globalThis.disableButton = true;
          globalThis.showWarningCR = true;
          $(current_row).find('input').css('border', '4px solid red');
        } else {
          globalThis.disableButton = false;
          globalThis.showWarningCR = false;
          $(current_row).find('input').css('border', '2px solid black');
          data.comment = cell; //binding input comment field to table data
        }
      }
    );
    $('#datatablesCR').on('click', 'td.download-action', function (e) {
      e.preventDefault();

      $('.popoverButtonCR').popover({
        html: true,
        trigger: 'focus',
        tabIndex: 0,
        content: function () {
          var table = $('#datatablesCR').DataTable();
          var data = table.row($(this).closest('tr')).data();
          var attachmentlist = '';
          for (let key of data.attachments) {
            key.filename = key.filename
              .replace(/\(/g, '%28')
              .replace(/\)/g, '%29'); //replacing parentheses by codes
            var message =
              '<li><a href="#/attachments/' +
              key.filename +
              '">' +
              key.trimmedFileName +
              '</a></li>';
            attachmentlist += message;
          }
          return '<ul>' + attachmentlist + '</ul>';
        },
      });
      if ($('.popoverButtonCR').length > 0) {
        $('.popoverButtonCR').popover('hide');
      }
      $(e.target).popover('toggle');
    });

    //opening popover for attachments approvals table

    $('#datatablesApproval').on('click', 'td.download-action', function (e) {
      $('.popoverButtonApproval').popover({
        html: true,
        trigger: 'focus',
        tabIndex: 0,
        content: function () {
          var table = $('#datatablesApproval').DataTable();
          var data = table.row($(this).closest('tr')).data();
          var attachmentlist = '';
          for (let key of data.attachments) {
            key.filename = key.filename
              .replace(/\(/g, '%28')
              .replace(/\)/g, '%29'); //replacing parentheses by codes
            var message =
              '<li><a href="#/attachments/' +
              key.filename +
              '">' +
              key.trimmedFileName +
              '</a></li>';
            attachmentlist += message;
          }
          return '<ul>' + attachmentlist + '</ul>';
        },
      });
      if ($('.popoverButtonApproval').length > 0) {
        $('.popoverButtonApproval').popover('hide');
      }
      $(e.target).popover('toggle');
    });

    $('body').on('click', 'div.popover-body', function (e) {
      if ($('.popoverButtonApproval').length > 0) {
        //popover not closing when only single activation is there
        $('.popoverButtonApproval').popover('hide');
      }
      if ($('.popoverButtonCR').length > 0) {
        //popover not closing when only single activation is there
        $('.popoverButtonCR').popover('hide');
      }
    });
  }

  //method for approval submission
  approve(event) {
    this.disableButton = true;
    event.preventDefault();
    let outputArray = [];
    var table1 = $('#datatablesApproval').DataTable();
    var rows_selectedApproval = table1.rows({ selected: true }).data();
    var table2 = $('#datatablesCR').DataTable();
    var rows_selectedCR = table2.rows({ selected: true }).data();
    var selectedListApproval = rows_selectedApproval.splice(
      0,
      rows_selectedApproval.length
    );
    var selectedListCR = rows_selectedCR.splice(0, rows_selectedCR.length);
    var totalSelected = selectedListApproval.concat(selectedListCR);
    this.toggleApproveRejectButton(totalSelected.length == 0);
    totalSelected.forEach(async (activation) => {
      if (activation.comment == undefined) {
        activation.comment = '';
      }
      if (activation.newAttachment.length != 0) {
        this.createBase64(activation);
      }
      outputArray.push({
        activationWbs: activation.activationWbs,
        status: 'approved',
        comment: activation.comment,
        approvalType: activation.type,
      });
    });
    if (this.disableButton) {
      this._snackBar.open('Please wait- Approval Status Loading', 'Close');
      this.projectservice.approveOrRejectRequest(outputArray).subscribe({
        next: (resp) => {
          this.allDataLoaded = false;
          if (this.uploadAttaArray.length > 0) {
            this.saveDocuments(this.uploadAttaArray);
          }
          this._snackBar.dismiss();
          this.reloadPage();
        },
        error: (error: unknown) => {
          this._snackBar.open('Failed to approve activations. ' + getErrorMessage(error), 'X', {
            panelClass: ['error-snackbar'],
          });
        },
      });
    }
  }
  createBase64(activation) {
    activation.newAttachment.forEach(async (file) => {
      await new Promise<void>((resolve) => {
        this.readBase64(file).then((data) => {
          let fileObj = { file: '', filename: '', activationWbs: '' };
          let base64 = data.split(',');
          let sDocumentName = file.name;
          fileObj.file = base64[1];
          fileObj.filename = sDocumentName;
          fileObj.activationWbs = activation.activationWbs;
          this.uploadAttaArray.push(fileObj);
          resolve();
        });
      });
    });
  }
  saveDocuments(attachments) {
    this.activationService
      .saveActivationSupportingDocuments(attachments)
      .subscribe({
        next: (resp) => {
          this._snackBar.open('Uploaded Files ', 'Close', {
            panelClass: ['green-snackbar'],
          });
        },
        error: (error: unknown) => {
          this._snackBar.open('Failed to upload attachments. ' + getErrorMessage(error), 'X', {
            panelClass: ['error-snackbar'],
          });
        },
      });
  }
  //method for rejection submission
  reject(event) {
    this.disableButton = true;
    event.preventDefault();
    let outputArray = [];
    var table1 = $('#datatablesApproval').DataTable();
    var rows_selectedApproval = table1.rows({ selected: true }).data();
    var table2 = $('#datatablesCR').DataTable();
    var rows_selectedCR = table2.rows({ selected: true }).data();
    var selectedListApproval = rows_selectedApproval.splice(
      0,
      rows_selectedApproval.length
    );
    var selectedListCR = rows_selectedCR.splice(0, rows_selectedCR.length);
    var totalSelected = selectedListApproval.concat(selectedListCR);
    this.toggleApproveRejectButton(totalSelected.length == 0);
    totalSelected.forEach((activation) => {
      if (activation.comment == undefined) {
        activation.comment = '';
      }
      if (activation.newAttachment.length != 0) {
        this.createBase64(activation);
      }
      outputArray.push({
        activationWbs: activation.activationWbs,
        status: 'rejected',
        comment: activation.comment,
        approvalType: activation.type,
      });
    });
    if (this.disableButton) {
      this._snackBar.open('Please wait- Approval Status Loading', 'Close');
      this.projectservice.approveOrRejectRequest(outputArray).subscribe({
        next: (resp) => {
          this.allDataLoaded = false;
          if (this.uploadAttaArray.length > 0) {
            this.saveDocuments(this.uploadAttaArray);
          }
          this._snackBar.dismiss();
          this.reloadPage();
        },
        error: (error: unknown) => {
          this._snackBar.open('Failed to reject activations. ' + getErrorMessage(error), 'X', {
            panelClass: ['error-snackbar'],
          });
        },
      });
    }
  }
  private readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }
  //method for reloading dashboard after submission
  reloadPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['myapprovals']);
  }
  //method for building table gridobject from api results
  buildProjectGridObject(requestObject) {
    requestObject.forEach((requestObj) => {
      let tableObj : any = {};
      tableObj = {
        submittedOn: this.formatSubmittedOn(requestObj.submittedOn),
        activationWbs: requestObj.activationWbs.activationWbs,
        amount: this.currencyPipe.transform(
          requestObj.activationWbs.activationAmount,
          ' '
        ),
        title: requestObj.projectId.projectTitle,
        activationDesc: requestObj.activationWbs.projectActivationDesc,
        projectOwner: requestObj.projectId.projectOwnerZid.split(' - ')[0],
        vendor: requestObj.activationWbs.vendorId.vendorName,
        startDate: this.formatDate(requestObj.activationWbs.startDate),
        endDate: this.formatDate(requestObj.activationWbs.endDate),
        attachment: '',
        type: requestObj.approvalType,
        lastChange: requestObj.LastChange,
        attachments: requestObj.attachments,
        projectId: requestObj.projectId.projectId,
        newAttachment: [],
        noOfSku: requestObj.activationWbs.numberOfSKUs,
        clarizenNo: requestObj.activationWbs.clarizenNumber,
      };
      if (tableObj.type == 'newActivation') {
        this.ApprovalDataSource.push(tableObj);

        //sorting activations in ascending order
        if (this.ApprovalDataSource.length > 0) {
          this.ApprovalDataSource.sort(
            (n1, n2) =>
              new Date(n1.submittedOn).getTime() -
              new Date(n2.submittedOn).getTime()
          );
        }
      } else if (tableObj.type == 'changeRequest') {
        //adding old values for change request

        tableObj.lastChange.forEach((element) => {
          switch (element.sourceField) {
            case 'activationAmount':
              tableObj.amount = this.currencyPipe.transform(
                element.newValue,
                ' '
              );
              tableObj.oldAmount = this.currencyPipe.transform(
                element.oldValue,
                ' '
              );

              break;
            case 'startDate':
              tableObj.startDate = this.formatDate(element.newValue);
              tableObj.oldstartdate = this.formatDate(element.oldValue);

              break;
            case 'endDate':
              tableObj.endDate = this.formatDate(element.newValue);
              tableObj.oldendDate = this.formatDate(element.oldValue);

              break;
            case 'changeRequestComment':
              tableObj.crComment = element.newValue;
          }
        });

        if (!tableObj.oldAmount) {
          tableObj.oldAmount = 'No Change';
        }
        if (!tableObj.oldendDate) {
          tableObj.oldendDate = 'No Change';
        }
        if (!tableObj.oldstartdate) {
          tableObj.oldstartdate = 'No Change';
        }
        if (!tableObj.crComment) {
          tableObj.crComment = '';
        }
        this.CRDataSource.push(tableObj);
        //sorting activations in ascending order
        if (this.CRDataSource.length > 0) {
          this.CRDataSource.sort(
            (n1, n2) =>
              new Date(n1.submittedOn).getTime() -
              new Date(n2.submittedOn).getTime()
          );
        }
      }
    });
  }
  //table headers
  ngDoCheck() {
    this.dataTableApproval = {
      headerRow: [
        '',
        'Approver Comments',
        'Submitted',
        'Activation WBS',
        'Amount',
        'Start Date',
        'End Date',
        'Attachments',
        'Title',
        'Activation Description',
        'Project Owner',
        'Vendor',
        'noOfSku',
        'clarizenNo',
        'Add Attachments',
      ],
      dataRows: this.ApprovalDataSource,
    };
    this.dataTableCR = {
      headerRow: [
        '',
        '',
        'Approver Comments',
        'Submitted',
        'Activation WBS',
        'Amount',
        'Start Date',
        'End Date',
        'Attachments',
        'Change Request Comment',
        'Title',
        'Activation Description',
        'Project Owner',
        'Vendor',
        'noOfSku',
        'clarizenNo',
        'Add Attachments',
      ],
      dataRows: this.CRDataSource,
    };
  }
  formatDate(createOn) {
    let edate = new Date(createOn);
    edate = new Date(edate.getTime() + edate.getTimezoneOffset() * 60000);
    const formattedDate = this.datePipe.transform(edate, 'MM/dd/yyyy');
    return formattedDate;
  }
  formatSubmittedOn(submittedOn) {
    let edate = new Date(submittedOn);
    //fixing time zone difference issue
    return edate.toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  //getting list of attachments
  getAttachments(object) {
    let attachmentList = [];
    return new Promise((resolve) => {
      this.projectservice
        .getListOfDocuments(object.activationWbs.activationWbs)
        .subscribe({
          next: (resp) => {
            if (resp.length > 0) {
              attachmentList = resp;
              for (let file of attachmentList) {
                file['trimmedFileName'] = this.trimAttachmentList(
                  file.filename
                );
                file.filename = this.projectservice.replaceCharacters(
                  file.filename
                );
                file.filename = encodeURIComponent(file.filename);
              }
              attachmentList.reverse();
              resolve(attachmentList);
            } else {
              resolve(attachmentList);
            }
          },
          error: (error: unknown) => {
            this._snackBar.open(
              'Failed to load list of attachments. ' + getErrorMessage(error),
              'X',
              { panelClass: ['error-snackbar'] }
            );
          },
        });
    });
  }
  trimAttachmentList(filename: string): string {
    const String2 = filename.slice(filename.indexOf('_') + 1);
    const String1 = String2.slice(String2.indexOf('_') + 1);
    return String1;
  }

  // method for disabling or enabling approve/reject button
  toggleApproveRejectButton(enabled: boolean) {
    if (enabled) {
      this.disableButton = false;
      this._snackBar.open(
        'Please select an activation for approval/rejection',
        'Close'
      );
    } else {
      this.disableButton = true;
    }
  }
}


