import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe ,DatePipe} from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { SidebarComponent } from './header/sidebar/sidebar.component';
import { DashboardComponent } from './header/dashboard/dashboard.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { GlobalErrorHandler } from './shared/services/global-error-handler';

import {SIFTInterceptor} from './shared/services/apiServices/SIFTIinterceptor';
import { AppInsightsMonitoringService } from './shared/services/appinsights-logging.service';
// import { MSAuthService } from './shared/services/services/ms_auth.service';
import { MsalModule,MsalRedirectComponent, MsalService,MsalBroadcastService, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from './environments/environment';
import { ViewChangeRequestDialog } from './new-project/template/change/change.review';
import { ViewConfirmationDialog } from './new-project/template/modal/dialog.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { ActivationDetailsComponent } from './activation-details/activation-details.component';
import { ReadonlyReportComponent } from './readonly-report/readonly-report.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectFormComponent } from './project-form/project-form.component';
import { ViewActivationConfirmationDialog } from './activation-details/modal/dialog.component';
import { StylePaginatorDirective } from './readonly-report/style-paginator.directive';
import { MyApprovalsComponent } from './my-approvals/my-approvals.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { ProjectDetailsDialog } from './guard/pop-up/save-popup.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule, 
     
  ],
  declarations: [
  
    AttachmentsComponent,       
           
  ],
  imports: []
})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
   
  MsalModule.forRoot( new PublicClientApplication({
    auth: {
      clientId: environment.MS_CLIENT_ID,
      authority: environment.MS_AUTHORITY,
      redirectUri: environment.REDIRECT_URI
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    }
  }), null, {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [environment.API_URL+'/api', ['user.read']]
  ]) // MSAL Interceptor Configuration
})  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarComponent,
    NavbarComponent,
    ViewChangeRequestDialog,
    ViewConfirmationDialog,
    ReadonlyReportComponent,
    ActivationDetailsComponent,
    ProjectDetailsComponent ,
    ProjectFormComponent,
    ViewActivationConfirmationDialog  ,
    StylePaginatorDirective,
    MyApprovalsComponent,
    ProjectDetailsDialog 
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SIFTInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  AppInsightsMonitoringService,
  MsalService,
  CurrencyPipe,
  MsalBroadcastService,
  DatePipe
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
