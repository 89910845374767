import { Component } from '@angular/core';

@Component({
  template: ''
})
export class AttachmentsComponent {

  constructor() { }

}
