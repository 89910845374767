import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validateBudgetGrp(BudgetGroups:any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        const selection: any = control.value;        
        if (!(BudgetGroups.find(s=>s.budgetGroupDesc==selection))) {
            return { noMatch: true };
        }else{
        return null;
        }
    }; 

}
export function validateProjectOwner(projectOwners:any): ValidatorFn{
    return(control: FormControl): ValidationErrors | null => {
        const selection:any = control.value;
        if(!(projectOwners.find(s=>s.projectOwnerName==selection))) {
            return { noMatch: true};
        }else{
            return null;
        }
    }
}