<div class="main-content">
    <button mat-raised-button class="btn btn-outline-success p-2" [disabled]="disableButton" [routerLink]="['/dashboard']"> <mat-icon>arrow_back_ios</mat-icon>Back to dashboard</button>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
                <!--      Wizard container        -->
                <div class="wizard-container">
                    <div class="card card-wizard content-padding" data-color="green" id="wizardProfile">
                        <form [formGroup]="type" action="" method="">
                            <!--        You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"       -->
                            <div class="card-header text-center">
    
                                <h2 class="card-title">
                                    {{formTitle}}
                                </h2>
                            </div>
                            <div class="wizard-navigation">
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#project" data-toggle="tab" role="tab">
                                            Project
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#activation" data-toggle="tab" role="tab">
                                            Activations
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="project">
                                        <div class="row justify-content-center">
                                            <app-project-form></app-project-form>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="activation">
                                        <div class="row justify-content-center">
                                            <app-activation-details></app-activation-details>
                                        </div>
                                    </div>
                                </div>
                            </div>
                     
                        </form>
                    </div>
                </div>
                <!-- wizard container -->
            </div>
        </div>
    </div>
</div>
