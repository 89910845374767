
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,  Subject,  throwError } from 'rxjs';
import { DataService } from '../shared/services/apiServices/data.service';
import { Project, ProjectListMapper } from '../shared/models/project';
import { retry, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

/**
* Manages ui specific data caching
*/
@Injectable({ providedIn: 'root' })
export class ProjectService {
  public ProjectObject = new Subject<any>();
  ProjectObjectObservable = this.ProjectObject.asObservable();
  public projectId = new BehaviorSubject<any>(0);
  projectIdObservable = this.projectId.asObservable();
  //observable for passing projectdetails for new projects without activations
  public projectDetails = new BehaviorSubject<any>([]);
  projectDetailsObservable = this.projectDetails.asObservable();
  //observable for passing previous URL
  public previousURL = new BehaviorSubject<any>([]);
  previousURLObservable = this.previousURL.asObservable();
  //observable for passing boolean for disabling button
  public disableButton = new BehaviorSubject<any>(false);
  disableButtonObservable = this.disableButton.asObservable()
  constructor(private dataService: DataService<Project>, private datePipe:DatePipe) {
  }

  getAllProjects(): Observable<Project[]> {
    return this.dataService.get('/api/listproject').pipe(retry(1), catchError(this.handleError));
  }

  getProjectBaseObject(id: any) {
    return this.dataService.get('/api/project/' + id).pipe(retry(1), catchError(this.handleError));
  }

  getProducts(budgetGroup:any): Observable<any> {
    return this.dataService.get('/api/listproductbybudgetgroup?budgetGroupId='+budgetGroup).pipe(retry(1), catchError(this.handleError));
  }

  getCustomers(): Observable<any> {
    return this.dataService.get('/api/listcustomer').pipe(retry(1), catchError(this.handleError));
  }

  getFiscalYears(): Observable<any> { 
    return this.dataService.get('/api/datesValidForActivations').pipe(retry(1), catchError(this.handleError));
  }

  getFiscalYearsUnique(): Observable<any> {
    return this.dataService.get('/api/listcalendaruniquefiscalyear').pipe(retry(1), catchError(this.handleError));
  }

  getVendors(): Observable<any> {
    return this.dataService.get('/api/listvendor').pipe(retry(1), catchError(this.handleError));
  }

  getBU(): Observable<any> {
    return this.dataService.get('/api/listbusinessunit').pipe(retry(1), catchError(this.handleError));
  }

  getBudgetGroup(): Observable<any> {
    return this.dataService.get('/api/listbudgetgroup').pipe(retry(1), catchError(this.handleError));
  }

  getFcstVersion(): Observable<any> {
    return this.dataService.get('/api/listfcstversion').pipe(retry(1), catchError(this.handleError));
  }

  getActivations(budgetGroupId:any): Observable<any> {
    return this.dataService.get('/api/listactivationbybudgetgroup?budgetGroupId='+budgetGroupId).pipe(retry(1), catchError(this.handleError));
  }

  getProjectActivationAllocations(projectid: any): Observable<any> {
    return this.dataService.get('/api/listactivationallocation?' + projectid).pipe(retry(1), catchError(this.handleError));
  }

  getProjectActivations(projectid: any): Observable<any> {
    return this.dataService.get('/api/listprojectactivation?projectId=' + projectid).pipe(retry(1), catchError(this.handleError));
  }

  getAllProjectActivations(): Observable<Project[]> {
    return this.dataService.get('/api/listprojectactivation').pipe(retry(1), catchError(this.handleError));
  }

  getDashboardAccrualList():Observable<any>{
    return this.dataService.get('/api/dashboardView').pipe(retry(1), catchError(this.handleError));
  }

  getActivationAllocations(projectId: any): Observable<any> {
    return this.dataService.get('/api/listactivationallocationnested?projectId=' + projectId).pipe(retry(1), catchError(this.handleError));
  }
  getAllocations(activationWbs:any): Observable<any>{
    return this.dataService.get('/api/listactivationallocationnested?activationWbs=' + activationWbs).pipe(retry(1), catchError(this.handleError)); 
  }

  getActivationsPendingApproval(zid:any,status:any): Observable<Project[]> {
    return this.dataService.get('/api/listapprovalstatus?approverZid='+zid+'&workflowStatus='+status).pipe(retry(1), catchError(this.handleError));
  }
  

  getActivationsDetails(activationWbs:any): Observable<Project[]> {
    return this.dataService.get('/api/listprojectactivation?activationWbs='+activationWbs).pipe(retry(1), catchError(this.handleError));
  }

  saveProjectBaseDetails(PjctBaseObj: any) {
    let ProjectBaseObject = this.CreateProjectBaseObjectPOST(PjctBaseObj);
    return this.dataService.post('/api/listproject', ProjectBaseObject).pipe(retry(1), catchError(this.handleError));
  }

  editProjectBaseDetails(PjctBaseObj: any) {
    let ProjectBaseObject = this.CreateProjectBaseObjectPUT(PjctBaseObj);
    return this.dataService.update('/api/project/'+ProjectBaseObject.projectId, ProjectBaseObject).pipe(retry(1), catchError(this.handleError));
  }

  saveProjectActivation(ActBaseObj: any) {
    let actobj = this.CreateProjectActivationObjectPOST(ActBaseObj);
    return this.dataService.post('/api/listprojectactivation', actobj).pipe(retry(1), catchError(this.handleError));
  }

  updateProjectActivation(ActBaseObj: any,activationwbs:any): Observable<any>{
    let actobj = this.CreateProjectActivationObjectPUT(ActBaseObj);
    return this.dataService.update('/api/projectactivation/'+activationwbs, actobj).pipe(retry(1), catchError(this.handleError));
  }

  getChangeId(changeobj:any): Observable<any>{
    return this.dataService.post('/api/listchangereview', changeobj).pipe(retry(1), catchError(this.handleError));
  }

  getCRs(projectId:any): Observable<any>{
    return this.dataService.get('/api/listchangereview?projectId='+projectId).pipe(retry(1), catchError(this.handleError));
  }

  getChangeFields(changeid:any):Observable<any>{
    return this.dataService.get('/api/listfieldchangeview?changeId_id='+changeid).pipe(retry(1), catchError(this.handleError));
  }

  submitCR(ChangedObj:any): Observable<any>{
    return this.dataService.post('/api/listfieldchange', ChangedObj).pipe(retry(1), catchError(this.handleError));
  }

  saveActivationAllocation(allocationobj: any) {
    let allocobj = this.CreateActivationAllocationObjectPOST(allocationobj);
    return this.dataService.post('/api/listactivationallocation', allocobj).pipe(retry(1), catchError(this.handleError));
  }

  updateAllocation(allocationobj: any): Observable<any> {
    let allocobj = this.CreateActivationAllocationObjectPUT(allocationobj);
    return this.dataService.update('/api/activationallocation/' + allocobj.allocationWbs, allocobj).pipe(retry(1), catchError(this.handleError));
  }

  submitActivationsforApproval(actArray:any): Observable<any>{
    return this.dataService.postApproval('/invoke?api-version=2016-10-01',actArray).pipe(retry(1), catchError(this.handleError));
  }
  saveSupportingDocuments(sDocumentName:any, activationwbs:any,file:any) {
    let fileObj = this.CreateFileObject(sDocumentName, activationwbs,file);
    return this.dataService.post('/api/listfileattachment', fileObj).pipe(retry(1), catchError(this.handleError));
  }

  getListOfDocuments(activationWbs:any): Observable<any>{
    return this.dataService.get('/api/listfileattachment?activationWbs='+ activationWbs).pipe(retry(1), catchError(this.handleError));
  }
  deleteAttachment(attachmentId:any): Observable<any>{
    return this.dataService.delete('/api/fileattachmentretrievedelete/'+ attachmentId).pipe(retry(1), catchError(this.handleError));
  }

  downloadAttachment(attachmentId:any): Observable<any>{
    return this.dataService.get('/api/fileattachmentretrievedelete/'+ attachmentId).pipe(retry(1), catchError(this.handleError));
  }
  getTransferTypeList(): Observable<any>{
    return this.dataService.get('/api/listtransfertype').pipe(retry(1), catchError(this.handleError));
  }
  getProjectReportActivations(projectId: any): Observable<any> {
    return this.dataService.get('/api/listactivationallocationnested?projectId=' + projectId).pipe(retry(1), catchError(this.handleError));
  }
  approveOrRejectRequest(requestObj:any): Observable<any> {
    return this.dataService.postApproveOrReject('/api/approvalemails',requestObj).pipe(retry(1), catchError(this.handleError));
  }
  listCRByActivation(activationWbs:any): Observable<any> {
    return this.dataService.get('/api/listchangereview?activationWbs='+activationWbs).pipe(retry(1), catchError(this.handleError));
  }
  //changing api endpoint name since it is used for multi-purpose
  getSiftAppSettings(): Observable<any>{
    return this.dataService.get('/api/listappsettings').pipe(retry(1), catchError(this.handleError));
  }
  getApprovalStatusByActivationId(changeId:any): Observable<any> {
    return this.dataService.get('/api/listapprovalstatus?changeId='+changeId).pipe(retry(1), catchError(this.handleError));
  }
  getListofOwners(bId:any): Observable<any>{
    return this.dataService.get('/api/listprojectownersbybudgetgroup?budgetGroupId='+bId).pipe(retry(1), catchError(this.handleError));
  }
  getVendorByBudgetGroup(bId:any): Observable<any>{
    return this.dataService.get('/api/listvendorbybudgetgroup?budgetGroupId='+bId).pipe(retry(1), catchError(this.handleError));
  }
  put(key: string, response: any) {
    localStorage.setItem(key,
      JSON.stringify(response));
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
  CreateFileObject(sDocumentName:any, activationWbs:any,file:any){
    let fileObject = {
        "filename": sDocumentName,
        "activationWbs": activationWbs,
        "file":file
    }
    return fileObject;
  }

 CreateProjectBaseObjectPOST(PjctObj: any) {
    let ProjectBaseObject = {
      "budgetGroupId": PjctObj.budgetGroupId,
      "projectDescription": PjctObj.projectDescription,
      "projectOwnerZid": PjctObj.projectOwnerZid,
      "projectTitle": PjctObj.projectTitle,
      "dataSource": "SIFT UI"
    };
    return ProjectBaseObject;
  }

  CreateProjectBaseObjectPUT(PjctObj: any) {
    let ProjectBaseObject = {
      "budgetGroupId": PjctObj.budgetGroupId,
      "projectDescription": PjctObj.projectDescription,
      "projectOwnerZid": PjctObj.projectOwnerZid,
      "projectTitle": PjctObj.projectTitle,
      "projectId":PjctObj.projectId,
      "dataSource":"SIFT UI"
    };
    return ProjectBaseObject;
  }

  CreateProjectActivationObjectPOST(ActObj: any) {
    let startdate = this.formatDate(ActObj.get('startDate').value);
    let enddate = this.formatDate(ActObj.get('endDate').value);
    let ProjectActivationObject = {
      "clarizenNumber": ActObj.get('clarizenNumber').value,
      "projectActivationDesc": ActObj.get('projectActivationDesc').value,
      "fiscalYear": ActObj.get('fiscalYear').value,
      "startDate": startdate,
      "endDate": enddate,
      "projectId": ActObj.get('projectId').value,
      "spendType": ActObj.get('spendType').value,
      "vendorId": ActObj.get('vendorId').value,
      "numberOfSKUs": ActObj.get('numberOfSKUs').value,
      // "eventInitiative": ActObj.get('eventInitiative').value,
      "activationStatus": ActObj.get('activationStatus').value,
      "activationAmount": ActObj.get('activationAmount').value,
      "activationType": ActObj.get('activationType').value,
      "mmNumber": ActObj.get('mmNumber').value,
      "transferId": ActObj.get('transferId').value
    };
    return ProjectActivationObject;
  }

  CreateProjectActivationObjectPUT(ActObj: any) {
    let startdate = this.formatDate(ActObj.get('startDate').value);
    let enddate = this.formatDate(ActObj.get('endDate').value);
    let ProjectActivationObject = {
      "clarizenNumber": ActObj.get('clarizenNumber').value,
      "projectActivationDesc": ActObj.get('projectActivationDesc').value,
      "fiscalYear": ActObj.get('fiscalYear').value,
      "startDate": startdate,
      "endDate": enddate,
      "projectId": ActObj.get('projectId').value,
      "spendType": ActObj.get('spendType').value,
      "vendorId": ActObj.get('vendorId').value,
      "numberOfSKUs": ActObj.get('numberOfSKUs').value,
      "eventInitiative": ActObj.get('eventInitiative').value,
      "activationStatus": ActObj.get('activationStatus').value,
      "activationAmount": ActObj.get('activationAmount').value,
      "activationType": ActObj.get('activationType').value,
      "mmNumber": ActObj.get('mmNumber').value,
      "transferId": ActObj.get('transferId').value
    };
    return ProjectActivationObject;
  }

  CreateActivationAllocationObjectPOST(allocationobj: any) {
    let projectallocation = {
      "allocationDesc": 'DUMMY ALLOCATION DESCRIPTION',
      "allocationWeight": allocationobj.allocationWeight,
      "activationWbs": allocationobj.activationWbs.activationWbs,
      "projectId": allocationobj.projectId,
      "customerId": allocationobj.customerId,
      "zplnLvl": allocationobj.zplnLvl,
      "allocationType": allocationobj.allocationType,
      "calculatedAllocationAmount": allocationobj.calculatedAllocationAmount
    };
    return projectallocation;
  }

  CreateActivationAllocationObjectPUT(allocationobj: any) {
    let projectallocation =
    {
      "allocationWbs": allocationobj.allocationWbs,
      "allocationDesc": "DUMMY ALLOCATION DESCRIPTION",
      "allocationType": allocationobj.allocationType,
      "allocationWeight": allocationobj.allocationWeight,
      "calculatedAllocationAmount": allocationobj.calculatedAllocationAmount,
      "activationWbs": allocationobj.activationWbs.activationWbs,
      "projectId": allocationobj.projectId.projectId,
      "customerId": allocationobj.customerId,
      "zplnLvl": allocationobj.zplnLvl
    };
    return projectallocation;
  }

  formatDate(dateObj: any) {
    let currentDate=new Date(dateObj);
    let formatedDate=this.datePipe.transform(currentDate,'yyyy-MM-dd');
        return formatedDate;
  }
  getMimeType(extension:any){
    let mimeType = '';
    switch(extension){
      case '.docx':
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;

      case '.xls':
        mimeType = 'application/vnd.ms-excel';
        break;

      case '.pdf':
        mimeType = 'application/pdf';
        break;
      
      case '.doc':
        mimeType = 'application/msword';
        break;
      case '.xlsx':
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case '.jpg':
        mimeType = 'image/jpeg';
        break;
      case '.png':
        mimeType = 'image/png';
        break;
      case '.png':
        mimeType = 'application/vnd.ms-outlook';
        break;
    }
    return mimeType;
  }
  fileIsTooLarge(file){
    return file.size > 50 * 1024 * 1024;
  }
  // render.js

  handleError(error: any) {
    let errorMessage = '';
    let customErrMsg = '';
    if (error.error.endDate) {
      customErrMsg = error.error.endDate[0];
    }
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\n`;
    }
    return throwError(() => {
      if (customErrMsg.length > 0) {
        return customErrMsg;
      }
      else return errorMessage;
    });
  }

  downloadAttachmentInUI(resp) {
    const byteCharacters = atob(resp.file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const trimmedFileName = this.getValidFileName(resp.filename)
    let extension = trimmedFileName.split('.');
    extension.reverse();
    let mimeType = this.getMimeType(extension[0]);
    let file = new Blob([byteArray], { type: mimeType });
    var a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.target = '_self';
    a.download = trimmedFileName;
    a.click();
    a.remove();
  }
  
  //method for replacing parentheses in attachments url
  replaceCharacters(filename: string) {
    return filename.replace(/\(/g, "%28").replace(/\)/g, "%29");
  }

  //Users were facing issues while downloading attachments as the file name was having an underscore in the naming convention and the logic looked for _ to trim it
  //Accepted extensions for attachments are .docx, .xls, .pdf, .doc, .xlsx, .jpg, .png, .msg
  //files having issues for e.g. - 'M.90949.BD2_2024-03-01 12:13:42.901973-06:00_Server_Feb 16_.png' returned the name "_.png" with old code creating problems
  //In order to mitigate, we are filtering out the needed file name by the use of regex pattern below in the method
  getValidFileName(filename: string) {
    const regex = /\w\.\d+\.\w+_\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+-\d{2}:\d{2}_/
    let requiredFileName = filename.replace(regex, '');
    return requiredFileName;
  }
}