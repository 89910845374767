
<h2 mat-dialog-title>Changes Raised For This Project
  <!-- close button for pop-up -->
  <mat-icon mat-dialog-close style="float:right;cursor: pointer;">close</mat-icon>
</h2>
  <div *ngIf="changeArray?.length == 0" class="block-style">No Pending CR's for this Project.</div>
  <div *ngIf="changeArray?.length">
  <table class="table">
      <thead>
      <tr class="header-style">
        <td style="width:15%">Date of Submission</td>
        <td style="width:15%">Change Request Comment</td>
        <td style="width:15%">Date of Review</td>
        <td style="width:20%">Review Comments</td>
        <td style="width:20%">Field</td>
        <td style="width:7.5%">Old Value</td>
        <td style="width:7.5%">New Value</td>
        
      </tr>
      </thead>
      <tbody *ngFor="let changeid of changeArray;let i = index;">
        <tr *ngFor="let change of changeid;let j = index;"  [ngStyle]="{'background-color': i % 2 === 0 ? '#E6E6E6' : ''}">
          <!-- adding CR date -->
          <td style="width:15%">{{change.createdOn}}</td>
          <td style="width:15%">{{change.changeRequestComment}}</td>
          <td style="width:15%">
            <div class="border border-rounded right-border-rounded left-border-rounded approved my-2" style="background-color: #3BA11B;color: white;vertical-align:middle;" *ngIf = "change.approvalstatus == 'approved'">
              <span class="mx-1 py-1"><mat-icon style="font-size: small;">check_circle_outline</mat-icon>APPROVED</span>
           </div>
           <div class="border border-rounded right-border-rounded left-border-rounded rejected my-2" style="background-color: #C3271B;color: white;vertical-align:middle;" *ngIf = "change.approvalstatus == 'rejected'">
            <span class="mx-1 py-1"><mat-icon style="font-size: small;">highlight_off</mat-icon>REJECTED</span>
         </div>
           
            <div *ngIf = "change.approvalstatus == 'pending'"> Pending Review </div>
            <div *ngIf = "change.approvalstatus != 'pending'">{{change.dateOfreview}}</div>
           </td>
          <td style="width:20%">{{!change.crComment && j == 0 ? 'N/A' : change.crComment}}</td>
          <td style="width:20%;"class="text-capitalize" [ngStyle]="{'background-color': change.sourceField == 'activation Amount' ? '#CEFE6C' : ''}">{{change.sourceField}}</td>
          <td style="width:7.5%" [ngStyle]="{'background-color': change.sourceField == 'activation Amount' ? '#CEFE6C' : ''}">{{change.oldValue}}</td>
          <td style="width:7.5%" class="new-value" [ngStyle]="{'background-color': change.sourceField == 'activation Amount' ? '#CEFE6C' : ''}">{{change.newValue}}</td>
         
        </tr>
      </tbody>
    </table>
  </div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button mat-dialog-close class="button-color">Close</button>
</div>
