import { Injectable } from '@angular/core';

/**
* Manages ui specific data caching
*/
@Injectable({ providedIn: 'root' })
export class DataCacheService {
  constructor() {
  }

  get(key: string): any {
    let settings = localStorage.getItem(key);
    if (settings)
      return JSON.parse(settings);
    else
      return null;
  }

  put(key: string, response: any) {
    localStorage.setItem(key,
      JSON.stringify(response));
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
}

