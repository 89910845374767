<!-- <div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="black">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
    </div>
</div> -->
<div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="black">
        <app-sidebar *ngIf="!isIframe && loginDisplay"></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp *ngIf="!isIframe && loginDisplay"></app-navbar-cmp>
        <router-outlet *ngIf="!isIframe && loginDisplay"></router-outlet>
    </div>
</div>