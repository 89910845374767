import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataService<T> {
  httpOptions = {
    params: {},
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  private baseUrl: string = environment.API_URL;
  private orchAPIUrl : string = environment.ORCH_API_URL;
  constructor(private http: HttpClient) {
  }

  get(url: string): Observable<any | any[]> {
    return this.http.get(`${this.baseUrl + url}`, this.httpOptions);
  }
 
  post<T>(url: string, data: any): Observable<any> {
    return this.http.post<T>(`${this.baseUrl + url}`, data, this.httpOptions);
  }
  public update(url: string, itemToUpdate: any): Observable<any> {
    this.httpOptions.params = {};
    return this.http.put<any>(`${this.baseUrl + url}`, itemToUpdate, this.httpOptions.params = {});
  }

  public delete(url: string): Observable<any> {
    return this.http.delete<T>(`${this.baseUrl + url}`);
  }
  postnewUrlAttachment<T>(url: string, data:any): Observable<any> {
    return this.http.post<T>(`${this.orchAPIUrl + url}`, data, this.httpOptions);
  }
  postnewUrlActivations<T>(url: string, data:any): Observable<any> {
    return this.http.post<T>(`${this.orchAPIUrl + url}`, data, this.httpOptions);
  }
  
  getApproval(url: string): Observable<any | any[]> {
    return this.http.post<T>(`${this.orchAPIUrl + url}`, this.httpOptions);
  }

  postApproval<T>(url: string, data: any): Observable<any> {
    return this.http.post<T>(`${this.orchAPIUrl + url}`, data, this.httpOptions);
  }

  postApproveOrReject<T>(url: string, data: any): Observable<any> {
    return this.http.post<T>(`${this.orchAPIUrl + url}`, data, this.httpOptions);
  }
}
