import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { filter, last } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewChangeRequestDialog } from '../new-project/template/change/change.review';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivationService } from '../services/activation.service';



@Component({
  selector: 'app-readonly-report',
  templateUrl: './readonly-report.component.html',
  styleUrls: ['./readonly-report.component.css']
})
export class ReadonlyReportComponent implements OnInit {
  displayedColumns = ['no', 'product', 'customer', 'weight','amount'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) 
  paginator!: MatPaginator;
  Vendors : any[] = [];
  pId :any;
  CRs:any[]=[];
  ActivationDetails : any[] = [];
  attachmentList : any[] = [];
  projectTitle : any;
  budgetGroup: any;
  projectOwner:any;
  projectDesc : any;
  currentlyOpenedItemIndex = -1;
  showBackButton : boolean = false;
  activationObject : any = {};
  responseArray : any = [];
  customers : any[] = [];
  products : any[] = [];
  allContentLoaded = false;
  pageSize = 10;
  pagedList: any[]= [];
  length: number = 0;
  ApprovalStatusArray : any[] = [];
  path:any;
  constructor(private projectService : ProjectService,private route: ActivatedRoute,private datePipe: DatePipe,private _snackBar:MatSnackBar,
    private CurrencyPipe : CurrencyPipe,private dialog: MatDialog,
   ) {
     
     }

  async ngOnInit() {
    this.pId = this.route.snapshot.paramMap.get("projectId");
    this.path = this.pId;
    let pIdList = this.pId.split(".");
    this.pId=pIdList[0]+'.'+pIdList[1];//getting projectId from wbs
    this.getProjectChanges(this.pId);
    // checking the route path and adding condition for 'Back To Approvals' button
    let path = this.route.snapshot.routeConfig.path;
    if(path == 'myapprovals/project-report/:projectId'){
      this.showBackButton = true;
    }
    await this.getCustomers();
    await this.getVendors();
    this.projectService.getProjectReportActivations(this.pId).subscribe({
      next:(elem:any)=>{
      this.ActivationDetails = elem;
      if(this.ActivationDetails?.length){
        this.ActivationDetails = this.ActivationDetails.filter((activation,i,arr)=>{
          return arr.findIndex(act => act.activationWbs.activationWbs === activation.activationWbs.activationWbs)===i
        });
      this.formActivationObject(this.ActivationDetails,elem);   
      }else{      
        this.allContentLoaded = true;  
        this._snackBar.open("No data available", 'Close');       
      }
    },
     error:(error: any) => {
      this._snackBar.open("Failed to load list of activations. "+ error, "X", {panelClass: ['error-snackbar'] });
     }    
    });
  }
 getAttachmentList(awbs:any){
    this.attachmentList=[];
    this.projectService.getListOfDocuments(awbs).subscribe(
      {
        next:(resp:any) => {
          if(resp?.length)
          {
          this.attachmentList = resp;
          for(let file of this.attachmentList){
          file['trimmedFileName'] = this.trimAttachmentList(file.filename);
          file.filename = this.projectService.replaceCharacters(file.filename);
          file.filename = encodeURIComponent(file.filename);
          }
          this.attachmentList.reverse();
          }
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list attachments. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
    )
  }
  trimAttachmentList(filename:String):string{
    const String2 = filename.slice(filename.indexOf('_')+1);
    const String1 = String2.slice(String2.indexOf('_')+1);
    return String1;
  }
  viewAttachment(attachment:any){
    this.projectService.downloadAttachment(attachment.attachmentId).subscribe(
      {
        next: (resp: any) => {
          if(resp){
            this.projectService.downloadAttachmentInUI(resp);
          }
        }, error: (error: any) => {
          this._snackBar.open("Failed to download attachment. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
    )
  }
  formatDate(createOn:any){
    let edate = new Date(createOn);
    edate = new Date(edate.getTime() + edate.getTimezoneOffset() * 60000);
    const formattedDate = this.datePipe.transform(edate,'MM/dd/yyyy');
    return formattedDate;
  }
  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }
  setClosed(itemIndex) {
    if(this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }
  formatAmount(element: any) {
    let amountVal = element.replace(/,/g, '');
    let formattedAmount = this.CurrencyPipe.transform(amountVal,'USD');
    return formattedAmount;
  }
  formatAmountCR(element: any) {
    let amountVal = element.replace(/,/g, '');
    let formattedAmount = this.CurrencyPipe.transform(amountVal,' ');
    return formattedAmount;
  }
  //form list of allocation nested inside activation
  async formActivationObject(activationData:any,data:any){
      await this.getProducts(activationData[0].projectId.budgetGroupId.budgetGroupId);
      this.projectTitle = activationData[0].projectId.projectTitle;
      this.budgetGroup = activationData[0].projectId.budgetGroupId.budgetGroupDesc;
      this.projectOwner = activationData[0].projectId.projectOwnerZid.toUpperCase();
      this.projectDesc = activationData[0].projectId.projectDescription;
      activationData.sort((a: any, b: any) => (a.createdOn > b.createdOn ? 1 : -1));
      for(let i=0;i<activationData.length;i++){
        this.activationObject = activationData[i].activationWbs;
        let allocationResponseArray = [];
        for(let j=0;j<data.length;j++){
          if(this.activationObject.activationWbs == data[j].activationWbs.activationWbs){
            allocationResponseArray.push(data[j]);
          }
        }
        this.activationObject.allocationArray = allocationResponseArray;
        this.activationObject.allocationArray = this.findProductorCustomer(this.activationObject.allocationArray);
        this.responseArray.push(this.activationObject);
        let tempArray = this.responseArray;
        if(this.responseArray[i].allocationArray.length>this.pageSize){
          this.pagedList[i] = tempArray[i].allocationArray.slice(0,this.pageSize);
        }else{
          this.pagedList[i] = tempArray[i].allocationArray;
        }
      }
    this.allContentLoaded = true;
  }
//  adding paginator to allocation table
  OnPageChange(index,length,event?: PageEvent){
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex > length){
      endIndex = length;
    }
    this.pagedList[index] = this.responseArray[index].allocationArray.slice(startIndex,endIndex);
  }
  //for getting list of customers
  getCustomers() {
    return new Promise(resolve => {
      this.projectService.getCustomers().subscribe({
        next: (resp: any) => {
          this.customers = resp;
          resolve(true);
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of customers. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
      )    
    });
  }
  //for getting list of products
  getProducts(budgetGroupId) {
    return new Promise(resolve => {
      this.projectService.getProducts(budgetGroupId).subscribe({
        next: (resp: any) => {
          this.products = resp;
          resolve(true);
        }, error: (error: any) => {
          this._snackBar.open("Failed to load list of products. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      }
    )    
    });
  }
  //populating table elements based on allocation basis
  findProductorCustomer(responseArray:any){
    for(let key of responseArray){
      let zplnLvl = key.zplnLvl?.zplnLvl;
      let customerId = key.customerId?.customerId;
      key.zplnLvl = zplnLvl ;
      key.customerId = customerId;
      key.productName=(this.products.find(v => v.zplnLvl == key.zplnLvl)?.productDescription)?.toLowerCase();
      key.customer=(this.customers.find(v => v.customerId == key.customerId)?.customerName)?.toLowerCase();
      key.calculatedAllocationAmount = this.formatAmount(key.calculatedAllocationAmount);
      key.pageNumber = responseArray.indexOf(key)+1; 
    }
    return responseArray;
  }
  //opening pop-ups
  viewCRs() {
    let data = Object.create(this.CRs);
    data = data.filter((item: any) => item.length > 0);
    if (data.length > 0) {
      if(data.length>1){
        data.sort((n1,n2) => new Date(n2[0].createdOn).getTime() - new Date(n1[0].createdOn).getTime());//sorting dates
      }
      this.dialog.open(ViewChangeRequestDialog, {
        width: '65%',
        height : '90%',//for scroll
        data: data
      });
    }
    else {
      this.dialog.open(ViewChangeRequestDialog, {
        width: '40%',
        data: []
      });
    }
  }
  //for getting list of CRs
  getProjectChanges(projectId: any) {
    this.allContentLoaded = false;
    let crCount = 0;
    this.projectService.getCRs(projectId).subscribe({
      next: async (response) => {
        if(response?.length){
        for (let j = 0; j < response.length; j++) {
          let date = response[j].createdOn;
          let data;
          data = await this.getDateandStatus(response[j].changeId);
          this.projectService.getChangeFields(response[j].changeId).subscribe({
            next: (resp: any) => {
              this.CRs[response[j].changeId] = resp;
              for(let key of this.CRs[response[j].changeId]){
                let crSplit = key.sourceField.split(/(?=[A-Z])/).join(" ");//formatting sourcefield to readable form
                key.sourceField = crSplit;
                if(key.sourceField == 'calculated Allocation Amount'){
                  key.sourceField = 'Allocated Amount'
                }
              }
              this.CRs[response[j].changeId] = this.transformObject(this.CRs[response[j].changeId],date,data);
              crCount++;
            },
            error: (err) => { this._snackBar.open("Failed to load changes for fields of change request. "+ err, "X",{ panelClass: ['error-snackbar'] })}
          })
          if(response?.length == crCount){
            this.allContentLoaded = true;
          }
        }
      }
      },
      error: (err) => {
        this._snackBar.open("Failed to load list of change requests. "+ err, "X",{ panelClass: ['error-snackbar'] });
      }
    });
  }
  //method for removing unwanted parameters
  transformObject(object:any,date:any,data:any){
    object = object.filter(obj => obj.sourceField !== 'allocation Weight');
    object = object.filter(obj => obj.sourceField !== 'allocation Desc');
    object = object.filter(obj => obj.sourceField !== 'allocation Type');
    object = object.filter(obj => obj.sourceField !== 'allocation Wbs');
    object = object.filter(obj => obj.sourceField !== 'activation Wbs');
    object = object.filter(obj => obj.sourceField !== 'project Id');
    object = object.filter(obj => obj.sourceField !== 'customer Id');
    object = object.filter(obj => obj.sourceField !== 'zpln Lvl');
    let crObject = object.filter(obj => obj.sourceField === 'change Request Comment');
    object = object.filter(obj => obj.sourceField !== 'change Request Comment');
    object.forEach(key=>{
      switch(key.sourceField){
        case 'activation Amount':
        key.oldValue = this.formatAmountCR(key.oldValue);
        key.newValue = this.formatAmountCR(key.newValue);
        let unshiftValue = key;
        object.splice(object.indexOf(key),1);
        object.unshift(unshiftValue);
        break;
        case 'Allocated Amount':
        key.oldValue = this.formatAmountCR(key.oldValue);
        key.newValue = this.formatAmountCR(key.newValue);
        if(key.allocationType == 'product'){
          key.sourceField = key.sourceField + ' - '+key.productName;
        }else if(key.allocationType == 'customer'){
          key.sourceField = key.sourceField + ' - '+key.customerName;
        }
        break;
        case 'start Date':
        let dateValue1 = key.newValue.split('T');
        key.newValue = dateValue1[0];
        key.oldValue = this.formatDate(key.oldValue);
        key.newValue = this.formatDate(key.newValue);
        break;
        case 'end Date':
        let dateValue2 = key.newValue.split('T');
        key.newValue = dateValue2[0];
        key.oldValue = this.formatDate(key.oldValue);
        key.newValue = this.formatDate(key.newValue);
        break;
        case 'vendor Id':
        let oldVendor = this.Vendors.filter((vendor: any) => {
          return vendor.vendorId == key.oldValue;
        });
        let newVendor = this.Vendors.filter((vendor: any) => {
          return vendor.vendorId == key.newValue;
        });
        key.oldValue = oldVendor[0].vendorName;
        key.newValue = newVendor[0].vendorName;
        break;
      }
    });
    if(object.length > 0){
      object[0].createdOn = this.formatSubmittedOn(date);
      object[0].approvalstatus = data[0]?.workflowStatus;
      object[0].dateOfreview = this.formatSubmittedOn(data[0]?.actionTimestamp);
      object[0].crComment = data[0]?.comment;
      object[0].changeRequestComment = crObject[0]?.newValue;
      if(!object[0].dateOfreview){
        object[0].dateOfreview == '';
      }
    }
    return object;
  }
  getVendors() {
    return new Promise(resolve => {
      this.projectService.getVendors().subscribe((data: any) => {
        this.Vendors = data;
        resolve(true);
        },
        (error) => {
          this._snackBar.open("Failed to load  list of vendors. " + error, "X", { panelClass: ['error-snackbar'] });
        });
    });
  }
  formatSubmittedOn(submittedOn:any){
    let edate = new Date(submittedOn);
    return edate.toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute:'2-digit'});
  }
  getDateandStatus(changeId){
    return new Promise(resolve=> {
      this.projectService.getApprovalStatusByActivationId(changeId).subscribe({
        next: (data: any) => {
        resolve(data);
        },
        error: (err) => {
          this._snackBar.open("Failed to load Approval status of change requests. "+ err, "X",{ panelClass: ['error-snackbar'] });
        }
      });  
    });
  }
}
