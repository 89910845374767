<mat-icon class="pb-2" (click)="continueEditing()" style="float:right;cursor: pointer;">close</mat-icon>
<div class="pt-4">
    <div  class="text-center">
        <p style="font-size: large;font-weight: bold;"> Project has not been saved. Do you want to close without saving the changes?</p>
    </div>
    <div class="justify-content-center"  mat-dialog-actions>
        <button  mat-raised-button class="btn btn-outline-success bg-white" (click)="closePopup()">
            CLOSE WITHOUT SAVING
        </button>
    
    <button mat-raised-button  class="btn btn-finish btn-fill btn-success btn-wd" (click)="continueEditing()" mat-dialog-close >CONTINUE EDITING</button>
    
    </div>
</div>


  