import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanActivate, CanDeactivate} from "@angular/router";
import { ProjectService } from "../services/project.service";
import { DataCacheService } from "../shared/services/dataCache.service";
import { ProjectDetailsDialog } from "./pop-up/save-popup.component";
@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate, CanDeactivate<any> {
    lockStatus :  any;
    editedProjectForm : any;
    closeWindow:any;
    disableButton : boolean = false;
    constructor(private cacheService:DataCacheService,private dialog: MatDialog,private projectService:ProjectService) {
    }
    canActivate(){
        //receiving lockstatus from cache and checking for navigation
        this.lockStatus = this.cacheService.get('lockStatus');
        if(this.lockStatus != null && this.lockStatus == false){
            return true;
        }else{
            return false;
        }
    }
    async canDeactivate(){
        this.projectService.disableButton.subscribe((data)=>{
            this.disableButton = data;
        });
        if(!this.editedProjectForm.projectId){
            if(this.editedProjectForm?.projectTitle.length != 0 || this.editedProjectForm?.projectDescription.length != 0 || this.editedProjectForm?.budgetGroupId.length != 0 ){
            await this.getProceedStatus();
             return this.closeWindow;
            }else{
              return true;
            }
        }else if(this.disableButton == true){
            return false;
        }
        else{
            return true;
        }
        
    }
    getProceedStatus(){
        return new Promise(resolve=> {
            const dialogRef=this.dialog.open(ProjectDetailsDialog, {
                width: '30%',
                height : '25%',//for scroll
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result === true) {
                    this.closeWindow = true;
                    resolve(true);
                }else{
                    this.closeWindow = false;
                    resolve(true);
                }
              });
          });
     

    }
    setprojectForm(form:any){
        this.editedProjectForm = form;
    }
    setCloseWindow(value:any){
        this.closeWindow = value;
    }
  
}