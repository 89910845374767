<div *ngIf="!allDataLoaded" class="bar_overlay"></div>
<mat-progress-bar mode="indeterminate" *ngIf="!allDataLoaded"></mat-progress-bar>
<div class="row" style="padding: 48px;">
    <div class="col-md-12">
        <div class="card activation-card m-0">
            <div class="card-header card-header-primary card-header-icon mb-2 p-0">
                <h2 class="card-title text-dark"> New Activation Approvals</h2>
            </div>
            <div class="card-body p-0">
                <div class="toolbar" style="vertical-align: middle;">
                    <!--        Here you can write extra buttons/actions for the toolbar              -->
                    <div class=" bg-white p-0">
                    <div class="table-responsive">
                        <table id="datatablesApproval" #datatablesApproval style="width:100%" class="table table-striped display scrollbar-1 nowrap">
                        </table>
                        <div class="number-limit" *ngIf="showWarningApproval">
                            Please reduce comment to be under 250 characters before submitting.
                        </div>
                    </div>
                    </div>
                </div>
                

                
              

            </div>
            <!-- end content-->
        </div>
        <!--  end card  -->
    </div>
    <!-- end col-md-12 -->
</div>
<div class="row" style="padding-left: 48px;padding-right: 48px;">
    <div class="col-md-12">
        <div class="card activation-card m-0">
            <div class="card-header card-header-primary card-header-icon mb-2 p-0">
                <h2 class="card-title text-dark">Change Requests</h2>
            </div>
            <div class="card-body p-0">
                <div class="toolbar" style="vertical-align: middle;">
                    <!--        Here you can write extra buttons/actions for the toolbar              -->
                   <div class=" bg-white p-0">
                    <div class="table-responsive">
                        <table id="datatablesCR" #datatablesCR style="width:100%" class="table table-striped display scrollbar-1 nowrap">
                        </table>
                        <div class="number-limit" *ngIf="showWarningCR">
                            Please reduce comment to be under 250 characters before submitting.
                        </div>
                    </div>
                   </div>
                    
                </div>
                

                
              

            </div>
            <!-- end content-->
        </div>
        <!--  end card  -->
    </div>
    <!-- end col-md-12 -->
</div>
<div style="padding-top: 48px;padding-left: 48px;">
    <div class="button-panel" style="display: flex;justify-content: start; ">
      <button mat-raised-button class="btn btn-fill reject-btn" [disabled]="disableButton"
        style="margin-right:1rem;background-color: #E82C2A;" (click)="reject($event)">Reject</button>
      <button mat-raised-button class="btn  btn-fill approve-btn"  [disabled]="disableButton"
       style="background-color:#3BA11B;" (click)="approve($event)">Approve</button>

    </div>
    <div class="warning-text">
        Upon clicking “reject” or “approve”, your page will refresh. It is possible that you 
        will still see the project(s) that you approved or rejected remain on the page. If this 
        happens, please wait a moment and refresh the page again to see the updated list of projects 
        that require your review.
    </div>
  </div>
  
 

