import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y/input-modality/input-modality-detector';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ActivationService } from 'src/app/services/activation.service';

import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'change-review.component.html',
  styleUrls: ['./change-review.component.css']
})
export class ViewChangeRequestDialog implements OnInit{

  public changeArray: any;
  public isDataPresent: boolean = false;
  Vendors: any;
  dataLoaded = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.changeArray = data;
  }

  ngOnInit(){
    
  }

  


}
