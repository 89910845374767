import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { DataCacheService } from '../dataCache.service';
// import { MSAuthService } from '../services/ms_auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class SIFTInterceptor implements HttpInterceptor {
  constructor(private datacacheservice: DataCacheService,private authService:MsalService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let account = this.authService.instance.getAllAccounts()[0];
    const idTokenClaims: { exp?: number } = account.idTokenClaims;
    const forceRefresh = (idTokenClaims.exp <= Math.floor(Date.now() / 1000));//token expiry time check
    if (forceRefresh)
      this.authService.instance
        .acquireTokenSilent({ scopes: [], account: account, forceRefresh: forceRefresh }).then((authResponse) => {
          // do something with authResponse.idToken 
        });
    let homeAccount = this.datacacheservice.get("homeAccountId");
    let clientId = environment.MS_CLIENT_ID;
    let tenantId = environment.TENANT_ID;
    let term = '-login.windows.net-idtoken-';
    let key = homeAccount+term+clientId+'-'+tenantId+'---';
    let token = this.datacacheservice.get(key);
    let authReq = req;
      if (!req.urlWithParams.includes('/invoke?api-version')) {
        authReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token.secret)
        });
      }
      else if (req.urlWithParams.includes('/invoke?api-version')) {
        authReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token.secret)
          .set('x-authorization', token.secret)
        });
      }
    return next.handle(authReq);
  }
}
