<div *ngIf="ActivationsArray?.length>0">
  <p> Following activations can be submitted for approval: </p>
  <ul *ngFor="let activations of ActivationsArray">
    <li>{{activations}}</li>
  </ul>
  <p>You and the project owner will be copied on the confirmation email that is sent to the assigned approver.</p>
</div>
<div *ngIf="noAttaActivationsArray?.length>0">
  <p> Cannot submit below activations for Approval. </p>
  <p> Add attachments to these activations before submitting for approval: </p>
  <ul *ngFor="let noAttactivations of noAttaActivationsArray">
    <li>{{noAttactivations}}</li>
  </ul>
 
</div>
<div *ngIf="data.changeReqList?.length>0 || SavedActivations?.length>0 || NewSavedActivations?.length>0">
 
    <div *ngIf="SavedActivations?.length>0">  
      <p> Changes to this activation will be saved: </p>    
      <ul *ngFor="let activations of SavedActivations">
        <li>{{activations}}</li>
      </ul>
    </div>
    <div *ngIf="data.changeReqList?.length>0">      
      <p> The edit you have made requires a change request to be submitted. This change will need to be approved by Finance in order to update the 
        Project Activation (including any associated POs).</p>
     
      <ul>
        <li>{{data.changeReqList}}</li>
      </ul>
      <h3 style="margin: 0%;font-weight: bolder;">Change Request Comment</h3>
      <mat-form-field appearance="outline">
        <textarea type="text" maxlength="350" [(ngModel)]="CRcomment" placeholder="Please enter a brief comment here to describe why the change is taking place." matInput style="height: 7.5rem !important" ></textarea>
      </mat-form-field>
      
    </div>
    <div *ngIf="NewSavedActivations?.length>0">
      <p> New project activation will be saved: </p>
      <ul *ngFor="let activations of NewSavedActivations">
        <li>{{activations}}</li>
      </ul>
    </div>
</div>    
<div>
  <p>{{deleteMessage}}</p>
  <p>{{data.deleteAlert}}</p>
</div>
<div *ngIf="siftLocked">
  <p>SIFT is currently locked in preparation for month end close. While SIFT is locked you cannot edit or create new projects. You are still able to view your projects as well as create and submit change requests. Reach out to your 
    Marketing Finance partner if you have any questions. SIFT will be unlocked on Wednesday.</p>
</div>
<div mat-dialog-actions>
  <div>
    <button mat-raised-button style="background-color: #E82C2A;" class="btn btn-finish btn-fill btn-success btn-wd"  mat-dialog-close (click)="close()">Cancel</button>
   </div>
   <button  style="margin-right: 0%;margin-left: auto;"  class="btn btn-next btn-fill btn-success btn-wd" [disabled]="noAttaActivationsArray?.length>0"
    (click)="onProceed()">Submit</button>
  <!-- <button style="margin-right: 0%;margin-left: auto;" *ngIf="!siftLocked" disabled="noAttaActivationsArray?.length>0" mat-raised-button class="btn btn-finish btn-fill btn-success btn-wd"
      (click)="onProceed()">Submit</button> -->
     

 
</div>

