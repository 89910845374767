import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppInsightsMonitoringService } from "./appinsights-logging.service";

@Injectable()

export class GlobalErrorHandler implements ErrorHandler {

  constructor(

    private errorSnackBar: MatSnackBar,

    private zone: NgZone,

    private appInsightsService:AppInsightsMonitoringService

  ) {

  }



  handleError(error: any) {

    console.log(error.stack);

    this.appInsightsService.logException(error);

    // Check if it's an error from an HTTP response

    if (!(error instanceof HttpErrorResponse)) {

      error = error.rejection; // get the error object

    }

    this.zone.run(() =>

      this.errorSnackBar.open(

        error?.message || 'Undefined client error' + ' '+ error?.status,"Close")

    );

    console.error('Error from global error handler', error);

  }

}