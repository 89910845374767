import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { ProjectService } from '../services/project.service';
import { DataCacheService } from '../shared/services/dataCache.service';

@Injectable({
  providedIn: 'root'
})
export class FileGuard implements CanActivate {
  constructor(private projectService: ProjectService, private authService: MsalService,
    private dataCache: DataCacheService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let accounts = this.authService.instance.getAllAccounts();
    let requestedAttachment = state.url;
    requestedAttachment = decodeURIComponent(requestedAttachment);

    if (accounts.length <= 0) {
      this.dataCache.put('requestedAttachment', requestedAttachment);
      this.authService.loginRedirect();
    }
    else {
      let fileName = requestedAttachment.substring(requestedAttachment.lastIndexOf('/') + 1);
      //replacing codes for parentheses
      fileName = fileName.replace(/%28/g, "(").replace(/%29/g, ")").replace(/%5B/g,"[").replace(/%5D/g,"]");
      let activation = fileName.split('_')[0];
      this.projectService.getListOfDocuments(activation).subscribe(data => {
        let matchedDoc = data.filter(x => x.filename === fileName.replace(/%20/g, ' '));
        this.projectService.downloadAttachment(matchedDoc[0].attachmentId).subscribe(
          {
            next: (resp: any) => {
              this.projectService.downloadAttachmentInUI(resp);
              if (document.getElementsByClassName('loader').length && !document.getElementsByClassName('main-panel').length)
                document.getElementsByClassName('loader')[0].remove();
              return of(false);
            }
          }
        )
        return of(false);
      })
    }
    return false
  }

}
