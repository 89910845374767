import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterContentChecked, AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { DataCacheService } from '../../shared/services/dataCache.service';
import { ProjectService } from '../../services/project.service';
import 'datatables.net-fixedcolumns';

declare interface DataObject {
  "wbs": string,
  "activationDesc": string,
  "amount": number,
  "startDate": Date,
  "endDate": Date,
  "status": string,
  "projectTitle": string,
  "projectId": string
}

declare var $: any;

export interface TableData {
  headerRow: string[];
  dataRows: DataObject[];
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,AfterContentChecked{
  public dataTable: TableData;
  showRightScrollBtn: boolean = true;
  showLeftScrollBtn: boolean = false;
  filters = [];
  projectGridObject: any = [];
  table: any;
  loadGrid: boolean = true;
  count: number = 0;
  objCount: number = 0;
  allDataLoaded = false;
  timeout: any = null;
  totalAmount: any;
  projectCount: number;
  pendingApprovalCount: number;
  approvedCount: number;
  lockbuttons = false;
  datatables: any;
  dtOptions: any;
  tableData = [];
  allContentLoaded = false;
  appSettings : any = {};
  FiscalYears = [];
  wbsList : any[] = [];
  projectTitleList : any[] = [];
  activationList : any[] = [];
  startDateList : any[] = [];
  endDateList : any[]= [];
  statusList : any[]= [];
  fiscalYearList : any[] = [];
  budgetGroupList : any[]= [];
  vendorList: any[] = [];
  transferTypeList : any[] = [];
  ownerList : any[] = [];
  FilterList : any[] = [];
  isClicked = false;
  selectedOptions = [];
  indexList = [];
  supressList = [];
  productList : any[] = [];
  customerList : any[] = [];
  @ViewChild('datatables', { static: true }) table1;
  private readonly _destroying$ = new Subject<void>();
  
  @ViewChild('items') allSelected: any;

  constructor(private router: Router, private projectService: ProjectService, private msalBroadcastService:MsalBroadcastService,
    private _snackBar:MatSnackBar, private currencyPipe: CurrencyPipe, private datePipe:DatePipe,private cacheService:DataCacheService
    ) {
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None),takeUntil(this._destroying$)).subscribe(async () => { 
      this.projectService.getDashboardAccrualList().subscribe({
        next:(dashBoardAccruals: [])=>{
          const length = dashBoardAccruals.length;
          const last = dashBoardAccruals[length-1];
          if(length == 0){//closing loader when no data available
            this.allDataLoaded = true;
          }
          dashBoardAccruals.forEach(accrualObj=>{
          this.buildProjectGridObject(accrualObj);
          if(accrualObj == last){//tracking whether all data is loaded or not(display search field only if data is loaded)
            this.allDataLoaded = true;
          }
          })
           this.getDashboardContent();
         }, error: (error: any) => {
          this._snackBar.open("Failed to load dashboard data. "+ error, "X", {panelClass: ['error-snackbar']  });
        }
      })

      });
  }
    ngAfterContentChecked(): void{
      //styles for fixed columns
        $('tr:nth-child(odd)>td').css('background-color','#E6E6E6');
        $('tr:nth-child(even)>td').css('background-color','white');
    }
    ngOnInit(): void {
  //redirecting to myapprovals after authentication
    let requestedURl =  this.cacheService.get('requestedURL');
    if(requestedURl){
      this.router.navigate(['/myapprovals']);
    }
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  buildProjectGridObject(accrualObj: any) {
    let gridObj = {
      "wbs": accrualObj.activationWbs,
      "projectOwner": accrualObj.projectOwner,
      "activationDesc": accrualObj.activation,
      "startDate": this.datePipe.transform(accrualObj.startDate,'MM/dd/yyyy'),
      "endDate": this.datePipe.transform(accrualObj.endDate,'MM/dd/yyyy'),
      "status": accrualObj.status.toUpperCase(),
      "projectTitle": accrualObj.projectTitle,
      "projectId": (accrualObj.activationWbs).substring(0,7),
      "Total": this.currencyPipe.transform(accrualObj.amount.toString(),' '),
      "fiscalYear": accrualObj.fiscalYear,
      "budgetGroup": accrualObj.budgetGroup,
      "vendorName": accrualObj.vendorName,
      "transferType":accrualObj.transferType,
      "product":accrualObj.productDescription,
      "customer":accrualObj.customerName,
      "isCurrent": accrualObj.isCurrent
    }
    this.projectGridObject.push(gridObj);
  }
  scrollRight = () => {
    let el = document.getElementById('button-wrapper');
    let scrollDifference = el.scrollWidth - el.clientWidth;
    this.showLeftScrollBtn = true;
    el.scrollLeft += 50;
    if (scrollDifference > el.scrollLeft) {
      this.showRightScrollBtn = true;
    }
    else {
      this.showRightScrollBtn = false;
    }
  }

  scrollLeft = () => {
    let el = document.getElementById('button-wrapper');
    let scrollDifference = el.scrollWidth - el.clientWidth;
    this.showRightScrollBtn = true;
    el.scrollLeft -= 50;
    if (el.scrollLeft > 0) {
      this.showLeftScrollBtn = true;
    }
    else {
      this.showLeftScrollBtn = false;
    }
  }
  createNewProject = () => {
    let pId = 0;
    this.router.navigate(['project', pId]);
  }
  navigateToProjectDetail(pId: number) {
    this.router.navigate(['project-details', pId]);
  }
  navigateToProjectDetailCR(pId: number) {
    this.router.navigate(['project-details-CR', pId]);
  }
  viewProjectReport(projectId: any){
    this.router.navigate(['project-report',projectId]);
  }
  //method for getting siftLockStatus
  getLockStatus(){
    this.cacheService.put('lockStatus',null);
    return new Promise(resolve=> {
    this.projectService.getSiftAppSettings().subscribe({
      next: (data) =>{
        // constructing object from api response list and using the object for showing dashboard details and buttons
      for(let k of data){
        this.appSettings[k.variableName] = k.variableValue;
      }
      if(this.appSettings.isSiftLocked.toLowerCase() == 'false'){
        this.lockbuttons = false;
        this.cacheService.put('lockStatus',false);
      }else if(this.appSettings.isSiftLocked.toLowerCase() == 'true'){
        this.lockbuttons = true;
        this.cacheService.put('lockStatus',true);
      }
      this.allContentLoaded = true;// adding loader till all data is loaded
      resolve(true);
      }, error: (error: any) => {
        this._snackBar.open("Failed to load SIFT App Settings. . "+ error, "X", {panelClass: ['error-snackbar']  });
      }
      });
    });
  }
  goToUrl(data:any){
    (window as any).open(data,"_blank");
  }
  getDashboardContent() {
    this.totalAmount = 0;
    (async () => {
      await this.getLockStatus();//other code should execute only after getting lock status
      while (this.allDataLoaded !== true)
        await new Promise(resolve => setTimeout(resolve, 2000));
       
        for(let key of this.projectGridObject){//forming list of fields for populating drop downs
          this.wbsList.push(key.wbs);
          this.ownerList.push(key.projectOwner);
          this.projectTitleList.push(key.projectTitle);
          this.activationList.push(key.activationDesc);
          this.startDateList.push(key.startDate);
          this.endDateList.push(key.endDate);
          this.statusList.push(key.status);
          this.fiscalYearList.push(key.fiscalYear);
          this.budgetGroupList.push(key.budgetGroup);
          this.vendorList.push(key.vendorName);
          this.transferTypeList.push(key.transferType);
        }
        //filtering unique values from list
        this.ownerList = this.ownerList.filter((n, i) => this.ownerList.indexOf(n) === i);
        this.ownerList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.ownerList.unshift('Project Owner');
        this.activationList = this.activationList.filter((n, i) => this.activationList.indexOf(n) === i);
        this.activationList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.activationList.unshift('Activation');
        this.startDateList = this.startDateList.filter((n, i) => this.startDateList.indexOf(n) === i);
        this.startDateList.sort((n1,n2) => new Date(n1).getTime() - new Date(n2).getTime());
        this.startDateList.unshift('Start Date');
        this.endDateList = this.endDateList.filter((n, i) => this.endDateList.indexOf(n) === i);
        this.endDateList.sort((n1,n2) => new Date(n1).getTime() - new Date(n2).getTime());
        this.endDateList.unshift('End Date');
        this.statusList = this.statusList.filter((n, i) => this.statusList.indexOf(n) === i);
        this.statusList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.statusList.unshift('Status');
        this.fiscalYearList = this.fiscalYearList.filter((n, i) => this.fiscalYearList.indexOf(n) === i);
        this.fiscalYearList.sort((n1,n2) => n1 - n2);
        this.fiscalYearList.unshift('Fiscal Year');
        this.budgetGroupList = this.budgetGroupList.filter((n, i) => this.budgetGroupList.indexOf(n) === i);
        this.budgetGroupList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.budgetGroupList.unshift('Budget Group');
        this.vendorList = this.vendorList.filter((n, i) => this.vendorList.indexOf(n) === i);
        this.vendorList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.vendorList.unshift('Vendor Name');
        this.transferTypeList = this.transferTypeList.filter((n, i) => this.transferTypeList.indexOf(n) === i);
        this.transferTypeList.sort((a,b)=> a.toLowerCase().localeCompare(b.toLowerCase()));
        this.transferTypeList.unshift('Transfer Type');
        this.supressList = ['Suppress Zeroes','Suppress $0 Project Activations','Display only $0 Project Activations'];
        this.FilterList.push(this.activationList,this.startDateList,this.endDateList,this.ownerList,
        this.statusList,this.fiscalYearList,this.budgetGroupList,this.vendorList,this.transferTypeList,this.supressList);
       
      let actAmt;
      let totalActAmt = 0;
      const editableStatus = ['DRAFT', 'APPROVED'];//making it local since it is only locally used
      this.projectGridObject.map(x => {
        actAmt = x.Total.toString().replace(' ', '');
        actAmt = actAmt.replace(',', '');
        actAmt = parseFloat(actAmt);
        totalActAmt = totalActAmt + actAmt;
      })
      this.totalAmount = totalActAmt;
      this.totalAmount = this.totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.totalAmount = this.totalAmount.replace(/,/g, '');
      this.totalAmount = this.currencyPipe.transform(this.totalAmount,' ');
      this.projectCount = new Set(this.projectGridObject.map(obj => obj.projectId)).size;
      this.approvedCount = this.projectGridObject.filter(obj => obj.status === 'APPROVED').length;
      this.pendingApprovalCount = this.projectGridObject.filter(obj => obj.status === 'UNDER_REVIEW').length;
      this.dtOptions = {
        pagingType: "full_numbers",
        lengthMenu: [
          [5,10,25,50,-1],
          [5,10,25,50,"All"]
        ],
        pageLength: 25,
        dom: 'lrtip',
        data: this.projectGridObject,
        language: {
          "emptyTable": "No Activations Available."
        },        
        // Data tables do not escape special characters in Angular, so we added data rendering to the dashboard fields to mitigate XSS(Cross Site Scripting) vulnerabilities 
        // XSS Reference - https://developer.okta.com/blog/2022/07/21/angular-security-xss#angular-automatically-escapes-values
        // Data Rendering Reference - https://datatables.net/manual/data/renderers
        columns: [
           { title: 'WBS', data: 'wbs', render: $.fn.dataTable.render.text()},
          { title: 'Project Title', data: 'projectTitle', render: $.fn.dataTable.render.text()},
          { title : 'Project Owner',data: 'projectOwner', render: $.fn.dataTable.render.text()},
          {
            "className": 'view-action',
            "orderable": false,
            "data": null,
            "defaultContent": '',
            "render": function (data, type, row) {
              return '<span class="material-symbols-outlined" style="padding-right: 20%; cursor: pointer" title="See Details">visibility</span>';
            }
            },{
            "className": 'row-action',
            "orderable": false,
            "data": null,
            "defaultContent": '',
            "render": function (data, type, row) {
             if (((editableStatus.includes(data.status) && !globalThis.lockbuttons) || data.status == 'APPROVED') && data.isCurrent)
               return '<span class="material-symbols-outlined" style="padding-right: 20%; cursor: pointer" title="Edit Details">edit </span>';
            }
          },
          { title: 'Activation', data: 'activationDesc', render: $.fn.dataTable.render.text()},
          { title: 'Start Date', data: 'startDate'},
          { title: 'End Date', data: 'endDate'},
          { title: 'Status', data: 'status'},
          { title: 'Total', data: 'Total',"className":'text-right'},
          { title: 'Fiscal Year', data: 'fiscalYear'},
          { title: 'Budget Group', data: 'budgetGroup', render: $.fn.dataTable.render.text()},
          { title: 'Vendor Name', data: 'vendorName', render: $.fn.dataTable.render.text()},
          { title: 'Transfer Type', data: 'transferType', render: $.fn.dataTable.render.text()},
        ],
        scrollX:  true,//horizontal scroll
        scrollY: '30rem',//vertical scroll
        scrollCollapse: true,
        info: false,
        autoWidth: true,//enabling columns width to adjust with screen size
        fixedColumns: {// keeping 2 columns from left fixed
          leftColumns: 2
        },
        'order': [[0, 'desc']],  
      }
      this.datatables = $(this.table1.nativeElement);
      this.datatables.DataTable(this.dtOptions);
      this.datatabelStyles();
      let filters = this.cacheService.get('filterItems');
      if(!filters){
        filters = [];
      }
      let suppressObject: any = {};
      suppressObject['column'] = 'Suppress Zeroes';
      suppressObject['filterValue'] = 'Suppress $0 Project Activations';
      let  textList = filters?.find(filterValue => filterValue.column == 'Suppress Zeroes');
      if(!textList){
        filters.push(suppressObject);
      }
      if(filters != null && filters != undefined && filters != [] && $.fn.dataTable.isDataTable( '#datatables') == true){
        this.selectedOptions = filters;
        this.filteritems();
      }
      //applying filters stored in cache while user redirects to dashboard
      await $.fn.dataTable.isDataTable( '#datatables');
      if($.fn.dataTable.isDataTable( '#datatables') == true){
        setTimeout(()=>{$('#datatables').DataTable().columns.adjust().responsive.recalc()}, 300);
        //adjusting columns with screen size
      }
    })();
    const globalThis = this;
    $('#datatables').on('click', '.row-action', function () {
      var table = $('#datatables').DataTable();
     var data = table.row($(this).closest('tr')).data();
      if(data.status == 'APPROVED' && globalThis.lockbuttons == true){
        globalThis.navigateToProjectDetailCR(data.wbs);
      }else{
        globalThis.navigateToProjectDetail(data.wbs);
      }
   })
  $('#datatables').on('click', 'td.view-action', function () {
    var table = $('#datatables').DataTable();
    var data = table.row($(this).closest('tr')).data();
    globalThis.viewProjectReport(data.wbs);
  })
  }
  autoSearchTable(e){
    clearTimeout(this.timeout);
    this.timeout = setTimeout(()=>{this.onBlurSearchMethod(e)}, 300);
  }
  onBlurSearchMethod(e) {
    this.allDataLoaded = false;
    e.preventDefault();
    var searchValue = e.target.value;
    var table :any;
    if ( $.fn.dataTable.isDataTable( '#datatables' )) {//checking if table is loaded 
      table = $('#datatables').DataTable();
      table.search(searchValue).draw();
      this.getCalulatedValues();
    }
    else{
      e.target.value = null;//clearing input field if data is not loaded.
      if(this._snackBar._openedSnackBarRef == null){
        this._snackBar.open('Please search after the Dashboard data is completely loaded.',"Close");
      }  
    }
    this.allDataLoaded = true;
  }
  openFilter(){
    if(!this.isClicked){
      this.isClicked = true;
    }else{
      this.isClicked = false;
    }   
  }
  filterItemsFunction(filterItem,e){
    if(e.option._selected == true){
      var itemObject : any = {};
      itemObject['column'] = filterItem[0];
      itemObject['filterValue'] = e.option._value;
      if(itemObject.column == 'Suppress Zeroes' && itemObject.filterValue == 'Suppress $0 Project Activations'){
        for(let key of this.selectedOptions){  
          if(key.column == 'Suppress Zeroes' && key.filterValue == 'Display only $0 Project Activations'){
            this.selectedOptions.splice(this.selectedOptions.findIndex(a => a.filterValue === key.filterValue),1);
          }
        }
      }else if(itemObject.column == 'Suppress Zeroes' && itemObject.filterValue == 'Display only $0 Project Activations'){
        for(let key of this.selectedOptions){
          if(key.column == 'Suppress Zeroes' && key.filterValue == 'Suppress $0 Project Activations'){
            this.selectedOptions.splice(this.selectedOptions.findIndex(a => a.filterValue === key.filterValue),1);
          }
        }
      }
      this.selectedOptions.push(itemObject);
      this.selectionControl();
      this.cacheService.put('filterItems',this.selectedOptions);//storing applied filters in cache
    }else if(e.option._selected == false){
      for(let key of this.selectedOptions){
        if(e.option._value == key.filterValue){
          this.selectedOptions.splice(this.selectedOptions.findIndex(a => a.filterValue === key.filterValue),1);
        }
      }
      this.selectionControl();
      this.cacheService.put('filterItems',this.selectedOptions);//storing applied filters in cache
    }
  }
  selectionControl(){
    if(this.selectedOptions.length != 0){
      this.filteritems();
    }else{
      $('#datatables').DataTable().columns('').search('').draw();
      this.getCalulatedValues();
    }
  }
  filteritems(){
    this.indexList = [];
    let sameFilterList = this.selectedOptions.filter((option,i,arr)=>{
      return arr.findIndex(act => act.column === option.column)===i
    });
    for(let i = 0; i < sameFilterList.length ; i++){
      let indexObj : any = {};
      let filters = [];
      for(let j = 0; j < this.selectedOptions.length; j++){
        indexObj['column'] = sameFilterList[i].column;
        if(sameFilterList[i].column == this.selectedOptions[j].column){
          filters.push( this.selectedOptions[j].filterValue);
          indexObj.filters = filters;
        }
      }
      this.indexList.push(indexObj);
    }
    var table :any;
    table = $('#datatables').DataTable();
    table.columns('').search('').draw();
    for(let key of this.indexList){
      key.filters = key.filters.join('|');
      let columnName = key.column;
      switch (columnName) {
        case 'Project Owner':table.column(2).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Activation':table.column(5).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Start Date':table.column(6).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'End Date':table.column(7).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Status':table.column(8).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Fiscal Year':table.column(10).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Budget Group':table.column(11).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Vendor Name':table.column(12).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Transfer Type':table.column(13).search(key.filters.length > 0 ? '^(' + key.filters + ')$' : '', true, false,true).draw();break;
        case 'Suppress Zeroes':
          if(key.filters == 'Display only $0 Project Activations'){
            table.column(9).search(key.filters.length > 0 ? '^(0.00)$' : '', true, false,true).draw();
          }else{
            table.column(9).search(key.filters.length > 0 ? '^(?!'+'0.00'+'$)' : '', true, false,true).draw();
          }
          break;
      }
    }
    this.getCalulatedValues();
  } 
  closeDropDown(){
    this.isClicked = false;
    this.selectedOptions = [];
    this.cacheService.delete('filterItems');
    this.selectionControl();
  }
  ngDoCheck() {
    this.dataTable = {
      headerRow: ['WBS', 'Project Title','Project Owner' ,'','','Activation', 'Start Date', 'End Date', 'Status', 
        'Total','Fiscal Year','Budget Group','Vendor Name','Transfer Type'],
      dataRows: this.projectGridObject
    }
  }
  removeFilter(option: any){
    this.selectedOptions.splice(this.selectedOptions.findIndex(a => a.filterValue === option),1);
    if(this.selectedOptions.length == 0){
      this.isClicked = false;
    }
    this.cacheService.put('filterItems',this.selectedOptions);
    this.selectionControl();
  }
  getSelected(option){
    for(let key of this.selectedOptions){
      if(key.filterValue == option){
        return true;
      }
    }
  }
  //method for recalculating values
  getCalulatedValues(){
    if ( $.fn.dataTable.isDataTable( '#datatables' )) {//checking if table is loaded 
      var table = $('#datatables').DataTable();
      this.datatabelStyles();
      let sumTotal = table.rows( { filter : 'applied'} ).data().toArray();
      this.totalAmount = 0;
      this.pendingApprovalCount = sumTotal.filter(obj => obj.status === 'UNDER_REVIEW').length;
      this.projectCount = new Set(sumTotal.map(obj => obj.projectId)).size;
      this.approvedCount=sumTotal.filter(obj => obj.status === 'APPROVED').length;
      for(let key of sumTotal){
        let amount = key.Total.toString().replaceAll(' ','');
        amount = amount.replaceAll(',','');
        amount = parseFloat(amount);
        this.totalAmount+=amount;
      }
      this.totalAmount = this.currencyPipe.transform(this.totalAmount,' ');
    }
  }
  datatabelStyles(){
    $('th').css('font-weight', 'bold'); 
      $('th').css('padding-top', '1rem'); 
      $('label').css('color','black');//style for pagination count
      $('div.dataTables_scrollHeadInner').css('border-bottom','2px solid #C0C0C0');//horizontal rule for header
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css('background','#e1e1e1');//footer color
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css('padding','1rem');//footer padding
      $('div.dataTables_wrapper div.dataTables_paginate ul.pagination').css('margin-top','0.5rem');//footer padding
      $('table').css('border-bottom','2px solid #C0C0C0');//footer rule 
  }
}
