import { CurrencyPipe, DatePipe, JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, retry, Subject } from 'rxjs';
import { Activations, Allocations } from '../shared/models/activations';
import { Project } from '../shared/models/project';
import { DataService } from '../shared/services/apiServices/data.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})

export class ActivationService {
  CRcomment  : any;
  CRCommentArray : any[] = [];
  constructor(private datePipe:DatePipe,private dataService: DataService<Project>,private projectService:ProjectService,private https: HttpClient) { }

  saveProjectActivationObjectArray(actObjarray:any){
    const activationArray=[];
    for(const actObj of actObjarray){
      let activationObj=new Activations();
      const allocObjArray:Array<Allocations>=[];
       activationObj.eventInitiative="None";//This is a hardcoded value that's needed to save an activation since the user doesn't have access to it in the frontend
       activationObj.deleteFlag= actObj.get('deleteFlag').value;
       const rawActivationAmount = actObj.get('activationAmount').value;
       const activationWithoutCommas = this.transformAmount(rawActivationAmount);
       // parseFloat does not like commas. will truncate after
       actObj.get('activationAmount').value = parseFloat(activationWithoutCommas).toFixed(2);
       activationObj.activationAmount=actObj.get('activationAmount').value;
       activationObj.activationStatus=actObj.get('activationStatus').value;
       activationObj.activationType=actObj.get('activationType').value;
       activationObj.activationWbs=actObj.get('activationWbs').value;
       activationObj.clarizenNumber=actObj.get('clarizenNumber').value;
       activationObj.endDate=this.formatDate(actObj.get('endDate').value);
       activationObj.startDate=this.formatDate(actObj.get('startDate').value);
       activationObj.transferId=actObj.get('transferId').value;
       activationObj.vendorId=actObj.get('vendorId').value;
       activationObj.spendType=actObj.get('spendType').value;
       activationObj.fiscalYear=actObj.get('fiscalYear').value;
       activationObj.mmNumber=actObj.get('mmNumber').value;
       activationObj.totalPiecesOfArt=actObj.get('totalPiecesOfArt').value;
       activationObj.numberOfSKUs=Number(actObj.get('numberOfSKUs').value);
       activationObj.projectActivationDesc=actObj.get('activationDesc').value;
       activationObj.projectId= actObjarray[0].get('projectId').value;
       //CR comment added for change request
       if(activationObj.activationStatus == 'approved'){
        for(const comment of this.CRCommentArray){
           if(activationObj.activationWbs == comment.wbs){
            activationObj.changeRequestComment = comment.CRcomment;
           }
        }
       }
       for(const allocation of actObj.get('allocationsForms').value){
        let allocObj=new Allocations();
        allocObj.allocationWbs=allocation.allocationWbs;
        allocObj.allocationDesc = allocation.customerId+'/'+allocation.zplnLvl;
        allocObj.allocationWeight=allocation.allocationWeight;
        allocObj.allocationType=allocation.allocationType;
        allocObj.calculatedAllocationAmount=allocation.calculatedAllocationAmount;
        allocObj.activationWbs=allocation.activationWbs;
        allocObj.projectId=allocation.projectId;
        allocObj.customerId=allocation.customerId;
        allocObj.zplnLvl=allocation.zplnLvl;
        allocObj.deleteFlag=allocation.deleteFlag;
        allocObjArray.push(allocObj);
       }
       activationObj.allocations=allocObjArray;
       activationArray.push(activationObj);
       
    }
    this.CRCommentArray = [];
    return activationArray;
  
  }
  transformAmount(amount: any) {
    return (amount.replace(/,/g, ''));
  }
  //methods for adding CR Comment
  getCRcomment(){
      return this.CRcomment;
  }
  setCRcomment(comment : any){
    this.CRcomment = comment;
    this.CRCommentArray.push(this.CRcomment);
  }

  formatDate(dateObj: any) {
    let currentDate=new Date(dateObj);
    let formatedDate=this.datePipe.transform(currentDate,'yyyy-MM-dd');
        return formatedDate;
  }
  saveActivationSupportingDocuments(arraySuppDocs:any) {
    return  this.dataService.postnewUrlAttachment('/api/attachments',arraySuppDocs).pipe(retry(1), catchError(this.projectService.handleError));
  }

   submitForApproval(activationWbsArrayObj:any){
    return this.dataService.postApproval('/api/submitactivations',activationWbsArrayObj).pipe(retry(1), catchError(this.projectService.handleError));
  }

  saveProjectActivation(actObjarray:any){
    let activationArray=this.saveProjectActivationObjectArray(actObjarray);
    return  this.dataService.postnewUrlActivations('/api/activations',activationArray).pipe(retry(1), catchError(this.projectService.handleError));

  }

}
