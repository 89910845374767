import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
* Manages ui specific data caching
*/

@Injectable({ providedIn: 'root' })
export class HelperService {
  constructor() {
  }

  private activationWbs = new BehaviorSubject<any>('M.26572.BD2');
  broadcastactivationWbs = this.activationWbs.asObservable();
  private activationWbsStatus = new BehaviorSubject<any>('draft');
  broadcastActivationWbsStatus = this.activationWbsStatus.asObservable();

  changeActivationWbs(newactivationWbs:any){
    this.activationWbs.next(newactivationWbs);
  }

  changeActivationWbsStatus(status:any){
    this.activationWbsStatus.next(status);
  }

  epochConverter(date: any) {
    let dateObj = new Date(0);
    dateObj.setUTCSeconds(date);
    return dateObj;
  }

  isTokenExpired(exp: any) {
    let expdate = new Date(0);
    expdate.setUTCSeconds(exp);
    let currentDate = new Date();
    if (expdate < currentDate) {
      return true;
    }
    else {
      return false;
    }
  }

}
