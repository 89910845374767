;import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Observable, of } from "rxjs";

import { DataCacheService } from "../shared/services/dataCache.service";
@Injectable({
    providedIn: 'root'
})
export class PathGuard implements CanActivate {
    constructor(private authService: MsalService,
        private dataCache: DataCacheService) {
    }
    //storing route path in cache is authentication is expired
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let accounts = this.authService.instance.getAllAccounts();
        let requestedURl = state.url
        if (accounts.length <= 0) {
            this.dataCache.put('requestedURL', requestedURl);
            this.authService.loginRedirect();
        }else{
            return of(true);
        }
    
    }
  
}